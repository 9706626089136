import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	list: []
};

const repositorySlice = createSlice({
	name: 'repository',
	initialState,
	reducers: {
		setRepositoryListState(state, { payload }) {
			state.list = [...payload];
		},
		resetRepositoryListState(state) {
			state.list = [];
		},
	},
});

const { actions, reducer } = repositorySlice;
export const { setRepositoryListState, resetRepositoryListState } = actions;
export default reducer;
