import type { FC } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import 'assets/scss/Root.scss';
import { Footer, PublicLayout, SettingLayout } from 'layouts';
import {
  Directory,
  Home,
  Login,
  MyPage,
  Repository,
  Tickets,
  TicketDetail,
  NotFound,
  GeneralSetting,
  AccessManagement,
  EmailNotifications,
  Terms,
  PrivacyPolicy,
} from 'pages';
import { Provider } from 'react-redux';
import store from 'app/store';
import { Helmet } from 'react-helmet';
import { UserInfo, UserInfoEdit } from 'components';
import { AlertContextProvider } from 'contexts/AlertContextProvider';
import { ScrollToTop } from 'layouts/ScrollToTop';

export const Root: FC = () => {
  return (
    <Provider store={store}>
      <AlertContextProvider>
        <Router>
          <ScrollToTop />
          <Helmet>
            <title>4D Pocket</title>
            <script src="/Logo_Animation.min.js" type="text/javascript"></script>
          </Helmet>
          <div className="layout-container">
            <h1 hidden>4D Pocket</h1>
            <div className="content-container">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/repository/:id/directory" element={<Directory />} />
                <Route path="/repository/:id/tickets" element={<Tickets />} />
                <Route path="/repository/:id/tickets/:ticketId" element={<TicketDetail />} />
                <Route path="/repository" element={<Repository />} />
                <Route path="/my-page" element={<MyPage />}>
                  <Route path="/my-page" element={<UserInfo />} />
                  <Route path="/my-page/edit" element={<UserInfoEdit />} />
                </Route>

                <Route path="/repository/:id/settings" element={<SettingLayout />}>
                  <Route path="/repository/:id/settings" element={<GeneralSetting />} />
                  <Route path="/repository/:id/settings/access-management" element={<AccessManagement />} />
                  <Route path="/repository/:id/settings/email-notifications" element={<EmailNotifications />} />
                </Route>

                <Route path="/" element={<PublicLayout />}>
                  <Route path="/terms" element={<Terms />} />
                  <Route path="/privacy_policy" element={<PrivacyPolicy />} />
                </Route>

                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
            <Footer />
          </div>
        </Router>
      </AlertContextProvider>
    </Provider>
  );
};
