import * as THREE from 'three';
import { SUPPORTED_FILE_TYPE } from "constants/Repository";
import { IMetaData } from "types";

export const DESIRED_LENGTH = 400;

export const formatFileSize = (bytes?: number, decimals: number = 2): string => {
  if (!bytes || bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const size = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

  return `${size}${sizes[i]}`;
};

export const getFileType = (url?: string | null, isUpperCase: boolean = false): string => {
  const splitedUrl = url?.split('.').pop();
  if (!splitedUrl || splitedUrl.length === 0) return '';

  let splitedQuery = splitedUrl;

  if (splitedQuery.includes('?')) {
    splitedQuery = splitedQuery.split('?')[0]
  }

  if (splitedQuery.includes('/')) {
    splitedQuery = splitedQuery.split('/')[0]
  }

  if (isUpperCase) return splitedQuery.toUpperCase();
  return splitedQuery.toLowerCase();
}

export const formatNumber = (value: number, decimals: number = 2): string => {
  if (value % 1 !== 0) {
    return value.toLocaleString('en-US', {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    });
  } else {
    return value.toLocaleString('en-US');
  }
}

export const isSupportedFileType = (url?: string | null): boolean => {
  const fileType = getFileType(url, true);
  return SUPPORTED_FILE_TYPE.includes(`.${fileType.toLowerCase()}`);
}

export const isMarkdownFile = (url?: string | null): boolean => {
  return getFileType(url) === 'md';
}

export const isFBXModel = (url?: string | null): boolean => {
  return getFileType(url) === 'fbx';
}

export const isObjModel = (url?: string | null): boolean => {
  return getFileType(url) === 'obj';
}

export const isGLBModel = (url?: string | null): boolean => {
  return getFileType(url) === 'glb' || getFileType(url) === 'gltf';
}

export const isModelFile = (url?: string | null): boolean => {
  return isFBXModel(url) || isObjModel(url) || isGLBModel(url);
}

export const getFileMetaData = (object3D: THREE.Object3D, isFormat: boolean = true): IMetaData => {
  let verticesCount = 0;
  let trianglesCount = 0;
  let boundingBox = new THREE.Box3();
  let surfaceArea = 0;

  object3D.traverse((child: any) => {
    if (child.isMesh) {
      const geometry = child.geometry;
      const positionAttribute = geometry.attributes.position;
      const index = geometry.index;

      verticesCount += positionAttribute.count;
      trianglesCount += index ? index.count / 3 : positionAttribute.count / 3;

      // Update bounding box
      boundingBox.expandByObject(child);

      // Calculate surface area
      if (index) {
        const positions = positionAttribute.array;
        for (let i = 0; i < index.count; i += 3) {
          const a = index.getX(i);
          const b = index.getX(i + 1);
          const c = index.getX(i + 2);

          const v0 = new THREE.Vector3().fromArray(positions, a * 3);
          const v1 = new THREE.Vector3().fromArray(positions, b * 3);
          const v2 = new THREE.Vector3().fromArray(positions, c * 3);

          const edge1 = v1.clone().sub(v0);
          const edge2 = v2.clone().sub(v0);
          const area = edge1.cross(edge2).length() / 2;

          surfaceArea += area;
        }
      } else {
        // Handle cases without indices
        for (let i = 0; i < positionAttribute.count; i += 3) {
          const v0 = new THREE.Vector3().fromArray(positionAttribute.array, i * 3);
          const v1 = new THREE.Vector3().fromArray(positionAttribute.array, (i + 1) * 3);
          const v2 = new THREE.Vector3().fromArray(positionAttribute.array, (i + 2) * 3);

          const edge1 = v1.clone().sub(v0);
          const edge2 = v2.clone().sub(v0);
          const area = edge1.cross(edge2).length() / 2;

          surfaceArea += area;
        }
      }
    }
  });

  // Calculate size
  const size = boundingBox.getSize(new THREE.Vector3());
  return {
    vertices: isFormat ? formatNumber(verticesCount) : verticesCount,
    triangles: isFormat ? formatNumber(trianglesCount) : trianglesCount,
    sizeX: isFormat ? formatNumber(size.x) : size.x,
    sizeY: isFormat ? formatNumber(size.y) : size.y,
    sizeZ: isFormat ? formatNumber(size.z) : size.z,
    surface: isFormat ? formatNumber(surfaceArea) : surfaceArea,
  };
}
