import React, { useCallback, useEffect } from 'react';
import 'assets/scss/layouts/RightHeader.scss';
import 'assets/scss/common/Font.scss';
import { isLogin, signOutFromGoogle } from 'libs/auth';
import { useLoading } from 'hooks/useLoading';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAlertContext } from 'contexts/AlertContextProvider';
import { iconDropDown, iconGlobal } from 'assets/images';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

export const RightHeader: React.FC = () => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = React.useState<boolean>(false);
  const [isShowLanguage, setIsShowLanguage] = React.useState<boolean>(false);
  const { withLoading } = useLoading();
  const navigate = useNavigate();
  const { alert } = useAlertContext();
  const stateBase = useSelector((state: any) => state.base);

  const onClickAvatar = () => {
    // check is login or not
    const checkLogin = isLogin();
    if (!checkLogin) {
      // redirect to login page
      navigate('/login');
    } else {
      setIsVisible(!isVisible);
      setIsShowLanguage(false);
    }
  };

  const onHandleLogout = async () => {
    try {
      await withLoading(() => signOutFromGoogle());
      alert({ type: 'success', content: t('login.message.logoutSuccess') });
      navigate('/login');
    } catch (error) {}
  };

  const onClickChangeLanguage = useCallback(() => {
    setIsShowLanguage(!isShowLanguage);
    setIsVisible(false);
  }, [isShowLanguage]);

  const onChangeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    setIsShowLanguage(false);
  };

  const onClickOutside = (e: any) => {
    if (!e.target.closest('.dropdown-info')) {
      setIsVisible(false);
    }
    if (!e.target.closest('.multi-language')) {
      setIsShowLanguage(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', onClickOutside);
    return () => {
      document.removeEventListener('click', onClickOutside);
    };
  }, []);

  return (
    <div className="header-right">
      <div className="multi-language">
        <div className="actions" onClick={onClickChangeLanguage}>
          <img src={iconGlobal} alt="global" />
          {i18n.language === 'ja' ? (
            <span className="font-ja font-en-14 font-ja-14">{t('common.japanese')}</span>
          ) : (
            <span className="font-en font-en-16 font-ja-16">{t('common.english')}</span>
          )}
          <img src={iconDropDown} alt="dropdown" />
        </div>
        <ul className={`dropdown-menu languages ${isShowLanguage ? 'visible' : ''}`}>
          <li className="language font-en font-en-16 font-ja-16" onClick={() => onChangeLanguage('en')}>
            {t('common.english')}
          </li>
          <li className="language font-ja font-en-14 font-ja-14" onClick={() => onChangeLanguage('ja')}>
            {t('common.japanese')}
          </li>
        </ul>
      </div>
      <div className="dropdown-info">
        <div className="avatar" onClick={onClickAvatar}>
          {stateBase?.userInfo?.avatarUrl && <img src={stateBase.userInfo.avatarUrl} alt="avatar user" />}
        </div>
        <ul className={`dropdown-menu ${isVisible ? 'visible' : ''}`}>
          <li className="font-en-16 font-ja-14" onClick={() => navigate('/my-page')}>
            {t('header.profile')}
          </li>
          <li className="font-en-16 font-ja-14">{t('header.settings')}</li>
          <li className="font-en-16 font-ja-14" onClickCapture={onHandleLogout}>
            {t('header.logout')}
          </li>
        </ul>
      </div>
    </div>
  );
};
