import { useEffect, useRef, useState } from 'react';

export default function wrapIcon(SVGComponent: any) {
  return function WrappedIcon(props: any) {
    const [isActive, setIsActive] = useState<boolean>(false);
    const wrapIconRef = useRef<HTMLDivElement>(null);
    const { activeColor } = props;
    // Set space event
    useEffect(() => {
      if (wrapIconRef.current) {
        const parentElement = wrapIconRef.current.parentElement;
        if (parentElement) {
          parentElement.style.position = 'relative';
        }
      }
    }, []);

    const handleMouseDown: React.MouseEventHandler<HTMLDivElement> = () => {
      setIsActive(true);
    };

    const handleMouseUp: React.MouseEventHandler<HTMLDivElement> = () => {
      setIsActive(false);
    };

    return (
      <div
        ref={wrapIconRef}
        className="wrap-icon"
        onMouseDown={activeColor && handleMouseDown}
        onMouseUp={activeColor && handleMouseUp}
      >
        <SVGComponent {...props} isActive={isActive} />
      </div>
    );
  };
}
