import Users from 'app/models/Users';
import { resetUserInfo, setUserInfo } from 'app/redux/baseSlice';
import store from 'app/store';
import { IErrorResponse } from 'types';
import { INotificationEmailParams, IUser, IUserSearch } from 'types/user';

export const fetchUser = async (): Promise<any> => {
  try {
    const userData = await Users.getAuth();
    store.dispatch(setUserInfo(userData));
    localStorage.setItem('user', JSON.stringify(userData));
    return userData;
  } catch (error) {
    store.dispatch(resetUserInfo());
    localStorage.removeItem('user');
    console.error('Error fetching user data:', error);
    return null;
  }
};

export const searchUser = async (keyword: string): Promise<IUserSearch[] | IErrorResponse> => {
  try {
    const response = await Users.searchUser(keyword);
    return response;
  } catch (error: any) {
    return {
      ...error.body,
      isError: false,
    } as IErrorResponse;
  }
};

export const updateUserProfile = async (data: FormData): Promise<IUser | IErrorResponse> => {
  try {
    return await Users.updateUserProfile(data);
  } catch (error: any) {
    return {
      ...error.body,
      isError: false,
    } as IErrorResponse;
  }
};

export const updateNotificationEmailSetting = async (
  repositoryId: string,
  data: INotificationEmailParams,
): Promise<IUser | IErrorResponse> => {
  try {
    return await Users.notificationEmailSetting(repositoryId, data);
  } catch (error: any) {
    return {
      ...error.body,
      isError: false,
    } as IErrorResponse;
  }
};
