import Users from 'app/models/Users';
import alertService from 'services/alertService';
import i18n from 'i18next';
import {
  ICreateRemoveDirectoryData,
  ICreateDirectoryResponse,
  ICreateFileResponse,
  IRepository,
  IRemoveFileData,
  IRenameFileData,
  IRenameDirectoryData,
  IRepositoryVersion,
  ICreateVersionData,
  ITutorialStatus,
  IErrorResponse,
  IFile,
  IDirectory,
  IAddMemberResponse,
  ITicket,
  IGetFileData,
  IRenameRepositoryData,
  IRepositoryGeneral,
  IMoveDirectoryData,
  IMoveFileData,
  IRemoveRepositoryVersion,
  IDownloadFileData,
  ITicketMessage,
} from 'types';
import { MAX_SIZE_UPLOAD_FILE } from 'constants/Common';

export const fetchRepositories = async (): Promise<any> => {
  try {
    const response = await Users.getRepositories();

    return response;
  } catch (error) {
    alertService.alert({ type: 'error', content: i18n.t('common.message.fetchRepositoriesFailed') });
    return [];
  }
};

export const createRepository = async (data: any): Promise<IRepository | IErrorResponse> => {
  try {
    const response = await Users.createRepository(data);

    return response;
  } catch (error: any) {
    return {
      ...error.body,
      isError: true,
    } as IErrorResponse;
  }
};

export const getRepositoryDetail = async (repositoryId: string): Promise<IRepository> => {
  try {
    const response = await Users.getRepositoryDetail(repositoryId);

    return response;
  } catch (error: any) {
    alertService.alert({ type: 'error', content: i18n.t('common.message.fetchRepositoryFailed') });
    return {} as IRepository;
  }
};

export const addRepositoryFile = async (
  repositoryId: string,
  data: FormData,
  dataFiles: {
    file: File,
    textureFiles: File[],
  },
  onProgress?: (progress: number) => void,
  onSuccess?: (response: any) => void,
  onError?: (error: string) => void,
): Promise<void> => {
  // check total size of files
  let totalSize = dataFiles.file.size;
  let countFile = 1;
  for (const textureFile of dataFiles.textureFiles) {
    totalSize += textureFile.size;
    countFile++;
  }
  let currentProgress = 0;

  if (totalSize > MAX_SIZE_UPLOAD_FILE) {
    // upload file before texture files
    const formDataFile = new FormData();
    formDataFile.append('file', dataFiles.file);
    formDataFile.append('path', data.get('path') as string);
    formDataFile.append('metaData[mesh]', 'mesh');
    formDataFile.append('metaData[side]', 'side');
    formDataFile.append('metaData[surface]', 'surface');

    Users.addRepositoryFile(repositoryId, formDataFile, 1 / countFile, 0, onProgress, (responseFile) => {
      currentProgress += 1 / countFile * 100;
      const responseFileParsed = JSON.parse(responseFile);

      // upload texture files
      let formDataList: FormData[] = [];
      let countFileFormDataList: number[] = [];
      let currentFormData = new FormData();
      currentFormData.append('fileId', responseFileParsed.id);
      currentFormData.append('path', data.get('path') as string);
      let currentSize = 0;
      let indexTextureFile = 0;

      dataFiles.textureFiles.forEach((textureFile) => {
        if (currentSize + textureFile.size > MAX_SIZE_UPLOAD_FILE) {
          formDataList.push(currentFormData);
          countFileFormDataList.push(indexTextureFile);
          currentFormData = new FormData();
          currentFormData.append('fileId', responseFileParsed.id);
          currentFormData.append('path', data.get('path') as string);
          currentSize = 0;
          indexTextureFile = 0;
        }

        currentFormData.append('textureFiles', textureFile);
        currentFormData.append('textureFileMetaData[' + indexTextureFile + '][mesh]', 'mesh');
        currentFormData.append('textureFileMetaData[' + indexTextureFile + '][side]', 'side');
        currentFormData.append('textureFileMetaData[' + indexTextureFile + '][surface]', 'surface');
        currentSize += textureFile.size;
        indexTextureFile++;
      });

      if (currentFormData.has('textureFiles')) {
        formDataList.push(currentFormData);
        countFileFormDataList.push(indexTextureFile);
      }

      let lastResponse = responseFileParsed;

      const sendRequestSequentially = (index = 0) => {
        if (index >= formDataList.length) {
          onSuccess && onSuccess(JSON.stringify(lastResponse));
          return;
        }

        Users.addRepositoryTextureFileForUploadedFile(
          repositoryId,
          formDataList[index],
          countFileFormDataList[index] / countFile,
          currentProgress,
          onProgress,
          (response) => {
            const responseParsed = JSON.parse(response);
            currentProgress += countFileFormDataList[index] / countFile * 100;
            if (responseParsed?.textureFiles?.length === dataFiles.textureFiles.length) {
              lastResponse = responseParsed;
            }

            sendRequestSequentially(index + 1);
          },
          onError
        );
      };

      sendRequestSequentially();
    }, onError);
  } else {
    Users.addRepositoryFile(repositoryId, data, 1, 0, onProgress, onSuccess, onError);
  }
};

export const addRepositoryDirectory = async (
  repositoryId: string,
  data: ICreateRemoveDirectoryData,
): Promise<ICreateDirectoryResponse> => {
  try {
    const response = await Users.addRepositoryDirectory(repositoryId, data);

    return response;
  } catch (error) {
    console.error('Error adding repository directory:', error);
    return {} as ICreateDirectoryResponse;
  }
};

export const removeRepositoryFile = async (repositoryId: string, data: IRemoveFileData): Promise<boolean> => {
  try {
    const reposonse = await Users.removeRepositoryFile(repositoryId, data);

    if (reposonse?.success) {
      return true;
    }
    return false;
  } catch (error) {
    console.error('Error removing repository file:', error);
    return false;
  }
};

export const removeRepositoryDirectory = async (
  repositoryId: string,
  data: ICreateRemoveDirectoryData,
): Promise<boolean> => {
  try {
    const reposonse = await Users.removeRepositoryDirectory(repositoryId, data);

    if (reposonse?.success) {
      return true;
    }
    return false;
  } catch (error) {
    console.error('Error removing repository directory:', error);
    return false;
  }
};

export const updateRepositoryGeneral = async (repositoryId: string, data: IRenameRepositoryData): Promise<IRepositoryGeneral> => {
  try {
    const reposonse = await Users.updateRepositoryGeneral(repositoryId, data);

    return reposonse;
  } catch (error) {
    console.error('Error renaming repository file:', error);
    return {} as IRepositoryGeneral;
  }
};

export const renameRepositoryFile = async (
  repositoryId: string,
  data: IRenameFileData,
): Promise<ICreateFileResponse> => {
  try {
    const reposonse = await Users.renameRepositoryFile(repositoryId, data);

    return reposonse;
  } catch (error) {
    console.error('Error renaming repository file:', error);
    return {} as ICreateFileResponse;
  }
};

export const renameRepositoryDirectory = async (
  repositoryId: string,
  data: IRenameDirectoryData,
): Promise<ICreateDirectoryResponse> => {
  try {
    const reposonse = await Users.renameRepositoryDirectory(repositoryId, data);

    return reposonse;
  } catch (error) {
    console.error('Error renaming repository directory:', error);
    return {} as ICreateDirectoryResponse;
  }
};

export const updateRepositoryFile = async (
  repositoryId: string,
  data: FormData,
  dataFiles: {
    file: File,
    textureFiles: File[],
  },
  onProgress?: (progress: number) => void,
  onSuccess?: (response: string) => void,
  onError?: (error: string) => void,
): Promise<void> => {
  // check total size of files
  let totalSize = dataFiles.file.size;
  let countFile = 1;
  for (const textureFile of dataFiles.textureFiles) {
    totalSize += textureFile.size;
    countFile++;
  }
  let currentProgress = 0;

  if (totalSize > MAX_SIZE_UPLOAD_FILE) {
    // upload file before texture files
    const formDataFile = new FormData();
    formDataFile.append('file', dataFiles.file);
    formDataFile.append('path', data.get('path') as string);
    formDataFile.append('metaData[mesh]', 'mesh');
    formDataFile.append('metaData[side]', 'side');
    formDataFile.append('metaData[surface]', 'surface');
    formDataFile.append('fileId', data.get('fileId') as string);
    formDataFile.append('comment', data.get('comment') as string);

    Users.updateRepositoryFile(repositoryId, formDataFile, 1 / countFile, 0, onProgress, (responseFile) => {
      currentProgress += 1 / countFile * 100;
      const responseFileParsed = JSON.parse(responseFile);

      // upload texture files
      let formDataList: FormData[] = [];
      let countFileFormDataList: number[] = [];
      let currentFormData = new FormData();
      currentFormData.append('fileId', data.get('fileId') as string);
      currentFormData.append('path', data.get('path') as string);
      let currentSize = 0;
      let indexTextureFile = 0;

      dataFiles.textureFiles.forEach((textureFile) => {
        if (currentSize + textureFile.size > MAX_SIZE_UPLOAD_FILE) {
          formDataList.push(currentFormData);
          countFileFormDataList.push(indexTextureFile);
          currentFormData = new FormData();
          currentFormData.append('fileId', data.get('fileId') as string);
          currentFormData.append('path', data.get('path') as string);
          currentSize = 0;
          indexTextureFile = 0;
        }

        currentFormData.append('textureFiles', textureFile);
        currentFormData.append('textureFileMetaData[' + indexTextureFile + '][mesh]', 'mesh');
        currentFormData.append('textureFileMetaData[' + indexTextureFile + '][side]', 'side');
        currentFormData.append('textureFileMetaData[' + indexTextureFile + '][surface]', 'surface');
        currentSize += textureFile.size;
        indexTextureFile++;
      });

      if (currentFormData.has('textureFiles')) {
        formDataList.push(currentFormData);
        countFileFormDataList.push(indexTextureFile);
      }

      let lastResponse = responseFileParsed;

      const sendRequestSequentially = (index = 0) => {
        if (index >= formDataList.length) {
          onSuccess && onSuccess(JSON.stringify(lastResponse));
          return;
        }

        Users.addRepositoryTextureFileForUpcomingFile(
          repositoryId,
          formDataList[index],
          countFileFormDataList[index] / countFile,
          currentProgress,
          onProgress,
          (response) => {
            const responseParsed = JSON.parse(response);
            currentProgress += countFileFormDataList[index] / countFile * 100;
            if (responseParsed?.textureFiles?.length === dataFiles.textureFiles.length) {
              lastResponse = responseParsed;
            }

            sendRequestSequentially(index + 1);
          },
          onError
        );
      };

      sendRequestSequentially();
    }, onError);
  } else {
    Users.updateRepositoryFile(repositoryId, data, 1, 0, onProgress, onSuccess, onError);
  }
};

export const createRepositoryVersion = async (
  repositoryId: string,
  data: ICreateVersionData,
): Promise<IRepositoryVersion | IErrorResponse> => {
  try {
    const response = await Users.createRepositoryVersion(repositoryId, data);

    return response;
  } catch (error: any) {
    return {
      ...error.body,
      isError: true,
    } as IErrorResponse;
  }
};

export const removeRepositoryVersion = async (repositoryId: string, data: IRemoveRepositoryVersion): Promise<boolean> => {
  try {
    const reposonse = await Users.removeRepositoryVersion(repositoryId, data);

    if (reposonse?.success) {
      return true;
    }
    return false;
  } catch (error) {
    console.error('Error removing repository version:', error);
    return false;
  }
};

export const updateRepositoryTutorialsStatus = async (
  repositoryId: string,
  data: ITutorialStatus,
): Promise<boolean> => {
  try {
    const response = await Users.updateRepositoryTutorialsStatus(repositoryId, data);

    if (response?.tutorialStatus) {
      return true;
    }
    return false;
  } catch (error) {
    console.error('Error updating repository tutorials status:', error);
    return false;
  }
};

export const moveRepositoryFile = async (
  repositoryId: string,
  data: IMoveFileData,
): Promise<IFile | IErrorResponse> => {
  try {
    const response = await Users.moveRepositoryFile(repositoryId, data);

    return response;
  } catch (error: any) {
    return {
      ...error.body,
      isError: true,
    } as IErrorResponse;
  }
};

export const moveRepositoryDirectory = async (
  repositoryId: string,
  data: IMoveDirectoryData,
): Promise<IDirectory | IErrorResponse> => {
  try {
    const response = await Users.moveRepositoryDirectory(repositoryId, data);

    return response;
  } catch (error: any) {
    return {
      ...error.body,
      isError: true,
    } as IErrorResponse;
  }
};

export const addRepositoryMember = async (
  repositoryId: string,
  body: any,
): Promise<IAddMemberResponse | IErrorResponse> => {
  try {
    const response = await Users.addMember(repositoryId, body);

    return response;
  } catch (error: any) {
    return {
      ...error.body,
      isError: true,
    } as IErrorResponse;
  }
};
export const updateMemberRoles = async (
  repositoryId: string,
  body: any,
): Promise<any | IErrorResponse> => {
  try {
    const response = await Users.updateMemberRoles(repositoryId, body);

    return response;
  } catch (error: any) {
    return {
      ...error.body,
      isError: true,
    } as IErrorResponse;
  }
};

export const updateApplyCurrent = async (repositoryId: string | undefined, data: IGetFileData): Promise<boolean> => {
  try {
    const response = await Users.updateApplyCurrent(repositoryId, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateApplyUpcoming = async (repositoryId: string | undefined, data: IGetFileData): Promise<boolean> => {
  try {
    const response = await Users.updateApplyUpcoming(repositoryId, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getRepositoryTickets = async (repositoryId: string): Promise<ITicket[]> => {
  try {
    const response = await Users.getRepositoryTickets(repositoryId);

    return response;
  } catch (error) {
    console.error('Error fetching repository tickets:', error);
    return [];
  }
}

export const getRepositoryFile = async (repositoryId: string, data: IGetFileData): Promise<IFile | IErrorResponse> => {
  try {
    const response = await Users.getRepositoryFile(repositoryId, data);

    return response;
  } catch (error: any) {
    return {
      ...error.body,
      isError: true,
    } as IErrorResponse;
  }
}

export const downloadRepositoryFiles = async (repositoryId: string, data: IDownloadFileData): Promise<{ downloadUrl: string } | IErrorResponse> => {
  try {
    const response = await Users.downloadRepositoryFiles(repositoryId, data);

    return response;
  } catch (error: any) {
    return {
      ...error.body,
      isError: true,
    } as IErrorResponse;
  }
}

export const getTicketsChats = async (repositoryId: string, ticketId: string): Promise<ITicketMessage[]> => {
  try {
    const response = await Users.getTicketsChats(repositoryId, ticketId);

    return response;
  } catch (error) {
    console.error('Error fetching tickets chats:', error);
    return [];
  }
}

export const createTicketMessage = async (repositoryId: string, ticketId: string, data: FormData): Promise<ITicketMessage | IErrorResponse> => {
  try {
    const response = await Users.createTicketMessage(repositoryId, ticketId, data);

    return response;
  } catch (error: any) {
    return {
      ...error.body,
      isError: true,
    } as IErrorResponse;
  }
}
