import React from 'react';
import 'assets/scss/components/RightClickContextMenu.scss';
import { IContextMenuItem, IContextMenuPosition } from 'types';
import { RIGHT_CLICK_CONTEXT_MENU_WIDTH, RIGHT_CLICK_CONTEXT_MENU_HEIGHT } from 'constants/Repository';

interface RightClickContextMenuProps {
  contextMenuPosition: IContextMenuPosition;
  contextMenuItems: IContextMenuItem[];
  executeAction: (action: () => void, isDisabled?: boolean) => void;
}

export const RightClickContextMenu: React.FC<RightClickContextMenuProps> = ({
  contextMenuPosition,
  contextMenuItems,
  executeAction,
}) => {
  const width = RIGHT_CLICK_CONTEXT_MENU_WIDTH;
  const height = RIGHT_CLICK_CONTEXT_MENU_HEIGHT;

  return (
    <div
      style={{
        position: 'fixed',
        top: contextMenuPosition.isTop ? `calc(${contextMenuPosition.y}px - ${height}px)` : contextMenuPosition.y,
        left: contextMenuPosition.isRightAligned ? 'unset' : contextMenuPosition.x,
        right: contextMenuPosition.isRightAligned ? window.innerWidth - contextMenuPosition.x + width : 'unset',
        zIndex: 1001,
      }}
      className="context-menu-container"
    >
      <div className="context-menu">
        {contextMenuItems.map((menuItem, index) => (
          <div
            key={index}
            onClick={() => executeAction(menuItem.action, menuItem.isDisabled)}
            className={`context-menu-item ${menuItem.className}`}
          >
            <div className="context-menu-item-wrapper">
              <img src={menuItem.icon} className="context-menu-icon" alt={menuItem.label} />
              <div className="content-context-menu font-en-16 font-ja-12">
                {menuItem.label}
                {menuItem.afterIcon && (
                  <img
                    src={menuItem.afterIcon}
                    alt={menuItem.label}
                    width={15}
                    height={15}
                    style={{
                      marginRight: '5px',
                      transform: 'rotate(150deg)',
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
