import React from 'react';
import iconChekboxUncheck from '../../assets/images//icons/checkbox-uncheck.svg';
import iconChekboxChecked from '../../assets/images//icons/checkbox-checked.svg';

interface ChecboxProp {
	isChecked: boolean;
}

export const Checkbox: React.FC<ChecboxProp> = ({ isChecked }) => {
	return isChecked ? (
		<img src={iconChekboxChecked} alt="checked"/>
	) : (
		<img src={iconChekboxUncheck} alt="uncheck"/>
	);
};
