import wrapIcon from './wrapIcon';

type IconProps = {
  onMouseColor?: string;
  onChangeColor?: string;
  width?: string;
  height?: string;
  isActive?: boolean;
};

function Icon({
  onMouseColor,
  onChangeColor,
  width,
  height,
  isActive,
  ...props
}: Readonly<IconProps>) {
  return (
    <svg
      width={width ?? '18'}
      height={height ?? '18'}
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.21273 17C6.25873 17 3.65667 15.4931 2.13332 13.2061L4.73984 11.7012C5.7378 13.0931 7.36936 14 9.21273 14C12.2503 14 14.7127 11.5376 14.7127 8.5C14.7127 5.46243 12.2503 3 9.21273 3C7.85412 3 6.61057 3.49261 5.65094 4.30896L8.13798 5.21417L1.85997 9.8024L0 2.25219L2.57538 3.18955C4.1331 1.24514 6.52751 0 9.21273 0C13.9072 0 17.7127 3.80558 17.7127 8.5C17.7127 13.1944 13.9072 17 9.21273 17Z"
        fill={isActive ? onMouseColor : onChangeColor || 'black'}
      />
    </svg>
  );
}
const ResetIcon = wrapIcon(Icon);
export default ResetIcon;
