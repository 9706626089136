import React from 'react';
import { ModalConfirm } from 'components';
import { useTranslation } from 'react-i18next';

interface CreateVersionModalProps {
  isVisibleSaveVersion: boolean;
  setIsVisibleSaveVersion: (value: boolean) => void;
  versionInput: string;
  setVersionInput: (value: string) => void;
  handleSumitCreateVersion: () => void;
}

export const CreateVersionModal: React.FC<CreateVersionModalProps> = ({
  isVisibleSaveVersion,
  setIsVisibleSaveVersion,
  versionInput,
  setVersionInput,
  handleSumitCreateVersion,
}) => {
  const { t } = useTranslation();

  return (
    <ModalConfirm
      setVisible={setIsVisibleSaveVersion}
      isVisible={isVisibleSaveVersion}
      width="647px"
      title={<span className="font-en-18 font-ja-14">{t('createVersionModal.title')}</span>}
      content={<span className="font-en-16 font-ja-12">{t('createVersionModal.description')}</span>}
      onSubmit={handleSumitCreateVersion}
      textSubmit={t('common.btnSave')}
    >
      <div className="input-wrapper">
        <div className="input-search">
          <input type="text" value={versionInput} onChange={(e) => setVersionInput(e.target.value)} />
        </div>
      </div>
    </ModalConfirm>
  );
};
