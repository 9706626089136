import React, { useEffect, useState } from 'react';
import { DirectoryHeader } from 'layouts';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { iconSettingEmail, iconSettingGeneral, iconSettingManagement } from 'assets/images';
import 'assets/scss/layouts/Setting.scss';
import { getRepositoryDetail } from 'api/repository';
import { useQuery } from 'react-query';
import { IRepository } from 'types';
import { useTranslation } from 'react-i18next';

enum SETTING_SCREEN {
  GENERAL = 0,
  ACCESS_MANAGEMENT = 1,
  EMAIL_NOTIFY = 2,
}

type MenuItem = {
  label: string;
  path: string;
  icon: string;
};

export const SettingLayout: React.FC = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [indexMenuSetting, setIndexMenuSetting] = useState<number>(SETTING_SCREEN.GENERAL);

  const { data: repositoryDetail } = useQuery<IRepository>(['REPOSITORY_DETAIL', id], () => getRepositoryDetail(id!), {
    enabled: !!id,
    refetchOnWindowFocus: false,
  });

  const menu: MenuItem[] = [
    {
      label: t('settingsScreen.general.title'),
      path: '/',
      icon: iconSettingGeneral,
    },
    {
      label: t('settingsScreen.accessManagement.title'),
      path: '/access-management',
      icon: iconSettingManagement,
    },
    {
      label: t('settingsScreen.emailNotification.title'),
      path: '/email-notifications',
      icon: iconSettingEmail,
    },
  ];

  useEffect(() => {
    const path = window.location.pathname;
    if (path.includes(menu[SETTING_SCREEN.ACCESS_MANAGEMENT].path))
      setIndexMenuSetting(SETTING_SCREEN.ACCESS_MANAGEMENT);
    else if (path.includes(menu[SETTING_SCREEN.EMAIL_NOTIFY].path)) setIndexMenuSetting(SETTING_SCREEN.EMAIL_NOTIFY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNavigate = (item: MenuItem, index: number) => {
    if (indexMenuSetting !== index) {
      setIndexMenuSetting(index);
      navigate(`/repository/${id}/settings${item.path}`);
    }
  };

  return (
    <div className="settingLayout">
      <DirectoryHeader repository={repositoryDetail} selectedItem={2} />
      <div className="settingLayout__wrapper flex">
        <div className="settingLayout__sider">
          {menu.map((item, index) => (
            <button
              key={item.label}
              className={`${indexMenuSetting === index ? 'selected' : ''}`}
              onClick={() => handleNavigate(item, index)}
            >
              <img src={item.icon} alt="icon" />
              <span className="font-en-16 font-ja-12">{item.label}</span>
            </button>
          ))}
        </div>

        <div className="settingLayout__content">
          <h2 className="font-en-24 font-ja-20">{menu[indexMenuSetting].label}</h2>
          <Outlet />
        </div>
      </div>
    </div>
  );
};
