import React, { useCallback, useEffect, useState } from 'react';
import { ModalConfirm } from 'components';
import { SUPPORTED_FILE_TEXTURE_TYPE, SUPPORTED_MODEL_FILE_TYPE } from 'constants/Repository';
import { useAlertContext } from 'contexts/AlertContextProvider';
import 'assets/scss/components/UploadFileModal.scss';
import { useTranslation } from 'react-i18next';

interface UploadFileModalProps {
  handleUploadThisItem: (file: File, fileTextures: File[]) => void;
  isVisibleUploadFile: boolean;
  setIsVisibleUploadFile: (value: boolean) => void;
}

export const UploadFileModal: React.FC<UploadFileModalProps> = ({
  isVisibleUploadFile,
  handleUploadThisItem,
  setIsVisibleUploadFile,
}) => {
  const { t } = useTranslation();
  const { alert } = useAlertContext();
  const inputFileRef = React.useRef<HTMLInputElement>(null);
  const inputFileTextureRef = React.useRef<HTMLInputElement>(null);

  const [selectedUploadFile, setSelectedUploadFile] = useState<File | null>(null);
  const [fileTexture, setFileTexture] = useState<File[]>([]);
  const [isDraggingFileUpload, setIsDraggingFileUpload] = useState(false);
  const [isDraggingFileTextureUpload, setIsDraggingFileTextureUpload] = useState(false);

  const handleClickSelectFile = useCallback(() => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  }, [inputFileRef]);

  const handleClickSelectFileTexture = useCallback(() => {
    if (inputFileTextureRef.current) {
      inputFileTextureRef.current.click();
    }
  }, [inputFileTextureRef]);

  const clearFiles = () => {
    setSelectedUploadFile(null);
    setFileTexture([]);
  };

  const onCancel = () => {
    clearFiles();
    setIsVisibleUploadFile(false);
  };

  // Drag and drop for model file
  const handleDragOverUpload = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDraggingFileUpload(true);
  };

  const handleDropUpload = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDraggingFileUpload(false);
    const files = event.dataTransfer.files;
    if (files.length > 1) {
      alert({ type: 'warning', content: t('uploadFileModal.message.modelFileLimit') });
    } else {
      if (files && files[0]) {
        setSelectedUploadFile(files[0]);
      }
    }
  };

  const handleDragLeaveUpload = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDraggingFileUpload(false);
  };

  // Drag and drop for texture files
  const handleDragOverTextureUpload = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDraggingFileTextureUpload(true);
  };

  const handleDropTextureUpload = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDraggingFileTextureUpload(false);
    const files = Array.from(event.dataTransfer.files);
    const validFiles = files.filter((file) => {
      const type = file.name.split('.').pop();
      if (!SUPPORTED_FILE_TEXTURE_TYPE.includes('.' + type)) {
        alert({ type: 'warning', content: t('uploadFileModal.message.fileTypeNotSupported') });
        return false;
      }
      return true;
    });
    setFileTexture((prev) => [...prev, ...validFiles]);
  };

  const handleDragLeaveTextureUpload = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDraggingFileTextureUpload(false);
  };

  const handleConfirmUpload = () => {
    if (!selectedUploadFile) {
      alert({ type: 'warning', content: t('uploadFileModal.message.modelFileRequired') });
      return;
    }

    handleUploadThisItem(selectedUploadFile, fileTexture);
    setIsVisibleUploadFile(false);
  };

  useEffect(() => {
    clearFiles();
  }, [isVisibleUploadFile]);

  return (
    <ModalConfirm
      setVisible={setIsVisibleUploadFile}
      isVisible={isVisibleUploadFile}
      width="627px"
      title={<span className="font-en-18 font-ja-14">{t('uploadFileModal.title')}</span>}
      content={<span className="font-en-16 font-ja-12">{t('uploadFileModal.description')}</span>}
      textSubmit={t('common.btnUpload')}
      onSubmit={handleConfirmUpload}
      onCancel={onCancel}
      classContainer="upload-file-modal"
    >
      <div className="upload-fn-title">
        <span className="font-en-16 font-ja-12">{t('uploadFileModal.descriptionSelectModelFile')}</span>
        <span className="font-en font-en-16 font-ja-16">({SUPPORTED_MODEL_FILE_TYPE.join(', ')})</span>
      </div>
      <div className="function-upload-file">
        <div className="select-file-wrapper" onDragLeave={handleDragLeaveUpload}>
          <input
            ref={inputFileRef}
            type="file"
            className="file-select-upload"
            accept={SUPPORTED_MODEL_FILE_TYPE.join(',')}
            onChange={(e) => {
              if (e.target.files && e.target.files.length > 0) {
                const file = e.target.files[0];
                const name = file.name;
                const type = name.split('.')[1];
                if (SUPPORTED_MODEL_FILE_TYPE.includes('.' + type)) {
                  setSelectedUploadFile(file);
                } else {
                  alert({ type: 'warning', content: t('uploadFileModal.message.fileTypeNotSupported') });
                }
              }
            }}
            onClick={(e) => e.stopPropagation()}
          />
          <button className="custom-file-label font-en-16 font-ja-12" onClick={handleClickSelectFile}>
            {t('uploadFileModal.btnSelectFile')}
          </button>
          {selectedUploadFile && <span className="file-name">{selectedUploadFile.name}</span>}
        </div>
        <div
          className={`drag-drop-file-wrapper ${isDraggingFileUpload ? 'dragging' : ''}`}
          onDragOver={handleDragOverUpload}
          onDrop={handleDropUpload}
        >
          <div className="main-text font-en-16 font-ja-12">{t('uploadFileModal.dragAndDrop')}</div>
        </div>
      </div>
      <div className="upload-fn-title mt-4">
        <span className="font-en-16 font-ja-12">{t('uploadFileModal.descriptionSelectTexturesFile')}</span>
        <span className="font-en font-en-16 font-ja-16">({SUPPORTED_FILE_TEXTURE_TYPE.join(', ')})</span>
      </div>
      <div className="function-upload-file">
        <div className="select-file-wrapper" onDragLeave={handleDragLeaveTextureUpload}>
          <input
            ref={inputFileTextureRef}
            type="file"
            className="file-select-upload"
            accept={SUPPORTED_FILE_TEXTURE_TYPE.join(',')}
            multiple
            onChange={(e) => {
              if (e.target.files && e.target.files.length > 0) {
                const files = Array.from(e.target.files);
                const validFiles = files.filter((file) => {
                  const name = file.name;
                  const type = name.split('.')[1];
                  if (!SUPPORTED_FILE_TEXTURE_TYPE.includes('.' + type)) {
                    alert({ type: 'warning', content: t('uploadFileModal.message.fileTypeNotSupported') });
                    return false;
                  }
                  return true;
                });
                setFileTexture((prev) => [...prev, ...validFiles]);
              }
            }}
            onClick={(e) => e.stopPropagation()}
          />
          <button className="custom-file-label font-en-16 font-ja-12" onClick={handleClickSelectFileTexture}>
            {t('uploadFileModal.btnSelectFiles')}
          </button>
          {fileTexture.length > 0 && (
            <span className="file-name">
              {fileTexture.map((file, index) => (
                <span key={index} className="file-name-item">
                  {file.name}
                </span>
              ))}
            </span>
          )}
        </div>
        <div
          className={`drag-drop-file-wrapper ${isDraggingFileTextureUpload ? 'dragging' : ''}`}
          onDragOver={handleDragOverTextureUpload}
          onDrop={handleDropTextureUpload}
        >
          <div className="main-text font-en-16 font-ja-12">{t('uploadFileModal.dragAndDrop')}</div>
        </div>
      </div>
    </ModalConfirm>
  );
};
