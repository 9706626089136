import React from 'react';
import 'assets/scss/components/ModalConfirm.scss';
import { useTranslation } from 'react-i18next';

interface TypeModalConfirm {
  isVisible?: any;
  setVisible?: any;
  title?: JSX.Element | string;
  content?: JSX.Element | string;
  hasBtnCancel?: boolean;
  hasBtnSave?: boolean;
  hasBtnSubmit?: boolean;
  textSubmit?: string;
  textSave?: string;
  textCancel?: string;
  onCancel?: () => void;
  onSubmit?: () => void;
  onSave?: () => void;
  children?: React.ReactNode;
  width?: string;
  classContainer?: string;
}

export const ModalConfirm: React.FC<TypeModalConfirm> = ({
  title = '',
  content = '',
  hasBtnCancel = true,
  hasBtnSave = false,
  hasBtnSubmit = true,
  textSubmit,
  textSave,
  textCancel,
  isVisible = false,
  setVisible,
  onCancel = () => setVisible(false),
  onSubmit = () => setVisible(false),
  onSave = () => setVisible(false),
  children,
  width,
  classContainer,
}) => {
  const { t } = useTranslation();

  return (
    isVisible && (
      <div className={`modal-confirm-container ${classContainer ?? ''}`}>
        <div className="modal-confirm" style={{ maxWidth: width ?? '' }}>
          {title && <div className="modal-confirm-title">{title}</div>}
          {content && <div className="modal-confirm-content">{content}</div>}
          {children && <div className="modal-confirm-children">{children}</div>}
          <div className="modal-confirm-action">
            {hasBtnCancel && (
              <button className="font-en-16 font-ja-12" onClick={onCancel}>
                {textCancel ?? t('common.btnCancel')}
              </button>
            )}
            {hasBtnSave && (
              <button className="text-white bg-blue font-en-16 font-ja-12" onClick={onSave}>
                {textSave ?? t('common.btnSave')}
              </button>
            )}
            {hasBtnSubmit && (
              <button
                className={`text-white font-en-16 font-ja-12 ${textSubmit === t('common.btnDelete') ? 'bg-red' : 'bg-blue-light'}`}
                onClick={onSubmit}
              >
                {textSubmit ?? t('common.btnApply')}
              </button>
            )}
          </div>
        </div>
      </div>
    )
  );
};
