import React from 'react';
import 'assets/scss/layouts/HomeHeader.scss';
import { RightHeader } from './RightHeader';
import { useNavigate } from 'react-router-dom';
import { Logo } from 'components';
import { useTranslation } from 'react-i18next';

export const HomeHeader: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleGoToHome = () => {
    navigate('/');
  };

  return (
    <header className="header">
      <div className="header-left">
        <div className="logo">
          <Logo onClick={handleGoToHome} />
        </div>
        <div className="title">
          <span className="text-bold-black font-en-18 font-ja-14">{t('header.dashboard')}</span>
        </div>
      </div>
      <RightHeader />
    </header>
  );
};
