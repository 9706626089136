import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import 'assets/scss/components/MarkdownViewer.scss';

interface MarkdownViewerProps {
  url: string;
}

export const MarkdownViewer: React.FC<MarkdownViewerProps> = ({ url }) => {
  const [markdown, setMarkdown] = useState('');

  useEffect(() => {
    fetch(url)
      .then((res) => res.text())
      .then((text) => setMarkdown(text));
  }, [url]);

  return (
    <div className="markdown-preview font-ja">
      <ReactMarkdown children={markdown} remarkPlugins={[remarkGfm]} />
    </div>
  );
};
