import React, { useEffect, useState } from 'react';
import 'assets/scss/layouts/HomeScreen.scss';
import { CardRepository, LoadingOverlay, RepositoryInfo, EnhanceHomePage } from 'components';
import { HomeHeader, SideBar } from 'layouts';
import { iconDot, iconPuzzle } from 'assets/images';
import { useSelector } from 'react-redux';
import { IFile } from 'types';
import { getModelFilesFromLocalStorage } from 'libs/repository';
import { useTranslation } from 'react-i18next';

export const Home: React.FC = () => {
  const { t } = useTranslation();
  const stateBase = useSelector((state: any) => state.base);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const recentModelFiles = getModelFilesFromLocalStorage();

  useEffect(() => {
    setIsLoading(stateBase.isLoading);
  }, [stateBase.isLoading]);

  return (
    <div className="homeScreen">
      <LoadingOverlay isLoading={isLoading} />
      <div className="layout-header">
        <HomeHeader />
      </div>
      <div className="layout-main">
        <aside className="layout-sidebar">
          <SideBar />
        </aside>
        <main className="layout-content">
          <div className="conainer-wrapper">
            <div className="header-container">
              <div className="header-left">
                <div className="icon-puzzle-piece">
                  <img src={iconPuzzle} alt="" width={40} height={40} />
                </div>
                <div className="text-header font-en-16 font-ja-12">{t('homepage.startYourCraft')}</div>
              </div>
              <div className="header-right">
                <img src={iconDot} alt="" width={24} height={40} />
              </div>
            </div>
            {/* 
              This is test code to sign in with google 
              This part will be changed after Login Page is completed
            */}
            <div className="homeScreen__top">
              <div className="homeScreen__left ja:!pb-6">
                <div className="homeScreen__left--title">
                  <h3 className="font-en-24 font-ja-20 ja:!mb-2.5">{t('homepage.createNewRepository')}</h3>
                  <p className="font-en-16 font-ja-12">{t('homepage.createNewRepositoryDescription')}</p>
                  <div className="homeScreen__line"></div>
                  <p className="note font-en-16 font-ja-12 text-normal-jp">{t('homepage.createNewRepositoryNote')}</p>
                </div>
                <RepositoryInfo customClassName="homePage__createRepo--custom" />
              </div>
              <div className="homeScreen__colLine"></div>
              <div className="homeScreen__right">
                <EnhanceHomePage />
              </div>
            </div>
            <div className="homeScreen__lineEndTop"></div>
            <div className="header-container">
              <div className="header-left">
                <div className="icon-puzzle-piece">
                  <img src={iconPuzzle} alt="" width={40} height={40} />
                </div>
                <div className="text-header font-en-16 font-ja-12">{t('homepage.recentActivities')}</div>
              </div>
              <div className="header-right">
                <img src={iconDot} alt="" width={24} height={40} />
              </div>
            </div>

            <div className="main-container">
              {recentModelFiles.map((file: IFile, index: number) => (
                <CardRepository key={index} file={file} />
              ))}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
