import { useCallback, useState } from 'react';

export const useLoading = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const withLoading = useCallback(async (promise: any) => {
		try {
			setIsLoading(true);
			return await promise();
		} finally {
			setIsLoading(false);
		}
	}, []);

	return { withLoading, isLoading };
};
