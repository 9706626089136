import React from 'react';
import 'assets/scss/components/MyPageContent.scss';
import { CardRepository } from 'components';
import { Outlet } from 'react-router-dom';
import { IFile } from 'types';
import { getModelFilesFromLocalStorage } from 'libs/repository';

export const MyPageContent: React.FC = () => {
  const recentModelFiles = getModelFilesFromLocalStorage();

  return (
    <div className="myPageContent">
      <div className="myPageContent__head">
        <Outlet />
      </div>

      <div className="myPageContent__body">
        {recentModelFiles.map((file: IFile, index: number) => (
          <CardRepository key={index} file={file} />
        ))}
      </div>
    </div>
  );
};
