import React from 'react';
import { HomeHeader, SideBar } from 'layouts';
import { MyPageContent } from 'layouts';

export const MyPage: React.FC = () => {
  return (
    <React.Fragment>
      <div className="layout-header">
        <HomeHeader />
      </div>
      <div className="layout-main">
        <aside className="layout-sidebar">
          <SideBar />
        </aside>
        <main className="layout-content">
          <MyPageContent />
        </main>
      </div>
    </React.Fragment>
  );
};
