import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from 'locales/en.json';
import ja from 'locales/ja.json';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: ['ja', 'en'],
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: { translation: en },
      ja: { translation: ja },
    },
  }).then(() => {
    if (!i18n?.options?.resources?.[i18n.language]) {
      i18n.changeLanguage('ja');
    }
  });

const updateFontBasedOnLanguage = () => {
  const currentLanguage = i18n.language;

  document.documentElement.lang = currentLanguage;
  document.body.className = `lang-${currentLanguage}`;
};

i18n.on('languageChanged', () => {
  updateFontBasedOnLanguage();
});

updateFontBasedOnLanguage();
