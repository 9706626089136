import React from 'react';
import { ModelViewer } from './ModelViewer';
import 'assets/scss/components/PreviewFile.scss';
import { isMarkdownFile, isModelFile } from 'libs/previewFile';
import { MarkdownViewer } from './MarkdownViewer';
import { ITextureFile } from '../../types';
import { useTranslation } from 'react-i18next';

interface PreviewFileProps {
  containerClassName?: string;
  url?: string | null;
  pathFull?: string;
  textureFiles?: ITextureFile[];
}

export const PreviewFile: React.FC<PreviewFileProps> = ({
  containerClassName = '',
  url,
  textureFiles,
  pathFull,
  ...props
}) => {
  const { t } = useTranslation();

  const renderViewer = () => {
    if (!url) return <div className="note font-en-16 font-ja-12">{t('previewFile.noSelectedFile')}</div>;
    if (isMarkdownFile(url)) {
      return <MarkdownViewer url={url} />;
    }
    if (isModelFile(url)) {
      return <ModelViewer url={url} {...props} textureFiles={textureFiles} />;
    }
    return <div className="note font-en-16 font-ja-12">{t('previewFile.noPreview')}</div>;
  };

  return (
    <div className={`preview-file-container ${containerClassName}`}>
      {pathFull && isModelFile(url) ? (
        <div className="header-preview">
          {pathFull && <span className="text-bold-black font-en font-en-16">{pathFull}</span>}
        </div>
      ) : (
        <div className="header-markdown-preview">
          {pathFull && <span className="text-bold-black font-en font-en-16">{pathFull}</span>}
        </div>
      )}
      <div className="preview-file-main">{renderViewer()}</div>
    </div>
  );
};
