import React from 'react';
import 'assets/scss/components/LoadingOverlay.scss';
import { LoadingAnimation } from 'app/icons/LoadingAnimation';

interface ILoadingOverlayProps {
  isLoading: boolean;
  progress?: number | null;
  containerClassName?: string;
}

export const LoadingOverlay: React.FC<ILoadingOverlayProps> = ({
  isLoading,
  progress = null,
  containerClassName = '',
}) => {
  const strokeDasharray = 2 * Math.PI * 70;
  const strokeDashoffset = strokeDasharray - ((progress || 0) / 100) * strokeDasharray;

  return (
    <div className={`loading-overlay ${containerClassName} ${isLoading ? 'loading' : ''}`}>
      <div className="loading-wrapper">
        {progress !== null && progress !== undefined && (
          <svg className="progress-circle" width="150" height="150">
            <circle className="progress-background" cx="75" cy="75" r="70" stroke="#e0e0e0" strokeWidth="3" />
            <circle
              className="progress-foreground"
              cx="75"
              cy="75"
              r="70"
              stroke="#3498db"
              strokeWidth="3"
              strokeDasharray={strokeDasharray}
              strokeDashoffset={strokeDashoffset}
              fill="none"
            />
          </svg>
        )}
        <div className="loading-animation-wrapper">
          <LoadingAnimation />
        </div>
      </div>
    </div>
  );
};
