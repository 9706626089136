import { iconAlertError, iconAlertInfo, iconAlertSuccess, iconAlertWarning } from 'assets/images';
import 'assets/scss/layouts/AlertCustom.scss';

interface IAlertBase {
  type: 'success' | 'info' | 'warning' | 'error';
  content: React.ReactNode;
}

export const AlertCustom: React.FC<IAlertBase> = ({ type = 'success', content }) => {
  const renderIcon = () => {
    switch (type) {
      case 'success':
        return iconAlertSuccess;
      case 'info':
        return iconAlertInfo;
      case 'warning':
        return iconAlertWarning;
      case 'error':
        return iconAlertError;
    }
  };

  return (
    <div className={`alertCustom alert-${type}`}>
      <img src={renderIcon()} alt="icon" className="icon-alert" />
      <span className="font-en-16 font-ja-12">{content}</span>
    </div>
  );
};
