import React, { useCallback, useEffect, useRef, useState } from 'react';
import 'assets/scss/components/ModalAddMember.scss';
import { iconSearch, iconXClose } from 'assets/images';
import useDebounce from 'hooks/useDebounce';
import { MEMBER_ROLE_TEXT } from 'constants/Repository';
import { useAlertContext } from 'contexts/AlertContextProvider';
import { useParams } from 'react-router-dom';
import { IAddMemberParams, IUserSearch } from 'types/user';
import { addRepositoryMember } from 'api/repository';
import { searchUser } from 'api/user';
import { Loading, LoadingOverlay } from 'components';
import { useTranslation } from 'react-i18next';

interface IProps {
  onClose: () => void;
  onSucess?: () => void;
}

const roleOptions = [MEMBER_ROLE_TEXT.OWNER, MEMBER_ROLE_TEXT.ADMIN, MEMBER_ROLE_TEXT.EDITOR, MEMBER_ROLE_TEXT.VIEWER];

export const ModalAddMember: React.FC<IProps> = ({ onClose, onSucess = () => {} }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const modalRef = useRef<HTMLDivElement | null>(null);

  const [isLoadingAddMember, setIsLoadingAddMember] = useState<boolean>(false);
  const [isLoadingSearch, setIsLoadingSearch] = useState<boolean>(false);
  const [peoples, setPeoples] = useState<IUserSearch[]>([]);
  const [peopleSelected, setPeopleSelected] = useState<IUserSearch>({} as IUserSearch);
  const [roleSelected, setRoleSelected] = useState<string | null>(null);
  const [keySearch, setKeySearch] = useState<string>('');
  const [textNotFound, setTextNotFound] = useState<string>('');
  const debouncedKeySearch = useDebounce(keySearch.trim(), 500);

  const { alert } = useAlertContext();

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeySearch(event.target.value);
  };

  const searchPeople = async (keyword: string) => {
    setIsLoadingSearch(true);
    const response = await searchUser(keyword);
    if (!('isError' in response) && response.length > 0) {
      setPeoples(response);
      setTextNotFound('');
    } else {
      setTextNotFound(`Could not find a 4D Pocket account matching "${keyword}"`);
    }
    setIsLoadingSearch(false);
  };

  const handleSelectPeople = (event: React.MouseEvent<HTMLDivElement>, people: IUserSearch) => {
    event.stopPropagation();
    setKeySearch('');
    setPeopleSelected(people);
  };

  const removePeopleSelected = (event: React.MouseEvent<HTMLImageElement>) => {
    event.stopPropagation();
    setPeopleSelected({} as IUserSearch);
  };

  const handleChangeRole = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRoleSelected(event.target.value);
  };

  const handleAddPeople = useCallback(async () => {
    if (!id) return;
    setIsLoadingAddMember(true);
    const params: IAddMemberParams = {
      members: [
        {
          email: peopleSelected.email,
          role: (roleSelected || '').toUpperCase(),
        },
      ],
    };
    const response = await addRepositoryMember(id, params);
    if (!('isError' in response)) {
      onSucess();
      alert({ type: 'success', content: t('modalAddMember.message.addMemberSuccess') });
    } else if ('isError' in response) {
      alert({ type: 'error', content: t('modalAddMember.message.addMemberNotAccepted') });
    } else {
      alert({ type: 'error', content: t('modalAddMember.message.addMemberFailed') });
    }
    setIsLoadingAddMember(false);
    setPeopleSelected({} as IUserSearch);
    setRoleSelected(null);
    onClose();
  }, [id, peopleSelected, roleSelected, onSucess, alert, onClose, t]);

  const handleClickOutsideModal = useCallback(
    (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    },
    [modalRef, onClose],
  );

  useEffect(() => {
    if (debouncedKeySearch) {
      searchPeople(debouncedKeySearch);
    } else {
      setPeoples([]);
      setTextNotFound('');
    }
  }, [debouncedKeySearch]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideModal);
    return () => {
      document.removeEventListener('click', handleClickOutsideModal);
    };
  }, [handleClickOutsideModal]);

  return (
    <div className="modalAddMember">
      <LoadingOverlay isLoading={isLoadingAddMember} />
      <div className="modalAddMember__wrapper" ref={modalRef}>
        {!peopleSelected.id ? (
          <React.Fragment>
            <h4 className="font-en-18 font-ja-14">{t('modalAddMember.titleSeach')}</h4>
            <div className="input-wrapper">
              <div className="input-search">
                <div className="icon-search">
                  <img src={iconSearch} alt="" width={17} height={20} />
                </div>
                <input
                  type="text"
                  placeholder={t('modalAddMember.placeholderSearch')}
                  value={keySearch}
                  onChange={onChangeInput}
                />
              </div>
            </div>

            <div className="modalAddMember__list beauty-scroll">
              {peoples.map((people) => (
                <div
                  key={people.id}
                  className="modalAddMember__item"
                  onClick={(event) => handleSelectPeople(event, people)}
                >
                  <img src={people.avatarUrl} alt="avatar" className="avatar" />
                  <div className="info">
                    <span className="email font-ja">{people.email}</span>
                    <span className="name font-ja">{people.name}</span>
                  </div>
                </div>
              ))}
              {isLoadingSearch && <Loading spinnerClassName="spinner-small" />}
              {!isLoadingSearch && !!textNotFound && <p>{textNotFound}</p>}
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <h5 className="m-0 mb-2 text-center font-en-18 font-ja-14">{t('modalAddMember.titleAdd')}</h5>
            <div className="modalAddMember__item modalAddMember__item--selected">
              <img src={peopleSelected.avatarUrl} alt="avatar" className="avatar" />
              <div className="info">
                <span className="email font-ja">{peopleSelected.email}</span>
                <span className="name font-ja">{peopleSelected.name}</span>
              </div>
              <img src={iconXClose} alt="icon" className="close" onClick={removePeopleSelected} />
            </div>

            <div className="px-2">
              <h6 className="m-0 font-en-16 font-ja-12">{t('modalAddMember.chooseRole')}</h6>
              <div className="modalAddMember__radio">
                {roleOptions.map((role) => (
                  <div key={role} className="modalAddMember__radio--item">
                    <input
                      type="radio"
                      id={`radio-${role}`}
                      value={role}
                      checked={role === roleSelected}
                      onChange={handleChangeRole}
                    />
                    <label htmlFor={`radio-${role}`}></label>
                    <label className="label">{role}</label>
                  </div>
                ))}
              </div>
            </div>
          </React.Fragment>
        )}
        <div className="modalAddMember__wrapBtn">
          <button onClick={onClose} className="common btn-cancel font-en-16 font-ja-12">
            {t('common.btnCancel')}
          </button>
          <button
            className={`common font-en-16 font-ja-12 ${!!roleSelected ? '' : 'opacity-60'}`}
            disabled={!roleSelected}
            onClick={handleAddPeople}
          >
            {t('modalAddMember.btnAdd')}
          </button>
        </div>
      </div>
    </div>
  );
};
