import wrapIcon from './wrapIcon';

type IconProps = {
  onMouseColor?: string;
  onChangeColor?: string;
  width?: string;
  height?: string;
  isActive?: boolean;
};

function Icon({
  onMouseColor,
  onChangeColor,
  width,
  height,
  isActive,
  ...props
}: Readonly<IconProps>) {
  return (
    <svg
      width={width ?? '18'}
      height={height ?? '18'}
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5623 8.06226L6.50017 0.562256L0.437988 8.06226H3.50017L3.50017 13.5623L9.50017 13.5623V8.06226L12.5623 8.06226Z"
        fill={isActive ? onMouseColor : onChangeColor || 'black'}
      />
    </svg>
  );
}
const ArrowUpIcon = wrapIcon(Icon);
export default ArrowUpIcon;
