import React from 'react';
import 'assets/scss/components/HoverItemMenu.scss';
import { IHoverMenuItem, IHoverMenuPosition } from 'types';

interface HoverItemMenuProps {
  hoverMenuPosition: IHoverMenuPosition;
  hoverMenuItems: IHoverMenuItem[];
  executeAction: (action: () => void, isDisabled?: boolean) => void;
}

export const HoverItemMenu: React.FC<HoverItemMenuProps> = ({ hoverMenuPosition, hoverMenuItems, executeAction }) => {
  return (
    <div
      style={{
        position: 'fixed',
        top: hoverMenuPosition.y,
        left: hoverMenuPosition.x,
        zIndex: 1000,
      }}
      className="hover-menu-container"
    >
      <div className="hover-menu">
        {hoverMenuItems.map((menuItem, index) => (
          <div className="tooltip-container" key={index}>
            <div className="!relative">
              <img
                src={menuItem.icon}
                onClick={() => executeAction(menuItem.action)}
                className="hover-icon"
                alt={menuItem.label}
              />
              <span className="tooltip-text font-en-16 font-ja-12">{menuItem.label}</span>
            </div>
          </div>
        ))}
        <div className="h-full"></div>
      </div>
    </div>
  );
};
