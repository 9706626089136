import React, { useEffect, useState } from 'react';
import { MemberManagement } from 'components';
import 'assets/scss/components/PopupManageMember.scss';
import { IMember } from 'types';

interface PopupManageMemberProps {
  members: IMember[];
  onClose: () => void;
  onClickButtonAdd: () => void;
}

export const PopupManageMember: React.FC<PopupManageMemberProps> = ({ members, onClose, onClickButtonAdd }) => {
  const [isOpenSubModal, setIsOpenSubModal] = useState(false);

  const clickOutside = (e: any) => {
    if (e.target.className === 'cd-popup') {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('click', clickOutside);
    return () => {
      document.removeEventListener('click', clickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="cd-popup" role="alert">
      <div className="cd-popup-container">
        <div className="header-wrapper">
          <div className="header-left">
            <div className="text-header">Manage access </div>
          </div>
        </div>
        <div className={`main-wrapper ${isOpenSubModal && '!transform-none'}`}>
          <MemberManagement
            customClassName="pPeopleManage"
            members={members}
            onClickButtonAdd={onClickButtonAdd}
            setIsOpenSubModal={setIsOpenSubModal}
          />
        </div>
      </div>
    </div>
  );
};
