import React from 'react';
import 'assets/scss/components/RepositoryItem.scss';
import { IFile, ITextureFile } from 'types';
import { iconClip, iconArrowRight } from 'assets/images';
import moment from 'moment-timezone';

interface RepositoryFileTextureItemProps {
  fileDataTexture: ITextureFile;
  fileData: IFile;
  depth: number;
}

export const RepositoryFileTextureItem: React.FC<RepositoryFileTextureItemProps> = ({
  fileData,
  fileDataTexture,
  depth,
}) => {
  const itemClasses = [`repo-item`, `file-item`, `item-depth-${depth}`, fileData?.isShow ? '' : 'item-hidden'];
  return (
    <tr className={itemClasses.join(' ')}>
      <td className={`td-checkbox `}>
        <div className={`wrapper-checkbox`}></div>
      </td>

      <td className="col-name">
        <span className="icon-with-name">
          <img src={iconArrowRight} alt="" width={20} height={20} className="icon-shift" />
          <img src={iconClip} alt="" width={20} height={20} className="icon-shift" />
          <span className="item-name">{fileDataTexture.name}</span>
        </span>
      </td>
      <td className="item-msg">{fileData.lastUpdateMessage ?? 'first-commit'}</td>
      <td className="item-update-time">
        {moment.unix(fileData.updatedAt?._seconds ?? fileData.createdAt._seconds).fromNow()}
      </td>
    </tr>
  );
};
