import baseReducer from 'app/redux/baseSlice';
import ticketReducer from 'app/redux/ticketSlice';
import repositoryReducer from 'app/redux/repositorySlice';
import { configureStore } from '@reduxjs/toolkit';
import { initialState as baseInitialState } from 'app/redux/baseSlice';
import { initialState as ticketInitialState } from 'app/redux/ticketSlice';

const rootReducer = {
	ticket: ticketReducer,
	base: baseReducer,
	repository: repositoryReducer
};

const loadState = () => {
	try {
		const user = localStorage.getItem('user');
		if (user === null) {
			return {
				base: baseInitialState,
				ticket: ticketInitialState,
			}
		}
		const userData = JSON.parse(user);
		const base = baseInitialState;
		base.userInfo = userData;
		return {
			base: base,
			ticket: ticketInitialState,
		}
	} catch (err) {
		return {
			base: baseInitialState,
			ticket: ticketInitialState,
		}
	}
}

const store = configureStore({
	reducer: rootReducer,
	preloadedState: loadState(),
});

export default store;
