import React from 'react';
import 'assets/scss/components/RepositoryItem.scss';
import { IDirectory, IFile } from 'types';
import { Checkbox, RepositoryFileItem } from 'components';
import { iconFolderBlack, iconTriagle } from 'assets/images';
import moment from 'moment-timezone';
import { RepositoryFileTextureItem } from 'components/RepositoryFileTextureItem';

interface RepositoryDirectoryItemProps {
  depth: number;
  directoryData: IDirectory;
  activeFile: IFile | IDirectory | null;
  selectedItemContextMenu: IFile | IDirectory | null;
  handleContextMenu: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, item: IDirectory | IFile) => void;
  handleHoverFile: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, item: IFile) => void;
  handleHoverDirectory: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, item: IDirectory) => void;
  handleRowClick: (item: IFile | IDirectory) => void;
  handleClickCheckboxItem: (item: IFile | IDirectory) => void;
  editingItemId: string | null;
  setEditingItemId: React.Dispatch<React.SetStateAction<string | null>>;
  inputRenameRef?: React.RefObject<HTMLInputElement>;
  newName: string;
  setNewName: React.Dispatch<React.SetStateAction<string>>;
  handleRenameThisItem: (item: IFile | IDirectory, newName: string) => void;
  toggleFolder: (folder: IDirectory) => void;
  isViewInModal?: boolean;
  selectedFolderInModal?: IDirectory | null;
  flagHiddenCheckbox?: boolean;
}

export const RepositoryDirectoryItem: React.FC<RepositoryDirectoryItemProps> = ({
  depth,
  directoryData,
  activeFile,
  selectedItemContextMenu,
  handleContextMenu,
  handleHoverFile,
  handleHoverDirectory,
  handleRowClick,
  handleClickCheckboxItem,
  editingItemId,
  setEditingItemId,
  inputRenameRef,
  newName,
  setNewName,
  handleRenameThisItem,
  toggleFolder,
  isViewInModal,
  selectedFolderInModal,
  flagHiddenCheckbox,
}) => {
  let checkShow = isViewInModal ? directoryData.isShowInModal : directoryData.isShow;
  // Hide the item if it is the selected item in the context menu
  if (isViewInModal && selectedItemContextMenu && selectedItemContextMenu.id === directoryData.id) {
    checkShow = false;
  }
  const checkOpen = isViewInModal ? directoryData.isOpenInModal : directoryData.isOpen;

  const itemClasses = [
    `repo-item`,
    `directory-item`,
    `item-depth-${depth}`,
    checkShow ? '' : 'item-hidden',
    isViewInModal && selectedFolderInModal?.id === directoryData.id ? 'active' : '',
  ];

  const handleClickCheckbox = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    handleClickCheckboxItem(directoryData);
  };

  const handleDoubleClick = () => {
    setEditingItemId(directoryData.id);
    setNewName(directoryData.name);
  };

  return (
    <React.Fragment>
      <tr
        onContextMenu={(e) => handleContextMenu(e, directoryData)}
        onMouseEnter={(e) => handleHoverDirectory(e, directoryData)}
        onClick={() => toggleFolder(directoryData)}
        className={itemClasses.join(' ')}
      >
        {!isViewInModal && (
          <td className={`td-checkbox ${flagHiddenCheckbox && 'cursor-default pointer-events-none'}`}>
            <div className={`wrapper-checkbox ${flagHiddenCheckbox && 'hidden'}`} onClick={handleClickCheckbox}>
              <Checkbox key={directoryData.id} isChecked={!!directoryData?.isChecked} />
            </div>
          </td>
        )}

        <td className="col-name">
          <span className="icon-with-name">
            <img
              src={iconTriagle}
              alt="icon-triagle"
              width={20}
              height={20}
              style={{
                marginRight: '5px',
                transform: checkOpen ? 'rotate(120deg)' : 'rotate(-90deg)',
              }}
            />
            <img src={iconFolderBlack} alt="" width={25} height={25} className="icon-shift" />
            {editingItemId === directoryData.id ? (
              <input
                ref={inputRenameRef}
                type="text"
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
                onBlur={() => {
                  handleRenameThisItem(directoryData, newName);
                  setEditingItemId(null);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleRenameThisItem(directoryData, newName);
                    setEditingItemId(null);
                  }
                }}
                autoFocus
              />
            ) : (
              <span className="item-name" onDoubleClick={handleDoubleClick}>
                {directoryData.name}
              </span>
            )}
          </span>
        </td>
        <td className="item-msg">{directoryData.lastUpdateMessage || 'first-commit'}</td>
        <td className="item-update-time">
          {moment.unix(directoryData.updatedAt?._seconds || directoryData.createdAt._seconds).fromNow()}
        </td>
      </tr>
      {!!directoryData.directories.length && (
        <>
          {directoryData.directories.map((directory: IDirectory) => (
            <RepositoryDirectoryItem
              depth={depth + 1}
              key={directory.id}
              directoryData={directory}
              activeFile={activeFile}
              selectedItemContextMenu={selectedItemContextMenu}
              handleContextMenu={handleContextMenu}
              handleHoverFile={handleHoverFile}
              handleHoverDirectory={handleHoverDirectory}
              handleRowClick={handleRowClick}
              handleClickCheckboxItem={handleClickCheckboxItem}
              editingItemId={editingItemId}
              setEditingItemId={setEditingItemId}
              inputRenameRef={inputRenameRef}
              newName={newName}
              setNewName={setNewName}
              handleRenameThisItem={handleRenameThisItem}
              toggleFolder={toggleFolder}
              isViewInModal={isViewInModal}
              selectedFolderInModal={selectedFolderInModal}
              flagHiddenCheckbox={flagHiddenCheckbox}
            />
          ))}
        </>
      )}
      {!!directoryData.files.length && !isViewInModal && (
        <>
          {directoryData.files.map((file: IFile) => (
            <>
              <RepositoryFileItem
                depth={depth + 1}
                key={file.id}
                fileData={file}
                activeFile={activeFile}
                selectedItemContextMenu={selectedItemContextMenu}
                handleContextMenu={handleContextMenu}
                handleHoverFile={handleHoverFile}
                handleRowClick={handleRowClick}
                handleClickCheckboxItem={handleClickCheckboxItem}
                editingItemId={editingItemId}
                setEditingItemId={setEditingItemId}
                inputRenameRef={inputRenameRef}
                newName={newName}
                setNewName={setNewName}
                handleRenameThisItem={handleRenameThisItem}
                flagHiddenCheckbox={flagHiddenCheckbox}
              />
              {file.textureFiles && file.textureFiles.length > 0 && (
                <>
                  {file.textureFiles.map((textureFile) => (
                    <RepositoryFileTextureItem
                      key={textureFile.id}
                      depth={depth + 1}
                      fileDataTexture={textureFile}
                      fileData={file}
                    />
                  ))}
                </>
              )}
            </>
          ))}
        </>
      )}
    </React.Fragment>
  );
};
