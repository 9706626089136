import { Logo } from 'components';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';
import 'assets/scss/layouts/PublicLayout.scss';
import i18n from 'i18next';
import { iconDropDown, iconGlobal } from 'assets/images';
import { isLogin } from 'libs/auth';

export const PublicLayout: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isShowLanguage, setIsShowLanguage] = React.useState<boolean>(false);

  const onClickLogo = () => {
    // check is login or not
    const checkLogin = isLogin();
    if (!checkLogin) {
      // redirect to login page
      navigate('/login');
    } else {
      navigate('/');
    }
  };

  const onClickChangeLanguage = useCallback(() => {
    setIsShowLanguage(!isShowLanguage);
  }, [isShowLanguage]);

  const onChangeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    setIsShowLanguage(false);
  };

  const onClickOutside = (e: any) => {
    if (!e.target.closest('.multi-language')) {
      setIsShowLanguage(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', onClickOutside);
    return () => {
      document.removeEventListener('click', onClickOutside);
    };
  }, []);

  return (
    <>
      <header className="header">
        <div className="header-left">
          <div className="logo">
            <Logo onClick={onClickLogo} />
          </div>
          <div className="title">
            <span className="text-bold-black font-ja font-en-14 font-ja-14">{t('common.projectName')}</span>
          </div>
        </div>
        <div className="header-right">
          <div className="multi-language">
            <div className="actions" onClick={onClickChangeLanguage}>
              <img src={iconGlobal} alt="global" />
              {i18n.language === 'ja' ? (
                <span className="font-ja font-en-14 font-ja-14">{t('common.japanese')}</span>
              ) : (
                <span className="font-en font-en-16 font-ja-16">{t('common.english')}</span>
              )}
              <img src={iconDropDown} alt="dropdown" />
            </div>
            <ul className={`dropdown-menu languages ${isShowLanguage ? 'visible' : ''}`}>
              <li className="language font-en font-en-16 font-ja-16" onClick={() => onChangeLanguage('en')}>
                {t('common.english')}
              </li>
              <li className="language font-ja font-en-14 font-ja-14" onClick={() => onChangeLanguage('ja')}>
                {t('common.japanese')}
              </li>
            </ul>
          </div>
        </div>
      </header>
      <div className="publicLayout">
        <Outlet />
      </div>
    </>
  );
};
