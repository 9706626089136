import React, { useCallback, useEffect, useState } from 'react';
import { iconCheckboxChecked, iconCheckboxUncheck } from 'assets/images';
import { useAlertContext } from 'contexts/AlertContextProvider';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { updateNotificationEmailSetting } from 'api/user';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { IRepository } from 'types';
import { getRepositoryDetail } from 'api/repository';
import { LoadingOverlay } from 'components';

export const EmailNotifications: React.FC = () => {
  const { id } = useParams();
  const { userInfo } = useSelector((state: any) => state.base);
  const { alert } = useAlertContext();
  const { t } = useTranslation();
  const [textInputEmail, setTextInputEmail] = useState<string>('');
  const [textInputApprove, setTextInputApprove] = useState<string>('');
  const [isCheckedNotify, setIsCheckedNotify] = useState<boolean>(true);

  const [errorEmail, setErrorEmail] = useState<string>('');
  const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);

  const isDisabledButton = !textInputEmail.includes('@');

  const {
    data: repositoryDetail,
    isLoading: isLoadingRepositoryDetail,
    refetch: refetchRepositoryDetail,
  } = useQuery<IRepository>(['REPOSITORY_DETAIL', id], () => getRepositoryDetail(id!), {
    enabled: !!id,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    const memberInfo = repositoryDetail?.members.find((member) => member.userId === userInfo?.id);
    setTextInputEmail(memberInfo?.emailNotifications?.emailAddresses || '');
    setTextInputApprove(memberInfo?.emailNotifications?.approvedHeader || '');
    setIsCheckedNotify(memberInfo?.emailNotifications?.isActive || false);
  }, [userInfo, repositoryDetail]);

  const onChangeTextEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (errorEmail !== '') setErrorEmail('');
    setTextInputEmail(e.target.value);
  };

  const handleSave = useCallback(async () => {
    try {
      if (!id) return;

      if (textInputEmail.trim() === '') {
        setErrorEmail(t('common.message.inputRequired'));
        return;
      }
      const params = {
        emailAddresses: textInputEmail,
        approvedHeader: textInputApprove,
        isActive: isCheckedNotify,
      };
      setIsLoadingSave(true);
      const response = await updateNotificationEmailSetting(id, params);
      if ('isError' in response) {
        alert({ type: 'error', content: t('settingsScreen.emailNotification.updateFailed') });
        return;
      }

      refetchRepositoryDetail();
      alert({ type: 'success', content: t('settingsScreen.emailNotification.updateSuccess') });
      setIsLoadingSave(false);
    } catch (error) {
      alert({ type: 'error', content: t('settingsScreen.emailNotification.updateFailed') });
      setIsLoadingSave(false);
    }
  }, [alert, id, isCheckedNotify, refetchRepositoryDetail, t, textInputApprove, textInputEmail]);

  return (
    <div className="eNotify">
      <LoadingOverlay isLoading={isLoadingRepositoryDetail || isLoadingSave} />
      <div className="eNotify__desc">
        <p className="eNotify__text font-en-16 font-ja-12">{t('settingsScreen.emailNotification.description')}</p>
        <p className="eNotify__text font-en-16 font-ja-12">{t('settingsScreen.emailNotification.note')}</p>
      </div>

      <div className="eNotify__form">
        <div className="eNotify__field">
          <span className="font-en-12 font-ja-10">{t('settingsScreen.emailNotification.address')}</span>
          <div>
            <input
              type="text"
              placeholder="one@example.com two@example.com three@example.com"
              value={textInputEmail}
              onChange={onChangeTextEmail}
              maxLength={1000}
              className={errorEmail && 'input-error'}
            />
            <span className="eNotify__textError font-en-14 font-ja-12">{errorEmail}</span>
          </div>
          <p className="eNotify__text font-en-16 font-ja-12">
            {t('settingsScreen.emailNotification.addressFieldNote')}
          </p>
        </div>
        <div className="eNotify__field">
          <span className="font-en-12 font-ja-10">{t('settingsScreen.emailNotification.approvedHeader')}</span>
          <div>
            <input
              type="text"
              maxLength={1000}
              value={textInputApprove}
              onChange={(e) => setTextInputApprove(e.target.value)}
            />
          </div>
          <p className="eNotify__text font-en-16 font-ja-12">
            {t('settingsScreen.emailNotification.approvedHeaderFieldNote')}
          </p>
        </div>
      </div>

      <div className="settingLayout__line"></div>

      <div className="eNotify__foot">
        <div className="eNotify__checkbox">
          <button onClick={() => setIsCheckedNotify((prev) => !prev)}>
            {isCheckedNotify ? (
              <img src={iconCheckboxChecked} width={20} height={20} alt="checked" />
            ) : (
              <img src={iconCheckboxUncheck} width={20} height={20} alt="unchecked" />
            )}
          </button>
          <span className="font-en-16 font-ja-12">{t('settingsScreen.emailNotification.active')}</span>
        </div>
        <p className="eNotify__text font-en-16 font-ja-12">{t('settingsScreen.emailNotification.activeFieldNote')}</p>
      </div>

      <div className="eNotify__submit">
        <button
          className={`common font-en-16 font-ja-12 ${isDisabledButton ? 'disabled' : ''}`}
          disabled={isDisabledButton}
          onClick={handleSave}
        >
          {t('settingsScreen.emailNotification.btnSubmit')}
        </button>
      </div>
    </div>
  );
};
