import { RECENT_MODEL_FILES_NUMBER } from 'constants/Common';
import moment from 'moment-timezone';
import { IDirectory, IFile, IMember, IRepository, IUpdatedBy } from 'types';
import { isMarkdownFile } from './previewFile';

export const isFileType = (item: IFile | IDirectory): item is IFile => {
  return 'directories' in item === false;
};

export const isDirectoryType = (item: IFile | IDirectory): item is IDirectory => {
  return 'directories' in item;
};

export const initRepository = (repository: IRepository, fileActive?: IFile): IRepository => {
  // Initialize top-level files in the repository
  repository?.files?.forEach((file) => {
    file.isShow = true;
  });

  // Initialize top-level directories in the repository
  repository?.directories?.forEach((directory) => {
    directory.isShow = true;
    directory.isShowInModal = true;
  });

  if (fileActive) {
    return sortRepository(openPathInRepository(repository, getPathInRepository(fileActive, repository)));
  }

  return sortRepository(repository);
};

export const addItemToRepository = (userInfo: any, item: IFile | IDirectory, path: string, repository: IRepository): IRepository => {
  // split the path into parts
  const pathParts = path.split('/').filter((part) => part.trim() !== '');
  if (pathParts.length === 0) {
    // add the file to the root level
    if (isDirectoryType(item)) {
      repository.directories.push(item);
    } else {
      repository.files.push(item);
    }
  }

  // find the directory to add the file to
  let currentLevel = repository.directories;
  for (let i = 0; i < pathParts.length; i++) {
    const directoryName = pathParts[i];
    const foundDirectory = currentLevel.find((dir) => dir.name === directoryName);

    if (!foundDirectory) {
      throw new Error(`Directory not found: ${directoryName}`);
    }

    foundDirectory.isShow = true;
    if (i === pathParts.length - 1) {
      // open dir and show all files and directories in the directory
      foundDirectory.isOpen = true;
      foundDirectory.files.forEach((file) => {
        file.isShow = true;
      });
      foundDirectory.directories.forEach((directory) => {
        directory.isShow = true;
      });

      // Add the file to the directory
      if (isDirectoryType(item)) {
        foundDirectory.directories.push(item);
      } else {
        foundDirectory.files.push(item);
      }
    } else {
      // Move to the next level
      currentLevel = foundDirectory.directories;
    }
  }
  const sortedRepository = sortRepository(repository);
  return {
    ...sortedRepository,
    updatedAt: {
      _seconds: moment().unix(),
    },
    updatedBy: userInfo,
  };
};

const updateFileRecursive = (directories: IDirectory[], fileId: string, updateFile: IFile): IDirectory[] => {
  return directories.map((directory) => {
    // Update files in the current directory
    const updatedFiles = directory.files.map((file) => {
      if (file.id === fileId) {
        return { ...file, ...updateFile };
      }
      return file;
    });

    // Recursively update files in subdirectories
    const updatedDirectories = directory.directories
      ? updateFileRecursive(directory.directories, fileId, updateFile)
      : [];

    return { ...directory, files: updatedFiles, directories: updatedDirectories };
  });
};

const updateDirectoryRecursive = (
  directories: IDirectory[],
  directoryId: string,
  updateDirectory: IDirectory,
  newId?: string,
): IDirectory[] => {
  return directories.map((directory) => {
    if (directory.id === directoryId) {
      if (newId) {
        updateDirectory.id = newId;
      }
      return { ...directory, ...updateDirectory };
    }

    // Recursively update directories in subdirectories
    const updatedDirectories = directory.directories
      ? updateDirectoryRecursive(directory.directories, directoryId, updateDirectory)
      : [];

    return { ...directory, directories: updatedDirectories };
  });
};

export const updateFileInRepository = (userInfo: any, repositoryData: IRepository, fileId: string, updateFile: IFile): IRepository => {
  // Update files at the root level
  const updatedFiles = repositoryData.files.map((file) => {
    if (file.id === fileId) {
      return { ...file, ...updateFile };
    }
    return file;
  });

  // Update files in directories
  const updatedDirectories = updateFileRecursive(repositoryData.directories, fileId, updateFile);

  if (userInfo) {
    return {
      ...repositoryData,
      files: updatedFiles,
      directories: updatedDirectories,
      updatedAt: {
        _seconds: moment().unix(),
      },
      updatedBy: userInfo,
    };
  }

  return {
    ...repositoryData,
    files: updatedFiles,
    directories: updatedDirectories,
  };
};

export const updateDirectoryInRepository = (
  userInfo: any,
  repositoryData: IRepository,
  directoryId: string,
  updateDirectory: IDirectory,
  newId?: string,
): IRepository => {
  // Update directories in the root level
  const updatedDirectories = updateDirectoryRecursive(repositoryData.directories, directoryId, updateDirectory, newId);

  if (userInfo) {
    return {
      ...repositoryData,
      directories: updatedDirectories,
      updatedAt: {
        _seconds: moment().unix(),
      },
      updatedBy: userInfo,
    };
  }

  return {
    ...repositoryData,
    directories: updatedDirectories,
  };
};

const findPathRecursive = (
  item: IFile | IDirectory,
  currentDirectory: IDirectory,
  currentPath: string[] = [],
  hasSpace?: boolean, // add space between directories
  isOnlyForDir?: boolean, // with directory, not include this directory name
): string | null => {
  // add the current directory to the path
  const space = hasSpace ? ' / ' : '/';
  currentPath.push(currentDirectory.name);

  // check in files
  for (const file of currentDirectory.files) {
    if (file.id === item.id) {
      return currentPath.join(space);
    }
  }

  // check trong các subdirectories
  for (const directory of currentDirectory.directories) {
    if (directory.id === item.id) {
      if (!isOnlyForDir) {
        currentPath.push(directory.name);
      }
      return currentPath.join(space);
    }

    const path = findPathRecursive(item, directory, [...currentPath], hasSpace, isOnlyForDir);
    if (path) {
      return path;
    }
  }

  return null;
};

export const getPathInRepository = (
  item: IFile | IDirectory,
  repository: IRepository,
  isFull?: boolean, // return with user and repository name
  hasSpace?: boolean, // add space between directories
  isOnlyForDir?: boolean, // with directory, not include this directory name
): string => {
  const space = hasSpace ? ' / ' : '/';
  let pathCurrent = '';
  // check if the item is at the root level
  if (repository?.files) {
    for (const file of repository.files) {
      if (file.id === item.id) {
        pathCurrent = ''; // return the repository name
      }
    }
  }

  // check if the item is inside a directory
  if (repository?.directories) {
    for (const directory of repository.directories) {
      if (directory.id === item.id) {
        if (isOnlyForDir) {
          return '';
        }
        pathCurrent = directory.name;
      }

      const path = findPathRecursive(item, directory, [], hasSpace, isOnlyForDir);
      if (path) {
        pathCurrent = path;
      }
    }
  }

  if (isFull) {
    if (isFileType(item)) {
      pathCurrent = pathCurrent ? `${pathCurrent}${space}${item.name}` : item.name;
    }
    const repositoryOwner = getRepositoryOwner(repository);
    if (repositoryOwner?.user?.name) {
      return `${repositoryOwner?.user?.name}${space}${repository.name}${space}${pathCurrent}`;
    }
    return `${repository.name}${space}${pathCurrent}`;
  }
  return pathCurrent;
};

export const removeItemFromRepository = (userInfo: any, repositoryData: IRepository, item: IFile | IDirectory): IRepository => {
  const removeItemRecursive = (directories: IDirectory[], item: IFile | IDirectory): IDirectory[] => {
    return directories?.reduce<IDirectory[]>((acc, dir) => {
      if (dir.id === item.id) {
        return acc;
      }

      const updatedDir = {
        ...dir,
        files: isFileType(item) ? dir.files.filter((file) => file.id !== item.id) : dir.files,
        directories: removeItemRecursive(dir.directories, item),
      };

      if (isFileType(item)) {
        updatedDir.files = updatedDir.files.filter((file) => file.id !== item.id);
      } else {
        updatedDir.directories = removeItemRecursive(updatedDir.directories, item);
      }

      return [...acc, updatedDir];
    }, []);
  };

  const updatedRepository = {
    ...repositoryData,
    files: isFileType(item) ? repositoryData.files.filter((file) => file.id !== item.id) : repositoryData.files,
    directories: removeItemRecursive(repositoryData.directories, item),
    updatedAt: {
      _seconds: moment().unix(),
    },
    updatedBy: userInfo,
  };

  return updatedRepository;
};

export const countCheckedItems = (repository: IRepository, onlyFile: boolean = false): number => {
  let count = 0;

  const countCheckedFiles = (files: IFile[]): number => {
    return files?.reduce((acc, file) => acc + (file.isChecked ? 1 : 0), 0);
  };

  const countCheckedDirectories = (directories: IDirectory[], onlyFile: boolean): number => {
    return directories?.reduce((acc, directory) => {
      const dirCount = directory.isChecked ? 1 : 0;
      const fileCount = countCheckedFiles(directory.files);
      const subDirCount = countCheckedDirectories(directory.directories, onlyFile);
      if (onlyFile) {
        return acc + fileCount;
      }
      return acc + dirCount + fileCount + subDirCount;
    }, 0);
  };

  count += countCheckedFiles(repository.files);
  count += countCheckedDirectories(repository.directories, onlyFile);

  return count;
};

export const getCheckedItems = (repository: IRepository): IRepository => {
  const getCheckedFiles = (files: IFile[]): IFile[] => {
    return files.filter((file) => file.isChecked);
  };

  const getCheckedDirectories = (directories: IDirectory[]): IDirectory[] => {
    return directories.filter((directory) => {
      directory.files = getCheckedFiles(directory.files);
      directory.directories = getCheckedDirectories(directory.directories);
      return directory.isChecked;
    });
  };

  const updatedRepository = { ...repository };
  updatedRepository.files = getCheckedFiles(repository.files);
  updatedRepository.directories = getCheckedDirectories(repository.directories);

  return updatedRepository;
};

export const removeCheckedItems = (userInfo: any, repository: IRepository, successfulItems: (IFile | IDirectory)[]): IRepository => {
  const isItemSuccessful = (item: IFile | IDirectory): boolean => {
    return successfulItems.some((successfulItem) => successfulItem.id === item.id);
  };

  const removeCheckedFiles = (files: IFile[]): IFile[] => {
    return files.filter((file) => !file.isChecked || !isItemSuccessful(file));
  };

  const removeCheckedDirectories = (directories: IDirectory[]): IDirectory[] => {
    return directories.filter((directory) => {
      directory.files = removeCheckedFiles(directory.files);
      directory.directories = removeCheckedDirectories(directory.directories);
      return !directory.isChecked || !isItemSuccessful(directory);
    });
  };

  const updatedRepository = { ...repository };
  updatedRepository.files = removeCheckedFiles(repository.files);
  updatedRepository.directories = removeCheckedDirectories(repository.directories);
  updatedRepository.updatedAt = {
    _seconds: moment().unix(),
  };
  updatedRepository.updatedBy = userInfo;

  return updatedRepository;
};

const sortByName = (item1: { name: string }, item2: { name: string }): number => {
  return item1?.name?.localeCompare(item2.name);
};

const sortDirectory = (directory: IDirectory): void => {
  directory?.files?.sort(sortByName);
  directory?.directories?.sort(sortByName);
  directory?.directories?.forEach(sortDirectory);
};

export const sortRepository = (repository: IRepository): IRepository => {
  const sortedRepository = { ...repository };

  sortedRepository?.files?.sort(sortByName);
  sortedRepository?.directories?.sort(sortByName);
  sortedRepository?.directories?.forEach(sortDirectory);

  return sortedRepository;
};

export const itemExistsInRepository = (repository: IRepository, itemId: string): boolean => {
  // Helper function to search within directories recursively
  const searchDirectory = (directory: IDirectory, itemId: string): boolean => {
    // Check if the item exists in the current directory's files
    const fileExists =
      directory.files.some((file) => file.id === itemId) || directory.directories.some((dir) => dir.id === itemId);
    if (fileExists) return true;

    // Check recursively in subdirectories
    return directory.directories.some((subDir) => searchDirectory(subDir, itemId));
  };

  // Check if the item exists in the repository's root files
  const fileExistsInRoot =
    repository.files.some((file) => file.id === itemId) || repository.directories.some((dir) => dir.id === itemId);
  if (fileExistsInRoot) return true;

  // Check recursively in the repository's root directories
  return repository.directories.some((dir) => searchDirectory(dir, itemId));
};

export const openPathInRepository = (repository: IRepository, path: string): IRepository => {
  const pathSegments = path.split('/').filter((segment) => segment);
  const openDirectories = (directories: IDirectory[], segments: string[]): IDirectory[] => {
    return directories.map((directory) => {
      if (directory.name === segments[0]) {
        const openedDirectories = directory.directories.map((subDir) => ({ ...subDir, isShow: true }));
        return {
          ...directory,
          isOpen: true,
          isShow: true,
          directories: segments.length > 1 ? openDirectories(openedDirectories, segments.slice(1)) : openedDirectories,
          files: directory.files.map((file) => ({ ...file, isShow: true })),
        };
      }
      return directory;
    });
  };

  return {
    ...repository,
    directories: openDirectories(repository.directories, pathSegments),
  };
};

export const getDefaultNewFolderName = (repository: IRepository, path: string): string => {
  const pathSegments = path.split('/').filter((segment) => segment);

  const findDirectories = (directories: IDirectory[], segments: string[]): IDirectory[] | undefined => {
    if (segments.length === 0) return directories;
    const segment = segments.shift();
    const directory = directories.find((dir) => dir.name === segment);
    if (!directory) return undefined;
    return findDirectories(directory.directories, segments);
  };

  const targetDirectories = findDirectories(repository.directories, pathSegments) || [];

  const baseName = 'New Folder';
  let counter = 1;

  const existingNames = new Set(targetDirectories.map((dir) => dir.name));
  let newName = baseName;
  while (existingNames.has(newName)) {
    newName = `${baseName} (${counter})`;
    counter++;
  }

  return newName;
};

export const findInRepository = (repository?: IRepository, itemId?: string): IFile | IDirectory | null => {
  if (!repository || !itemId) {
    return null;
  }

  for (const file of repository.files) {
    if (file.id === itemId) {
      return file;
    }
  }

  const findInDirectories = (directories: IDirectory[]): IFile | IDirectory | null => {
    for (const directory of directories) {
      if (directory.id === itemId) {
        return directory;
      }

      for (const file of directory.files) {
        if (file.id === itemId) {
          return file;
        }
      }

      const foundInSubDir = findInDirectories(directory.directories);
      if (foundInSubDir) {
        return foundInSubDir;
      }
    }

    return null;
  };

  return findInDirectories(repository.directories);
};

export const getModelFilesFromLocalStorage = (): IFile[] => {
  const recentModelFiles = localStorage.getItem('recentModelFiles');
  if (recentModelFiles) {
    const recentModelFilesArray = JSON.parse(recentModelFiles);
    return recentModelFilesArray;
  }
  return [];
}

export const addOrUpdateModelFileToLocalStorage = (file: IFile, repository: IRepository): void => {
  if (isMarkdownFile(file.url)) {
    return;
  }
  file.repoId = repository.id || '';
  file.repoName = repository.name || '';
  const parentDirectory = getPathInRepository(file, repository);
  file.directoryPath = parentDirectory;
  const recentModelFiles = localStorage.getItem('recentModelFiles');
  let recentModelFilesArray = [];
  if (recentModelFiles) {
    recentModelFilesArray = JSON.parse(recentModelFiles);
  }
  const index = recentModelFilesArray.findIndex((r: IFile) => r.id === file.id);
  if (index !== -1) {
    recentModelFilesArray.splice(index, 1);
  }
  recentModelFilesArray.unshift(file);
  if (recentModelFilesArray.length > RECENT_MODEL_FILES_NUMBER) {
    recentModelFilesArray.pop();
  }
  localStorage.setItem('recentModelFiles', JSON.stringify(recentModelFilesArray));
}

export const removeModelFileFromLocalStorage = (file: IFile): void => {
  const recentModelFiles = localStorage.getItem('recentModelFiles');
  if (recentModelFiles) {
    const recentModelFilesArray = JSON.parse(recentModelFiles);
    const index = recentModelFilesArray.findIndex((r: IFile) => r.id === file.id);
    if (index !== -1) {
      recentModelFilesArray.splice(index, 1);
      localStorage.setItem('recentModelFiles', JSON.stringify(recentModelFilesArray));
    }
  }
}


export const downloadFromUrl = async (url: string, fileName: string) => {
  const response = await fetch(url);
  const blob = await response.blob();
  const urlBlob = window.URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = urlBlob;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  window.URL.revokeObjectURL(urlBlob);
}

export const getRepositoryOwner = (repository?: IRepository): IMember | undefined => {
  return repository?.members?.find((member) => member.userId === repository?.creatorId);
}

export const getRepositoryLastUpdatedBy = (repository?: IRepository): IUpdatedBy | undefined => {
  const repositoryOwner = getRepositoryOwner(repository);
  const repositoryUpdatedBy =
    repository?.members?.find((member) => member.userId === repository?.updatedBy?.id) ||
    repository?.updatedBy;
  let updatedBy = repositoryOwner?.user;
  if (repositoryUpdatedBy && 'user' in repositoryUpdatedBy) {
    updatedBy = repositoryUpdatedBy.user;
  }
  if (repositoryUpdatedBy && 'name' in repositoryUpdatedBy) {
    updatedBy = repositoryUpdatedBy;
  }

  return {
    ...updatedBy,
    updatedAt: repository?.updatedAt,
  } as IUpdatedBy;
}

export const getFileLastUpdatedBy = (file?: IFile): IUpdatedBy | undefined => {
  let updatedBy;

  if (file?.updatedHistory?.length) {
    updatedBy = file.updatedHistory[file.updatedHistory.length - 1].user;
  }

  return {
    ...updatedBy,
    updatedAt: file?.updatedAt,
  } as IUpdatedBy;
}
