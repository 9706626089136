import { IAlert } from 'types';

class AlertService {
  private alertFunction: ((data: IAlert) => void) | null = null;

  public setAlertFunction(fn: (data: IAlert) => void) {
    this.alertFunction = fn;
  }

  public alert(data: IAlert) {
    if (this.alertFunction) {
      this.alertFunction(data);
    }
  }
}

const alertService = new AlertService();
export default alertService;
