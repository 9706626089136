import React, { useEffect, useState } from 'react';
import { Checkbox, ModalAddMember, ModalConfirm } from 'components';
import { iconSearch, iconTrash, iconTriagle } from 'assets/images';
import 'assets/scss/components/MemberManagement.scss';
import { ROLE_LIST } from 'constants/Repository';
import { IMember } from 'types';
import { isEmpty } from 'lodash';
import { IUpdateMemberRolesParams } from 'types/user';
import { updateMemberRoles } from 'api/repository';
import { useParams } from 'react-router-dom';
import { useAlertContext } from 'contexts/AlertContextProvider';
import { useTranslation } from 'react-i18next';

const MAX_INDEX = 9999;

type IMemberSelected = {
  email: string;
  userId: string;
  role: string;
  index: number;
};

interface IProps {
  members: IMember[];
  customClassName?: string;
  onClickButtonAdd?: () => void;
  onAddMemberSucess?: () => void;
  setIsOpenSubModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MemberManagement: React.FC<IProps> = ({
  members,
  customClassName = '',
  onClickButtonAdd,
  onAddMemberSucess = () => {},
  setIsOpenSubModal,
}) => {
  const { id: repositoryId } = useParams();
  const { alert } = useAlertContext();
  const { t } = useTranslation();

  const [peoples, setPeoples] = useState<IMember[]>(members);

  const [isCheckedItems, setIsCheckedItems] = useState<boolean[]>([]);
  const [isCheckedAll, setIsCheckedAll] = useState<boolean>(false);

  const [indexDropdown, setIndexDropdown] = useState<number>(MAX_INDEX);
  const [isShowMenuAction, setIsShowMenuAction] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [keySearch, setKeySearch] = useState<string>('');

  const [memberSelectedChangeRole, setMemberSelectedChangeRole] = useState<IMemberSelected>({} as IMemberSelected);

  const handleCheckedAll = () => {
    setIsCheckedAll(!isCheckedAll);
    setIsCheckedItems((prevState) => prevState.map(() => !isCheckedAll));
  };

  const handleCheckedItem = (index: number) => {
    const newState = [...isCheckedItems];
    newState[index] = !newState[index];
    setIsCheckedItems(newState);
    setIsCheckedAll(newState.every((item) => item));
  };

  const onToggleMenu = () => {};

  const handleClickOutside = (event: MouseEvent) => {
    const element = document.querySelector('.pManage__item--dropdown');
    if (element && !element.contains(event.target as Node)) {
      setIndexDropdown(MAX_INDEX);
    }

    const elementMenuAction = document.querySelector('.pManage__menuAction');
    if (elementMenuAction && !elementMenuAction.contains(event.target as Node)) {
      setIsShowMenuAction(false);
    }
  };

  const handleChooseMemberChangeRole = (event: React.MouseEvent, index: number, role: any) => {
    const member = peoples[index];

    setIsOpenSubModal && setIsOpenSubModal((prev) => !prev);
    setIndexDropdown(MAX_INDEX);
    setMemberSelectedChangeRole({ userId: member.userId, role, email: member.user.email, index });

    event.stopPropagation();
  };

  const handleConfirmChangeRole = async () => {
    if (!repositoryId || isEmpty(memberSelectedChangeRole)) return;
    const { index, role, userId } = memberSelectedChangeRole;
    const newState = [...peoples];
    newState[index] = { ...newState[index], role } as IMember;
    const params: IUpdateMemberRolesParams = {
      members: [{ userId, role }],
    };
    const response = await updateMemberRoles(repositoryId, params);

    setMemberSelectedChangeRole({} as IMemberSelected);
    setIsOpenSubModal && setIsOpenSubModal(false);

    if (!('isError' in response)) {
      onAddMemberSucess();
      setPeoples(newState);
      alert({ type: 'success', content: t('settingsScreen.accessManagement.message.roleChanged') });
    } else if ('isError' in response) {
      alert({ type: 'error', content: t('settingsScreen.accessManagement.message.roleChangeNotAccepted') });
    } else {
      alert({ type: 'error', content: t('settingsScreen.accessManagement.message.roleChangeFailed') });
    }
  };

  const handleActionChangeRole = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // Get Item is checked

    // TO DO: request handle action change role

    event.stopPropagation();
  };

  const handleActionRemove = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // Get Item is checked

    // TO DO: request handle action change role

    event.stopPropagation();
  };

  const openModalAddMember = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (onClickButtonAdd) {
      onClickButtonAdd();
    } else {
      setIsOpenModal(true);
    }
  };

  useEffect(() => {
    setIsCheckedItems(new Array(members.length).fill(false));
  }, [members]);

  useEffect(() => {
    setPeoples(
      members.filter((people) => people.user.email.includes(keySearch) || people.user.name.includes(keySearch)),
    );
  }, [members, keySearch]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className={`pManage ${customClassName}`}>
        <div className="pManage__buttonAdd">
          <button className="common font-en-16 font-ja-12" onClick={openModalAddMember}>
            {t('settingsScreen.accessManagement.btnAddMember')}
          </button>
        </div>
        {isOpenModal && <ModalAddMember onClose={() => setIsOpenModal(false)} onSucess={onAddMemberSucess} />}

        <div className="pManage__search input-wrapper">
          <div className="pManage__checkboxAll">
            <div className="pt-[3px]" onClick={handleCheckedAll}>
              <Checkbox isChecked={isCheckedAll} />
            </div>
            <span className="font-en-16 font-ja-12">{t('settingsScreen.accessManagement.selectAll')}</span>
            <div className="pManage__iconAction" onClick={() => setIsShowMenuAction(true)}>
              <img src={iconTriagle} alt="" width={14} height={14} onClick={onToggleMenu} />
              {isShowMenuAction && (
                <div className="pManage__menuAction">
                  <div className="font-en-16 font-ja-12" onClick={(event) => handleActionChangeRole(event)}>
                    {t('settingsScreen.accessManagement.changeRole')}
                  </div>
                  <div className="font-en-16 font-ja-12" onClick={(event) => handleActionRemove(event)}>
                    {t('settingsScreen.accessManagement.remove')}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="pManage__lineCol line-middle"></div>
          <div className="input-search">
            <div className="icon-search">
              <img src={iconSearch} alt="" width={17} height={20} />
            </div>
            <input
              type="text"
              placeholder="Find people"
              value={keySearch}
              onChange={(event) => setKeySearch(event.target.value)}
            />
          </div>
        </div>
        <div className="pManage__list beauty-scroll scroll-green">
          {peoples.map((people, index) => (
            <div key={people.userId} className="pManage__item">
              <div className="pManage__item--checkbox" onClick={() => handleCheckedItem(index)}>
                <Checkbox isChecked={isCheckedItems[index]} />
              </div>
              <img className="pManage__item--avatar" src={people.user.avatarUrl} alt="people.user.name" />
              <div className="pManage__item--name">
                <a href={`#${people.user.email}`}>{people.user.email}</a>
                <span>{`${people.user.name} | ${ROLE_LIST.find((role) => role.value === people.role)?.label}`}</span>
              </div>

              <div className="pManage__item--role" onClick={() => setIndexDropdown(index)}>
                <span>Role:</span>
                <p>{ROLE_LIST.find((role) => role.value === people.role)?.label}</p>
                <div className="pt-1">
                  <img src={iconTriagle} alt="" width={14} height={14} onClick={onToggleMenu} />
                </div>
                {index === indexDropdown && (
                  <div className="pManage__item--dropdown">
                    {ROLE_LIST.map((role) => (
                      <div
                        key={role.value + people.userId}
                        onClick={(event) => handleChooseMemberChangeRole(event, index, role.value)}
                      >
                        {role.label}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <img src={iconTrash} alt="icon trash" className="cursor-pointer" />
            </div>
          ))}
        </div>
      </div>
      <ModalConfirm
        isVisible={!isEmpty(memberSelectedChangeRole)}
        onCancel={() => {
          setMemberSelectedChangeRole({} as IMemberSelected);
          setIsOpenSubModal && setIsOpenSubModal(false);
        }}
        title={<span className="font-en-18 font-ja-14">{t('settingsScreen.accessManagement.changeRole')}</span>}
        textSubmit={t('common.btnSave')}
        onSubmit={handleConfirmChangeRole}
        classContainer={`left-0 top-0 w-[100vw] h-[100vh]`}
      >
        <p className="pt-2 mb-0 font-en-16 font-ja-12">
          {t('settingsScreen.accessManagement.confirmChangeRole', {
            email: memberSelectedChangeRole.email,
            role: memberSelectedChangeRole.role,
          })}
        </p>
      </ModalConfirm>
    </>
  );
};
