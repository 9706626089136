import React from 'react';
import 'assets/scss/components/Logo.scss';
import { efiLogo } from 'assets/images';

interface LogoProps {
  name?: 'header-logo' | 'login-logo';
  onClick?: () => void;
}

export const Logo: React.FC<LogoProps> = (props: LogoProps) => {
  return (
    <div className={`main-logo ${props.name ?? 'header-logo'}`}>
      <img src={efiLogo} alt="logo" onClick={props.onClick} />
    </div>
  );
};
