import React, { useEffect, useState } from 'react';
import { HomeHeader, SideBar } from 'layouts';
import 'assets/scss/layouts/Repository.scss';
import { iconPuzzle, iconDot } from 'assets/images';
import { useSelector } from 'react-redux';
import { RepositoryInfo, LoadingOverlay } from 'components';
import { useTranslation } from 'react-i18next';

export const Repository: React.FC = () => {
  const { t } = useTranslation();
  const stateBase = useSelector((state: any) => state.base);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(stateBase.isLoading);
  }, [stateBase.isLoading]);

  return (
    <React.Fragment>
      <LoadingOverlay isLoading={isLoading} />
      <div className="layout-header">
        <HomeHeader />
      </div>
      <div className="layout-main">
        <aside className="layout-sidebar">
          <SideBar />
        </aside>
        <main className="layout-content">
          <div className="conainer-wrapper">
            <div className="header-container">
              <div className="header-left">
                <div className="icon-puzzle-piece">
                  <img src={iconPuzzle} alt="" width={40} height={40} />
                </div>
                <div className="text-header font-en-16 font-ja-12">{t('homepage.startYourCraft')}</div>
              </div>
              <div className="header-right">
                <img src={iconDot} alt="" width={24} height={40} />
              </div>
            </div>
            <div className="container-repository">
              <div className="header-container-repository">
                <div className="title-repository font-en-24 font-ja-20">{t('homepage.createNewRepository')}</div>
                <div className="description-repository font-en-16 font-ja-12">
                  {t('homepage.createNewRepositoryDescription')}
                </div>
              </div>
              <div className="border-solid-header"></div>
              <div className="main-repository">
                <div className="fields-required font-en-16 font-ja-12 text-normal-jp">
                  {t('homepage.createNewRepositoryNote')}
                </div>
              </div>
              <RepositoryInfo />
            </div>
          </div>
        </main>
      </div>
    </React.Fragment>
  );
};
