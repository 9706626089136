import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'opLodash';

export const initialState = {
  dataViewer: [],
  dataViewerMax: {
    id: null,
    totalTime: 0,
    currentTime: 0,
  },
  timeInput: null,
  isPlay: false,
  cameraPosition: null,
  cameraRotation: null,
  cameraZoom: null,
  cameraTarget: null,
};

const ticketSlice = createSlice({
  name: 'ticket',
  initialState,
  reducers: {
    setDataViewer(state, { payload }) {
      const { id } = payload;
      const newData = cloneDeep(payload);
      if (state.dataViewer.length > 0) {
        const isDuplicate = state.dataViewer.some((item) => item.id === id);
        if (!isDuplicate) {
          state.dataViewer.push(newData);
        }
      } else {
        state.dataViewer.push(newData);
      }
    },
    setDataViewerMax(state, { payload }) {
      const newData = cloneDeep(payload);
      state.dataViewerMax = newData;
    },
    setCurrentTime(state, { payload }) {
      const { url, currentTime, totalTime } = payload;
      state.dataViewer.forEach((item, index) => {
        if (item.id === url) {
          state.dataViewer[index].currentTime = currentTime;
          state.dataViewer[index].totalTime = totalTime;
        }
      });
    },
    setChangeTimeInput(state, { payload }) {
      state.timeInput = payload;
    },
    setIsPlay(state, { payload }) {
      state.isPlay = payload;
    },
    setCameraPosition(state, { payload }) {
      state.cameraPosition = payload;
    },
    setCameraTarget(state, { payload }) {
			state.cameraTarget = payload;
		},
    setCameraRotation(state, { payload }) {
      state.cameraRotation = payload;
    },
    setCameraZoom(state, action) {
      state.cameraZoom = action.payload;
    },
  },
});

const { actions, reducer } = ticketSlice;
export const {
  setDataViewer,
  setDataViewerMax,
  setCurrentTime,
  setChangeTimeInput,
  setIsPlay,
  setCameraPosition,
  setCameraRotation,
  setCameraZoom,
  setCameraTarget,
} = actions;
export default reducer;
