import React, { useEffect, useState } from 'react';
import { MemberManagement, LoadingOverlay } from 'components';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { IRepository } from 'types';
import { getRepositoryDetail } from 'api/repository';

export const AccessManagement: React.FC = () => {
  const { id } = useParams();
  const stateBase = useSelector((state: any) => state.base);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(stateBase.isLoading);
  }, [stateBase.isLoading]);

  const {
    data: repositoryDetail,
    isLoading: isLoadingRepositoryDetail,
    refetch: refetchRepositoryDetail,
  } = useQuery<IRepository>(['REPOSITORY_DETAIL', id], () => getRepositoryDetail(id!), {
    enabled: !!id,
    refetchOnWindowFocus: false,
  });

  return (
    <div>
      <LoadingOverlay isLoading={isLoading || isLoadingRepositoryDetail || stateBase.isLoading} />
      <MemberManagement members={repositoryDetail?.members || []} onAddMemberSucess={refetchRepositoryDetail} />
    </div>
  );
};
