import React from 'react';
import 'assets/scss/components/RepositoryItem.scss';
import { IDirectory, IFile } from 'types';
import { Checkbox } from 'components';
import { iconAlert, iconFileGreen } from 'assets/images';
import moment from 'moment-timezone';

interface RepositoryFileItemProps {
  depth: number;
  fileData: IFile;
  activeFile: IFile | IDirectory | null;
  selectedItemContextMenu: IFile | IDirectory | null;
  handleContextMenu: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, item: IDirectory | IFile) => void;
  handleHoverFile: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, item: IFile) => void;
  handleRowClick: (item: IFile | IDirectory) => void;
  handleClickCheckboxItem: (item: IFile | IDirectory) => void;
  editingItemId: string | null;
  setEditingItemId: React.Dispatch<React.SetStateAction<string | null>>;
  inputRenameRef?: React.RefObject<HTMLInputElement>;
  newName: string;
  setNewName: React.Dispatch<React.SetStateAction<string>>;
  handleRenameThisItem: (item: IFile | IDirectory, newName: string) => void;
  flagHiddenCheckbox?: boolean;
}

export const RepositoryFileItem: React.FC<RepositoryFileItemProps> = ({
  depth,
  fileData,
  activeFile,
  selectedItemContextMenu,
  handleContextMenu,
  handleHoverFile,
  handleRowClick,
  handleClickCheckboxItem,
  editingItemId,
  setEditingItemId,
  inputRenameRef,
  newName,
  setNewName,
  handleRenameThisItem,
  flagHiddenCheckbox,
}) => {
  const itemClasses = [
    `repo-item`,
    `file-item`,
    `item-depth-${depth}`,
    fileData?.isShow ? '' : 'item-hidden',
    selectedItemContextMenu?.id === fileData.id ? 'selected' : '',
    activeFile?.id === fileData.id ? 'active' : '',
  ];

  const handleClickCheckbox = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    handleClickCheckboxItem(fileData);
  };

  const handleDoubleClick = () => {
    setEditingItemId(fileData.id);
    setNewName(fileData.name);
  };

  return (
    <tr
      onContextMenu={(e) => handleContextMenu(e, fileData)}
      onMouseEnter={(e) => handleHoverFile(e, fileData)}
      onClick={() => handleRowClick(fileData)}
      className={itemClasses.join(' ')}
    >
      <td className={`td-checkbox ${flagHiddenCheckbox && 'cursor-default pointer-events-none'}`}>
        <div className={`wrapper-checkbox ${flagHiddenCheckbox && 'hidden'}`} onClick={handleClickCheckbox}>
          <Checkbox key={fileData.id} isChecked={!!fileData.isChecked} />
        </div>
      </td>

      <td className="col-name">
        <span className="icon-with-name">
          <img src={iconFileGreen} alt="" width={25} height={25} className="icon-shift" />

          {editingItemId === fileData.id ? (
            <input
              ref={inputRenameRef}
              type="text"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              onBlur={() => {
                handleRenameThisItem(fileData, newName);
                setEditingItemId(null);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleRenameThisItem(fileData, newName);
                  setEditingItemId(null);
                }
              }}
              autoFocus
            />
          ) : (
            <span className="item-name" onDoubleClick={handleDoubleClick}>
              {fileData.name}
            </span>
          )}
          {!!fileData.upcomingFile && Object.keys(fileData.upcomingFile).length > 0 && (
            <img
              src={iconAlert}
              alt="icon-alert"
              width={30}
              height={30}
              style={{
                marginLeft: '15px',
                marginTop: '5px',
              }}
            />
          )}
        </span>
      </td>
      <td className="item-msg">{fileData.lastUpdateMessage ?? 'first-commit'}</td>
      <td className="item-update-time">
        {moment.unix(fileData.updatedAt?._seconds ?? fileData.createdAt._seconds).fromNow()}
      </td>
    </tr>
  );
};
