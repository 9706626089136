import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RepositoryList } from 'components';
import 'assets/scss/components/EnhanceHomePage.scss';
import { useTranslation } from 'react-i18next';

export const EnhanceHomePage: React.FC<any> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const repositoryState = useSelector((state: any) => state.repository);

  return (
    <div className="enhancePage">
      <div className="enhancePage__title">
        <h3 className="font-en-24 font-ja-20">{t('homepage.enhanceYourMyPage.title')}</h3>
        <p className="enhancePage__desc font-en-16 font-ja-12 ja:!leading-[17.38px]">
          {t('homepage.enhanceYourMyPage.description')}
        </p>
      </div>

      <div className="enhancePage__line"></div>

      <div className="enhancePage__step">
        <div className="enhancePage__step--item font-en-16 font-ja-12">
          <h5 className="font-en-16 font-ja-12">{t('homepage.enhanceYourMyPage.step1')}</h5>
          <p className="enhancePage__desc font-en-16 font-ja-12">{t('homepage.enhanceYourMyPage.step1Description')}</p>
          <div className="enhancePage__button">
            <button className="font-en-16 font-ja-12" onClick={() => navigate('/my-page')}>
              {t('homepage.enhanceYourMyPage.btnGoToMyPage')}
            </button>
          </div>
        </div>
        <div className="enhancePage__step--item">
          <h5 className="font-en-16 font-ja-12">{t('homepage.enhanceYourMyPage.step2')}</h5>
          <p className="enhancePage__desc font-en-16 font-ja-12 ja:!leading-[17.38px]">
            {t('homepage.enhanceYourMyPage.step2Description')}
          </p>
        </div>
      </div>

      <div className="enhancePage__repo">
        <h4 className="font-en-18 font-ja-14">{t('homepage.enhanceYourMyPage.repositories')}</h4>
        <div className="enhancePage__repo--body beauty-scroll scroll-green">
          <RepositoryList list={repositoryState?.list || []} />
        </div>
      </div>
    </div>
  );
};
