import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	isLoading: false,
	userInfo: {},
};

const baseSlice = createSlice({
	name: 'base',
	initialState,
	reducers: {
		setIsLoading(state, { payload }) {
			state.isLoading = payload;
		},
		setUserInfo(state, { payload }) {
			state.userInfo = { ...payload };
		},
		resetUserInfo(state) {
			state.userInfo = {};
		},
	},
});

const { actions, reducer } = baseSlice;
export const { setIsLoading, setUserInfo, resetUserInfo } = actions;
export default reducer;
