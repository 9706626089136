import React from 'react';
import { useNavigate } from 'react-router-dom';
import 'assets/scss/components/RepositoryList.scss';
import { useTranslation } from 'react-i18next';

interface RepositoryListProps {
  list: any[];
}

export const RepositoryList: React.FC<RepositoryListProps> = ({ list }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onNavigateRepository = (repo: any) => {
    navigate(`/repository/${repo.id}/directory`);
  };

  return (
    <div className="repoList">
      <div className="repoList__list">
        {list.length > 0 ? (
          list.map((repo) => (
            <div key={repo.id} className="repoList__item" onClick={() => onNavigateRepository(repo)}>
              <img src={repo?.creator?.avatarUrl} alt="avatar" className="avatar" />
              <p className="font-ja font-en-12 font-ja-12">
                {repo?.creator?.name}/{repo.name?.slice(0, 50)}
                {repo.name.length > 50 && '...'}
              </p>
            </div>
          ))
        ) : (
          <p className="repoList__text font-en-16 font-ja-12">{t('sidebar.noResult')}</p>
        )}
      </div>
    </div>
  );
};
