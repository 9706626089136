import { fetchUser } from 'api/user';
import { setIsLoading } from 'app/redux/baseSlice';
import store from 'app/store';
import { getAuth, signOut } from 'firebase/auth';
import { isObject } from 'opLodash';
import alertService from 'services/alertService';
import superagent from 'superagent';
import i18n from 'i18next';

// env variables
export const API_ROOT = process.env.REACT_APP_BACKEND_URL;

let accessToken: string | null;

export const parseAccess = () => {
  try {
    accessToken = localStorage.getItem('token');
  } catch (error) {
    accessToken = null;
  }
};
parseAccess();

export const getUserInfo = () => {
  try {
    const userStore = localStorage.getItem('user') ?? '';
    const user = JSON.parse(userStore);
    return user;
  } catch (error) {
    return {};
  }
};

export const tokenPlugin = async (req: any) => {
  parseAccess();
  if (accessToken) {
    req.set('Authorization', `Bearer ${accessToken}`);
  }
};

export const getNewToken = async (err: any) => {
  try {
    if (!err || err.status !== 401) {
      return false;
    }
    alertService.alert({ type: 'info', content: i18n.t('common.message.sessionExpiredWaitingRefersh') });
    store.dispatch(setIsLoading(true));
    const auth = getAuth();
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const user = auth.currentUser;
    if (!user) {
      throw new Error(i18n.t('common.message.userUnauthorized'));
    }
    const idToken = await user.getIdToken(true);
    if (idToken) {
      localStorage.setItem('token', idToken);
      await fetchUser();
      window.location.reload();
      return true;
    }
    throw new Error(i18n.t('common.message.failedToRefreshToken'));
  } catch (error: any) {
    alertService.alert({ type: 'info', content: i18n.t('common.message.sessionExpired') });
    const auth = getAuth();
    await signOut(auth);
    clearToken();
    window.location.href = '/login';
    return false;
  }
};

const responseBody = (res: any) => (res.body ? res.body : res.text);

export const clearToken = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('token');
};

export const catchError = (e: any) => {
  let error = e.response;
  if (!isObject(error)) {
    error = e.response?.body || e?.response || {};
  }
  error.status = e.status;
  throw error;
};

const requests = {
  del: (url: string, body: any) =>
    superagent.del(url, body).retry(1, getNewToken).use(tokenPlugin).then(responseBody).catch(catchError),
  get: (url: string, body: any) =>
    superagent.get(url, body).retry(1, getNewToken).use(tokenPlugin).then(responseBody).catch(catchError),
  post: (url: string, body: any) =>
    superagent.post(url, body).retry(1, getNewToken).use(tokenPlugin).then(responseBody).catch(catchError),
  put: (url: string, body: any) =>
    superagent.put(url, body).retry(1, getNewToken).use(tokenPlugin).then(responseBody).catch(catchError),
};

class Base {
  apiGet = (url: string, query = {}) => requests.get(`${API_ROOT}${url}`, this.normalizeQuery(query));

  apiPut = (url: string, body: any) => requests.put(`${API_ROOT}${url}`, body);

  apiPost = (url: string, body: any) => requests.post(`${API_ROOT}${url}`, body);

  apiDelete = (url: string, body: any) => requests.del(`${API_ROOT}${url}`, body);

  normalizeQuery = (query: any) =>
    Object.entries(query).reduce<Record<string, any>>((acc, [key, value]) => {
      if (value !== null) {
        if (typeof value === 'string') {
          acc[key] = value.trim();
        } else if (!Number.isNaN(value)) {
          acc[key] = value;
        }
      }
      return acc;
    }, {});

  apiUpload = (
    url: string,
    body: FormData,
    ratioProgress: number = 1,
    currentProgress: number = 0,
    onProgress?: (progress: number) => void,
    onSuccess?: (response: string) => void,
    onError?: (error: string) => void,
    retry: boolean = true,
  ) => {
    const xhr = new XMLHttpRequest();

    xhr.open('POST', `${API_ROOT}${url}`, true);

    if (accessToken) {
      xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`);
    }

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentComplete = (event.loaded / event.total) * 100 * ratioProgress + currentProgress;
        if (onProgress) onProgress(percentComplete);
      }
    };

    xhr.onload = async () => {
      if (xhr.status === 200 || xhr.status === 201) {
        if (onSuccess) onSuccess(xhr.response);
      } else if (xhr.status === 401 && retry) {
        const tokenRefreshed = await getNewToken({ status: 401 });
        if (tokenRefreshed) {
          this.apiUpload(url, body, ratioProgress, currentProgress, onProgress, onSuccess, onError, false); // retry = false
        } else if (onError) {
          onError(xhr.response);
        }
      } else {
        if (onError) onError(xhr.response);
      }
    };

    xhr.onerror = () => {
      if (onError) onError(xhr.statusText);
    };

    xhr.send(body);
  };
}

export default Base;
