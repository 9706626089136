import React from 'react';
import 'assets/scss/layouts/HomeHeader.scss';
import { RightHeader } from './RightHeader';
import { useNavigate } from 'react-router-dom';
import { Logo, MenuDirectory } from 'components';
import { IRepository } from 'types';
import { getRepositoryOwner } from 'libs/repository';

interface DirectoryHeaderProps {
  repository?: IRepository;
  selectedItem: number | null;
}

export const DirectoryHeader: React.FC<DirectoryHeaderProps> = ({ repository, selectedItem }) => {
  const navigate = useNavigate();

  const repositoryOwner = getRepositoryOwner(repository);

  const handleGoToHome = () => {
    navigate('/');
  };

  return (
    <div className="header-directory">
      <header className="header header-directory">
        <div className="header-left">
          <div className="logo">
            <Logo onClick={handleGoToHome} />
          </div>
          <div className="title title-directory">
            <span className="text-regular-black font-ja font-en-14 font-ja-14">
              {repositoryOwner?.user?.name} / <strong>{repository?.name}</strong>
            </span>
          </div>
        </div>
        <RightHeader />
      </header>
      <MenuDirectory selectedItem={selectedItem} repositoryId={repository?.id || ''} />
    </div>
  );
};
