import React from 'react';
import 'assets/scss/components/MoveItemToFolderModal.scss';
import { ModalConfirm, RepositoryDirectoryItem } from 'components';
import { IDirectory, IFile } from 'types';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

interface MoveItemToFolderModalProps {
  isVisibleMoveItem: boolean;
  setIsVisibleMoveItem: (value: boolean) => void;
  itemName: string;
  filteredFolders: IDirectory[];
  toggleFolder: (folder: IDirectory) => void;
  handleMoveItem: () => void;
  selectedFolderInModal: IDirectory | null;
  selectedItemContextMenu: IFile | IDirectory | null;
}

export const MoveItemToFolderModal: React.FC<MoveItemToFolderModalProps> = ({
  isVisibleMoveItem,
  setIsVisibleMoveItem,
  itemName,
  filteredFolders,
  toggleFolder,
  handleMoveItem,
  selectedFolderInModal,
  selectedItemContextMenu,
}) => {
  const { t } = useTranslation();

  const title =
    i18n.language === 'ja' ? (
      <div>
        <span className="font-ja font-en-14 font-ja-14">“{itemName}”</span>
        <span className="font-en-18 font-ja-14">{t('moveItemToFolderModal.title')}</span>
      </div>
    ) : (
      <div>
        <span className="font-en-18 font-ja-14">{t('moveItemToFolderModal.title')}</span>
        <span className="font-ja font-en-14 font-ja-14">“{itemName}”</span>
      </div>
    );

  return (
    <ModalConfirm
      setVisible={setIsVisibleMoveItem}
      isVisible={isVisibleMoveItem}
      width="686px"
      title={title}
      content={<span className="font-en-16 font-ja-12">{t('moveItemToFolderModal.content')}</span>}
      textSubmit={t('moveItemToFolderModal.btnMove')}
      onSubmit={() => handleMoveItem()}
      classContainer="move-item-to-folder-modal"
    >
      <div className="table-folder-structure">
        <table>
          <tbody>
            {filteredFolders.map((item: IDirectory) => (
              <RepositoryDirectoryItem
                depth={0}
                key={item.id}
                directoryData={item}
                activeFile={null}
                selectedItemContextMenu={selectedItemContextMenu}
                toggleFolder={toggleFolder}
                handleRowClick={() => {}}
                handleContextMenu={() => {}}
                handleHoverFile={() => {}}
                handleHoverDirectory={() => {}}
                handleClickCheckboxItem={() => {}}
                editingItemId={null}
                setEditingItemId={() => {}}
                newName={''}
                setNewName={() => {}}
                handleRenameThisItem={() => {}}
                isViewInModal={true}
                selectedFolderInModal={selectedFolderInModal}
              />
            ))}
          </tbody>
        </table>
      </div>
    </ModalConfirm>
  );
};
