import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { useForm } from 'react-hook-form';
import '../../assets/scss/components/UserInfoEdit.scss';
import { iconWorkPosition, iconAddress, iconClock, iconLink, iconCamera } from 'assets/images';
import { useAlertContext } from 'contexts/AlertContextProvider';
import { fetchUser, updateUserProfile } from 'api/user';
import { useTranslation } from 'react-i18next';

type FormType = {
  image: File | null;
  name: string;
  nameId: string;
  description: string;
  position: string;
  address: string;
  time: string;
  url: string;
};

export const UserInfoEdit: React.FC = () => {
  const userState = useSelector((state: any) => state.base);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { alert } = useAlertContext();

  const user = {
    image: userState?.userInfo?.avatarUrl,
    name: userState?.userInfo?.name,
    nameId: userState?.userInfo?.email,
    description: userState?.userInfo?.description,
    isOwner: true,
    position: userState?.userInfo?.work,
    address: userState?.userInfo?.address,
    timezone: 'Asia/Tokyo',
    url: userState?.userInfo?.link,
  };

  const {
    register,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm<FormType>({
    defaultValues: {
      name: user.name,
      description: user.description,
      position: user.position,
      address: user.address,
      url: user.url,
    },
  });

  const [imgSrc, setImgSrc] = useState<string>(user.image);

  const handleChooseFile = () => {
    document.getElementById('imageInput')?.click();
  };

  const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      setValue('image', file);
      clearErrors('image');
      const reader = new FileReader();
      reader.onloadend = () => {
        setImgSrc(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSaveChanges = async (data: any) => {
    try {
      const dataUpdate = new FormData();
      dataUpdate.append('file', data.image);
      dataUpdate.append('name', data.name);
      dataUpdate.append('description', data.description);
      dataUpdate.append('work', data.position);
      dataUpdate.append('address', data.address);
      dataUpdate.append('link', data.url);

      const response = await updateUserProfile(dataUpdate);
      if ('isError' in response) {
        alert({ type: 'error', content: t('profileScreen.message.updateProfileFailed') });
        return;
      }
      await fetchUser();
      alert({ type: 'success', content: t('profileScreen.message.updateProfileSuccess') });
      navigate('/my-page');
    } catch (error: any) {
      alert({ type: 'error', content: t('profileScreen.message.updateProfileFailed') });
    }
  };

  return (
    <form onSubmit={handleSubmit(handleSaveChanges)}>
      <div className="userInfoEdit">
        <div className="userInfoEdit__head">
          <div className="userInfoEdit__head--avatar">
            <img src={imgSrc} alt="Avatar" className="image-avatar" />
            <img src={iconCamera} alt="icon" className="icon-camera" onClick={handleChooseFile} />
            <input
              id="imageInput"
              accept=".png, .jpg, .jpeg"
              type="file"
              className="!hidden font-ja"
              {...register('image')}
              onChange={onChangeFile}
            />
          </div>
          <div className="userInfoEdit__head--info">
            <input
              type="text"
              {...register('name', { required: true })}
              className={`common mb-1 font-bold text-[20px] font-ja ${errors.name ? 'input-error' : ''}`}
            />
            {errors.name && <p className="text-error">{t('profileScreen.fieldRequired')}</p>}
            <span className="user-email">{user.nameId}</span>
          </div>
          {user.isOwner && <button className="common font-en-16 font-ja-12">{t('common.btnSave')}</button>}
        </div>
        <div className="userInfoEdit__body">
          <div className="userInfoEdit__body--description">
            <p className="label">bio</p>
            <textarea {...register('description')} className="common beauty-scroll font-ja"></textarea>
          </div>
          <div className="userInfoEdit__body--content">
            <div className="userInfoEdit__body--item">
              <img src={iconWorkPosition} alt="icon" />
              <input type="text" {...register('position')} className="common font-ja" />
            </div>
            <div className="userInfoEdit__body--item">
              <img src={iconAddress} alt="icon" />
              <input type="text" {...register('address')} className="common font-ja" />
            </div>
            <div className="userInfoEdit__body--item">
              <img src={iconClock} alt="icon" />
              <span>{moment().format('YYYY/MM/DD HH:mmA')}</span>
            </div>
            <div className="userInfoEdit__body--item">
              <img src={iconLink} alt="icon" />
              <input type="text" {...register('url')} className="common font-ja" />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
