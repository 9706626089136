import React, { useCallback, useState, useRef, useEffect } from 'react';
import { ModalConfirm } from 'components';
import 'assets/scss/components/UpdateFileModal.scss';
import { IDirectory, IFile } from 'types';
import { SUPPORTED_FILE_TYPE, SUPPORTED_FILE_TEXTURE_TYPE, SUPPORTED_MODEL_FILE_TYPE } from 'constants/Repository';
import { useAlertContext } from 'contexts/AlertContextProvider';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

interface UpdateFileModalProps {
  isVisibleUpdateFile: boolean;
  setIsVisibleUpdateFile: (value: boolean) => void;
  selectedItemContextMenu: IFile | IDirectory | null;
  handleUpdateThisItem: (file: File, commitMessage: string, textures: File[]) => void;
}

export const UpdateFileModal: React.FC<UpdateFileModalProps> = ({
  isVisibleUpdateFile,
  setIsVisibleUpdateFile,
  selectedItemContextMenu,
  handleUpdateThisItem,
}) => {
  const { t } = useTranslation();
  const { alert } = useAlertContext();
  const inputFileRef = useRef<HTMLInputElement>(null);
  const inputFileTextureRef = useRef<HTMLInputElement>(null);

  const [selectedUploadFile, setSelectedUploadFile] = useState<File | null>(null);
  const [fileTexture, setFileTexture] = useState<File[]>([]);
  const [isDraggingFileUpdate, setIsDraggingFileUpdate] = useState(false);
  const [isDraggingFileTexture, setIsDraggingFileTexture] = useState(false);
  const [lastCommitMessage, setLastCommitMessage] = useState<string>('');

  const handleClickSelectFile = useCallback(() => {
    inputFileRef.current?.click();
  }, []);

  const handleClickSelectFileTexture = useCallback(() => {
    inputFileTextureRef.current?.click();
  }, []);

  const handleConfirmUpload = () => {
    if (!selectedUploadFile) {
      alert({ type: 'warning', content: t('uploadFileModal.message.modelFileLimit') });
      return;
    }

    if (!lastCommitMessage) {
      alert({ type: 'error', content: t('updateFileModal.message.updateCommentRequired') });
      return;
    }

    handleUpdateThisItem(selectedUploadFile, lastCommitMessage, fileTexture);
  };

  const clearFiles = () => {
    setSelectedUploadFile(null);
    setFileTexture([]);
    setLastCommitMessage('');
  };

  const onCancel = () => {
    clearFiles();
    setIsVisibleUpdateFile(false);
  };

  // Drag-and-Drop Handlers for Model File
  const handleDragOverUpload = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDraggingFileUpdate(true);
  };

  const handleDropUpload = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDraggingFileUpdate(false);
    const files = event.dataTransfer.files;
    if (files.length > 1) {
      alert({ type: 'warning', content: t('uploadFileModal.message.modelFileLimit') });
    } else {
      if (files && files[0]) {
        const file = files[0];
        const name = file.name;
        const type = name.split('.').pop()?.toLowerCase() || '';
        if (SUPPORTED_FILE_TYPE.includes('.' + type)) {
          setSelectedUploadFile(file);
        } else {
          alert({ type: 'warning', content: t('uploadFileModal.message.fileTypeNotSupported') });
        }
      }
    }
  };

  const handleDragLeaveUpload = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDraggingFileUpdate(false);
  };

  // Drag-and-Drop Handlers for Texture Files
  const handleDragOverTexture = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDraggingFileTexture(true);
  };

  const handleDropTexture = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDraggingFileTexture(false);
    const files = Array.from(event.dataTransfer.files);
    const invalidFiles = files.filter(
      (file) => !SUPPORTED_FILE_TEXTURE_TYPE.includes(`.${file.name.split('.').pop()?.toLowerCase()}`),
    );
    if (invalidFiles.length) {
      alert({ type: 'warning', content: t('uploadFileModal.message.fileTypeNotSupported') });
    }
    const validFiles = files.filter((file) =>
      SUPPORTED_FILE_TEXTURE_TYPE.includes(`.${file.name.split('.').pop()?.toLowerCase()}`),
    );
    setFileTexture((prevFiles) => (prevFiles ? [...prevFiles, ...validFiles] : validFiles));
  };

  const handleDragLeaveTexture = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDraggingFileTexture(false);
  };

  useEffect(() => {
    clearFiles();
  }, [isVisibleUpdateFile]);

  const title =
    i18n.language === 'ja' ? (
      <div>
        <span className="font-ja font-en-14 font-ja-14">“{selectedItemContextMenu?.name}”</span>
        <span className="font-en-18 font-ja-14">{t('updateFileModal.title')}</span>
      </div>
    ) : (
      <div>
        <span className="font-en-18 font-ja-14">{t('updateFileModal.title')}</span>
        <span className="font-ja font-en-14 font-ja-14">“{selectedItemContextMenu?.name}”</span>
      </div>
    );

  return (
    <ModalConfirm
      setVisible={setIsVisibleUpdateFile}
      isVisible={isVisibleUpdateFile}
      width="627px"
      title={title}
      content={<span className="font-en-16 font-ja-12">{t('updateFileModal.description')}</span>}
      textSubmit={t('common.btnConfirm')}
      onSubmit={handleConfirmUpload}
      onCancel={onCancel}
      classContainer="update-file-modal"
    >
      <div className="update-comment-title font-en-16 font-ja-12">{t('updateFileModal.updateComment')}</div>
      <div className="input-wrapper">
        <div className="input-search">
          <input type="text" value={lastCommitMessage} onChange={(e) => setLastCommitMessage(e.target.value)} />
        </div>
      </div>
      <div className="upload-fn-title mt-4">
        <span className="font-en-16 font-ja-12">{t('uploadFileModal.descriptionSelectModelFile')}</span>
        <span className="font-en font-en-16 font-ja-16">({SUPPORTED_MODEL_FILE_TYPE.join(', ')})</span>
      </div>
      <div className="function-update-file">
        <div
          className="select-file-wrapper"
          onDragOver={handleDragOverUpload}
          onDrop={handleDropUpload}
          onDragLeave={handleDragLeaveUpload}
        >
          <input
            ref={inputFileRef}
            type="file"
            className="file-select-update"
            accept={SUPPORTED_MODEL_FILE_TYPE.join(',')}
            onChange={(e) => {
              if (e.target.files && e.target.files.length > 0) {
                const file = e.target.files[0];
                const name = file.name;
                const type = name.split('.').pop()?.toLowerCase() || '';
                if (SUPPORTED_MODEL_FILE_TYPE.includes('.' + type)) {
                  setSelectedUploadFile(file);
                } else {
                  alert({ type: 'warning', content: t('uploadFileModal.message.fileTypeNotSupported') });
                }
              }
            }}
            onClick={(e) => e.stopPropagation()}
          />
          <button className="custom-file-label font-en-16 font-ja-12" onClick={handleClickSelectFile}>
            {t('uploadFileModal.btnSelectFile')}
          </button>
          {selectedUploadFile && <span className="file-name">{selectedUploadFile.name}</span>}
        </div>
        <div
          className={`drag-drop-file-wrapper ${isDraggingFileUpdate ? 'dragging' : ''}`}
          onDragOver={handleDragOverUpload}
          onDrop={handleDropUpload}
          onDragLeave={handleDragLeaveUpload}
        >
          <div className="main-text font-en-16 font-ja-12">{t('uploadFileModal.dragAndDrop')}</div>
        </div>
      </div>
      <div className="upload-fn-title mt-4">
        <span className="font-en-16 font-ja-12">{t('uploadFileModal.descriptionSelectTexturesFile')}</span>
        <span className="font-en font-en-16 font-ja-16">({SUPPORTED_FILE_TEXTURE_TYPE.join(', ')})</span>
      </div>
      <div className="function-update-file">
        <div
          className="select-file-wrapper"
          onDragOver={handleDragOverTexture}
          onDrop={handleDropTexture}
          onDragLeave={handleDragLeaveTexture}
        >
          <input
            ref={inputFileTextureRef}
            type="file"
            className="file-select-update"
            accept={SUPPORTED_FILE_TEXTURE_TYPE.join(',')}
            multiple
            onChange={(e) => {
              const files = Array.from(e.target.files || []);
              const invalidFiles = files.filter(
                (file) => !SUPPORTED_FILE_TEXTURE_TYPE.includes(`.${file.name.split('.').pop()?.toLowerCase()}`),
              );
              if (invalidFiles.length) {
                alert({ type: 'warning', content: t('uploadFileModal.message.fileTypeNotSupported') });
              }
              const validFiles = files.filter((file) =>
                SUPPORTED_FILE_TEXTURE_TYPE.includes(`.${file.name.split('.').pop()?.toLowerCase()}`),
              );
              setFileTexture((prevFiles) => (prevFiles ? [...prevFiles, ...validFiles] : validFiles));
            }}
            onClick={(e) => e.stopPropagation()}
          />
          <button className="custom-file-label font-en-16 font-ja-12" onClick={handleClickSelectFileTexture}>
            {t('uploadFileModal.btnSelectFiles')}
          </button>
          {fileTexture && fileTexture.length > 0 && (
            <span className="file-name">
              {fileTexture.map((file, index) => (
                <span key={index} className="file-name-item">
                  {file.name}
                </span>
              ))}
            </span>
          )}
        </div>
        <div
          className={`drag-drop-file-wrapper ${isDraggingFileTexture ? 'dragging' : ''}`}
          onDragOver={handleDragOverTexture}
          onDrop={handleDropTexture}
          onDragLeave={handleDragLeaveTexture}
        >
          <div className="main-text font-en-16 font-ja-12">{t('uploadFileModal.dragAndDrop')}</div>
        </div>
      </div>
    </ModalConfirm>
  );
};
