import React, { useCallback, useEffect } from 'react';
import 'assets/scss/components/CardRepository.scss';
import { useNavigate } from 'react-router-dom';
import { IErrorResponse, IFile, IRepository } from 'types';
import { PreviewFile, PreviewInfoFile } from 'components';
import { useQuery } from 'react-query';
import { getRepositoryDetail, getRepositoryFile } from 'api/repository';
import {
  getPathInRepository,
  initRepository,
  itemExistsInRepository,
  removeModelFileFromLocalStorage,
} from 'libs/repository';

interface CardRepositoryProps {
  file: IFile;
}

export const CardRepository: React.FC<CardRepositoryProps> = ({ file }) => {
  const NUMBER_OF_CHARACTERS = 15;
  const navigate = useNavigate();
  const [error, setError] = React.useState<boolean>(false);
  const [parentDirectory, setParentDirectory] = React.useState<string>('');

  const { data: repositoryDetail, isLoading: isLoadingRepositoryDetail } = useQuery<IRepository>(
    ['REPOSITORY_DETAIL', file.repoId],
    () => getRepositoryDetail(file.repoId!),
    {
      enabled: !!file.repoId,
      refetchOnWindowFocus: false,
    },
  );

  const { data: fileData, isLoading: isLoadingFileData } = useQuery<IFile | IErrorResponse>(
    ['REPOSITORY_FILE_DETAIL', file.repoId, file.directoryPath, file.name],
    () =>
      getRepositoryFile(file.repoId!, {
        path: file.directoryPath || '',
        fileId: file.id,
      }),
    {
      enabled: !!file.repoId && !!file.name,
      refetchOnWindowFocus: false,
    },
  );

  const handleRedicret = useCallback(() => {
    if (!fileData || 'isError' in fileData || !repositoryDetail) return;
    navigate(`/repository/${repositoryDetail.id}/directory`, {
      state: {
        fileActive: fileData,
      },
    });
  }, [fileData, navigate, repositoryDetail]);

  useEffect(() => {
    if (fileData && !isLoadingFileData) {
      if ('isError' in fileData && fileData.isError) {
        setError(true);
        removeModelFileFromLocalStorage(file);
      }
    }

    if (repositoryDetail && !isLoadingRepositoryDetail && fileData && !isLoadingFileData && !('isError' in fileData)) {
      const initRepositoryData = initRepository(repositoryDetail);
      // check exist file in repository
      const checkExistFile = itemExistsInRepository(initRepositoryData, fileData.id);
      if (!checkExistFile) {
        setError(true);
        removeModelFileFromLocalStorage(file);
      } else {
        const path = getPathInRepository(fileData, initRepositoryData);
        setParentDirectory(path.split('/').pop() || '');
      }
    }
  }, [fileData, isLoadingFileData, isLoadingRepositoryDetail, repositoryDetail, file]);

  if (error) return null;

  return fileData && repositoryDetail && !('isError' in fileData) ? (
    <div className="cardRepo">
      <div className="cardRepo__head" onClick={handleRedicret}>
        <span className="font-en-18 font-ja-14">{repositoryDetail.name}</span>
        {parentDirectory ? (
          <span className="cardRepo__head--directory font-en-16 font-ja-12">
            {fileData.name.length > NUMBER_OF_CHARACTERS || repositoryDetail.name.length > NUMBER_OF_CHARACTERS
              ? `>...> ${fileData.name}`
              : `>...> ${parentDirectory} > ${fileData.name || fileData.id}`}
          </span>
        ) : (
          <span className="cardRepo__head--directory font-en-16 font-ja-12">{`> ${fileData.name || fileData.id}`}</span>
        )}
      </div>
      <div className="cardRepo__body">
        <div>
          <div className="cardRepo__body--content">
            <PreviewFile
              containerClassName="preview-file-card"
              url={fileData.url}
              textureFiles={fileData.textureFiles}
              pathFull={''}
            />
            <PreviewInfoFile containerClassName="preview-file-info-card" file={fileData} onClick={handleRedicret} />
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
