import React from 'react';
import { useTranslation } from 'react-i18next';

const page = 'terms.';
export const Terms: React.FC = () => {
  const { t } = useTranslation();

  const trans = (key: string) => {
    return t(page + key);
  };
  return (
    <>
      <h1>{trans('title')}</h1>
      <span className="font-ja">{trans('date')}</span>
      <div className="line"></div>
      <p className="en:!mb-3">{trans('desc.1')}</p>
      <p className="en:!mb-3">{trans('desc.2')}</p>
      <p>{trans('desc.3')}</p>

      <h3>{trans('subTitle.1')}</h3>

      <p className="font-bold margin-b-10">{trans('1.title')}</p>
      <p>{trans('1.1')}</p>
      <p className="margin-b-10">{trans('1.2')}</p>

      <p className="font-bold margin-b-10">{trans('2.title')}</p>
      <p>{trans('2.desc')}</p>
      <p>{trans('2.1')}</p>
      <p dangerouslySetInnerHTML={{ __html: trans('2.2') }}></p>
      <p>{trans('2.3')}</p>
      <p>{trans('2.4')}</p>
      <p>{trans('2.5')}</p>
      <p>{trans('2.6')}</p>
      <p dangerouslySetInnerHTML={{ __html: trans('2.7') }}></p>
      <p>{trans('2.8')}</p>
      <p>{trans('2.9')}</p>
      <p>{trans('2.10')}</p>
      <p>{trans('2.11')}</p>
      <p>{trans('2.12')}</p>
      <p>{trans('2.13')}</p>
      <p>{trans('2.14')}</p>
      <p>{trans('2.15')}</p>
      <p>{trans('2.16')}</p>
      <p>{trans('2.17')}</p>
      <p>{trans('2.18')}</p>
      <p>{trans('2.19')}</p>
      <p>{trans('2.20')}</p>
      <p>{trans('2.21')}</p>
      <p>{trans('2.22')}</p>
      <p>{trans('2.23')}</p>
      <p>{trans('2.24')}</p>
      <p>{trans('2.25')}</p>
      <p>{trans('2.26.title')}</p>
      <p className="margin-l-10">{trans('2.26.a')}</p>
      <p className="margin-l-10">{trans('2.26.b')}</p>
      <p className="margin-l-10">{trans('2.26.c')}</p>
      <p className="margin-l-10">{trans('2.26.d')}</p>
      <p className="margin-l-10">{trans('2.26.e')}</p>
      <p className="margin-l-10">{trans('2.26.f')}</p>
      <p className="margin-l-10">{trans('2.26.g')}</p>
      <p className="margin-l-10">{trans('2.26.h')}</p>
      <p className="margin-l-10">{trans('2.26.i')}</p>
      <p className="margin-l-10">{trans('2.26.j')}</p>
      <p className="margin-l-10 margin-b-10">{trans('2.26.k')}</p>

      <h3>{trans('subTitle.2')}</h3>

      <p className="font-bold margin-b-10">{trans('3.title')}</p>
      <p>{trans('3.1')}</p>
      <p>{trans('3.2.title')}</p>
      <p className="margin-l-10">{trans('3.2.a')}</p>
      <p className="margin-l-10">{trans('3.2.b')}</p>
      <p className="margin-l-10">{trans('3.2.c')}</p>
      <p className="margin-l-10">{trans('3.2.d')}</p>
      <p className="margin-l-10">{trans('3.2.e')}</p>
      <p className="margin-l-10">{trans('3.2.f')}</p>
      <p className="margin-l-10">{trans('3.2.g')}</p>
      <p className="margin-l-10">{trans('3.2.h')}</p>
      <p className="margin-l-10">{trans('3.2.i')}</p>
      <p className="margin-l-10">{trans('3.2.j')}</p>
      <p>{trans('3.3')}</p>
      <p className="margin-b-10">{trans('3.4')}</p>

      <p className="font-bold margin-b-10">{trans('4.title')}</p>
      <p>{trans('4.1')}</p>
      <p>{trans('4.2')}</p>
      <p>{trans('4.3')}</p>
      <p className="margin-b-10">{trans('4.4')}</p>

      <h3>{trans('subTitle.3')}</h3>

      <p className="font-bold margin-b-10">{trans('5.title')}</p>
      <p>{trans('5.1')}</p>
      <p>{trans('5.2')}</p>
      <p className="margin-b-10">{trans('5.3')}</p>

      <p className="font-bold margin-b-10">{trans('6.title')}</p>
      <p>{trans('6.1')}</p>
      <p className="margin-b-10">{trans('6.2')}</p>

      <p className="font-bold margin-b-10">{trans('7.title')}</p>
      <p>{trans('7.desc')}</p>
      <p>{trans('7.1')}</p>
      <p>{trans('7.2')}</p>
      <p>{trans('7.3')}</p>
      <p>{trans('7.4')}</p>
      <p>{trans('7.5')}</p>
      <p>{trans('7.6')}</p>
      <p>{trans('7.7')}</p>
      <p>{trans('7.8')}</p>
      <p>{trans('7.9')}</p>
      <p>{trans('7.10')}</p>
      <p>{trans('7.10_2')}</p>
      <p>{trans('7.11.title')}</p>
      <p className="margin-l-10">{trans('7.11.a')}</p>
      <p className="margin-l-10">{trans('7.11.b')}</p>
      <p className="margin-l-10">{trans('7.11.c')}</p>
      <p className="margin-l-10">{trans('7.11.d')}</p>
      <p className="margin-l-10">{trans('7.11.e')}</p>
      <p className="margin-l-10">{trans('7.11.f')}</p>
      <p className="margin-l-10">{trans('7.11.g')}</p>
      <p className="margin-l-10">{trans('7.11.h')}</p>
      <p className="margin-l-10">{trans('7.11.i')}</p>
      <p className="margin-l-10">{trans('7.11.j')}</p>
      <p className="margin-l-10">{trans('7.11.k')}</p>
      <p className="margin-l-10">{trans('7.11.l')}</p>
      <p className="margin-l-10">{trans('7.11.m')}</p>
      <p>{trans('7.12')}</p>
      <p>{trans('7.13')}</p>
      <p>{trans('7.14')}</p>
      <p>{trans('7.15')}</p>
      <p>{trans('7.16')}</p>
      <p>{trans('7.17')}</p>
      <p>{trans('7.18')}</p>
      <p className="margin-b-10">{trans('7.19')}</p>

      <h3>{trans('subTitle.4')}</h3>

      <p className="font-bold margin-b-10">{trans('8.title')}</p>
      <p>{trans('8.1.title')}</p>
      <p className="margin-l-10">{trans('8.1.a')}</p>
      <p className="margin-l-10">{trans('8.1.b')}</p>
      <p className="margin-l-10">{trans('8.1.c')}</p>
      <p className="margin-l-10">{trans('8.1.d')}</p>
      <p className="margin-l-10">{trans('8.1.e')}</p>
      <p className="margin-l-10">{trans('8.1.f')}</p>
      <p className="margin-l-10">{trans('8.1.g')}</p>
      <p>{trans('8.2')}</p>
      <p>{trans('8.3')}</p>
      <p className="margin-b-10">{trans('8.4')}</p>

      <p className="font-bold margin-b-10">{trans('9.title')}</p>
      <p>{trans('9.1.title')}</p>
      <p className="margin-l-10">{trans('9.1.a')}</p>
      <p className="margin-l-10">{trans('9.1.b')}</p>
      <p className="margin-l-10">{trans('9.1.c')}</p>
      <p className="margin-l-10">{trans('9.1.d')}</p>
      <p className="margin-l-10">{trans('9.1.e')}</p>
      <p className="margin-l-10">{trans('9.1.f')}</p>
      <p className="margin-l-10">{trans('9.1.g')}</p>
      <p className="margin-l-10">{trans('9.1.h')}</p>
      <p className="margin-l-10">{trans('9.1.i')}</p>
      <p className="margin-b-10">{trans('9.2')}</p>

      <p className="font-bold margin-b-10">{trans('10.title')}</p>
      <p>{trans('10.1')}</p>
      <p className="margin-b-10">{trans('10.2')}</p>

      <p className="font-bold margin-b-10">{trans('11.title')}</p>
      <p className="margin-b-10">{trans('11.desc')}</p>

      <p className="font-bold margin-b-10">{trans('12.title')}</p>
      <p>{trans('12.1')}</p>
      <p>{trans('12.2')}</p>
      <p>{trans('12.3')}</p>
      <p>{trans('12.4')}</p>
      <p>{trans('12.5')}</p>
      <p className="margin-b-10">{trans('12.6')}</p>

      <p className="font-bold margin-b-10">{trans('13.title')}</p>
      <p>{trans('13.1')}</p>
      <p className="margin-b-10">{trans('13.2')}</p>

      <p className="font-bold margin-b-10">{trans('14.title')}</p>
      <p>{trans('14.1')}</p>
      <p className="margin-b-10">{trans('14.2')}</p>

      <p className="font-bold margin-b-10">{trans('15.title')}</p>
      <p>{trans('15.1.title')}</p>
      <p className="margin-l-10">{trans('15.1.a')}</p>
      <p className="margin-l-10">{trans('15.1.b')}</p>
      <p className="margin-l-10">{trans('15.1.c')}</p>
      <p className="margin-l-10">{trans('15.1.d')}</p>
      <p>{trans('15.2')}</p>
      <p>{trans('15.3')}</p>
      <p>{trans('15.4')}</p>
      <p>{trans('15.5')}</p>
      <p>{trans('15.6.title')}</p>
      <p className="margin-l-10">{trans('15.6.a')}</p>
      <p className="margin-l-10">{trans('15.6.b')}</p>
      <p className="margin-l-10">{trans('15.6.c')}</p>
      <p className="margin-l-10">{trans('15.6.d')}</p>
      <p className="margin-l-10">{trans('15.6.e')}</p>
      <p className="margin-l-10">{trans('15.6.f')}</p>
      <p className="margin-l-10">{trans('15.6.g')}</p>
      <p className="margin-l-10">{trans('15.6.h')}</p>
      <p className="margin-l-10">{trans('15.6.i')}</p>
      <p className="margin-l-10">{trans('15.6.j')}</p>
      <p className="margin-l-10">{trans('15.6.k')}</p>
      <p className="margin-l-10">{trans('15.6.l')}</p>
      <p className="margin-l-10 margin-b-10">{trans('15.6.m')}</p>

      <h3>{trans('subTitle.5')}</h3>

      <p className="font-bold margin-b-10">{trans('16.title')}</p>
      <p>{trans('16.1')}</p>
      <p className="margin-b-10">{trans('16.2')}</p>

      <p className="font-bold margin-b-10">{trans('17.title')}</p>
      <p>{trans('17.1')}</p>
      <p>{trans('17.2.title')}</p>
      <p className="margin-l-10">{trans('17.2.a')}</p>
      <p className="margin-l-10">{trans('17.2.b')}</p>
      <p className="margin-l-10">{trans('17.2.c')}</p>
      <p className="margin-l-10">{trans('17.2.d')}</p>
      <p className="margin-l-10">{trans('17.2.e')}</p>
      <p>{trans('17.3')}</p>
      <p className="margin-b-10">{trans('17.4')}</p>

      <p className="font-bold margin-b-10">{trans('18.title')}</p>
      <p>{trans('18.1')}</p>
      <p>{trans('18.2')}</p>
      <p className="margin-b-10">{trans('18.3')}</p>

      <p className="font-bold margin-b-10">{trans('19.title')}</p>
      <p>{trans('19.1.title')}</p>
      <p className="margin-l-10">{trans('19.1.a')}</p>
      <p className="margin-l-10">{trans('19.1.b')}</p>
      <p>{trans('19.2')}</p>
      <p>{trans('19.3')}</p>
      <p className="margin-b-10">{trans('19.4')}</p>

      <p className="font-bold margin-b-10">{trans('20.title')}</p>
      <p>{trans('20.1')}</p>
      <p className="margin-b-10">{trans('20.2')}</p>

      <p className="font-bold margin-b-10">{trans('21.title')}</p>
      <p className="margin-b-10">{trans('21.desc')}</p>

      <p className="font-bold margin-b-10">{trans('22.title')}</p>
      <p className="margin-b-10">{trans('22.desc')}</p>

      <p className="font-bold margin-b-10">{trans('23.title')}</p>
      <p className="margin-b-10">{trans('23.desc')}</p>

      <p className="font-bold margin-b-10">{trans('24.title')}</p>
      <p>{trans('24.1')}</p>
      <p className="margin-b-10">{trans('24.2')}</p>
    </>
  );
};
