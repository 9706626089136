import React, { createContext, useContext, useState, useEffect } from 'react';
import { AlertCustom } from 'components';
import alertService from 'services/alertService';
import { IAlert } from 'types';

const AlertContext = createContext<{ alert: (data: IAlert) => void } | undefined>(undefined);

export const useAlertContext = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error('useAlertContext must be used within an AlertProvider');
  }
  return context;
};

export const AlertContextProvider = ({ children }: React.PropsWithChildren) => {
  const [alertState, setAlertState] = useState<IAlert>({} as IAlert);

  useEffect(() => {
    alertService.setAlertFunction((data: IAlert) => {
      setAlertState(data);
      setTimeout(() => setAlertState({} as IAlert), 3000);
    });
  }, []);

  return (
    <AlertContext.Provider value={{ alert: alertService.alert.bind(alertService) }}>
      {Object.keys(alertState).length > 0 && <AlertCustom {...alertState} />}
      {children}
    </AlertContext.Provider>
  );
};
