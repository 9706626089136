import React, { useEffect } from 'react';
import 'assets/scss/components/DragAndDropFile.scss';
import { addFolderIcon } from 'assets/images';
import { useTranslation } from 'react-i18next';

interface DragAndDropFileProps {
  onFileDrop: (files: FileList | null) => void;
  isDraggingFile: boolean;
  setIsDraggingFile: (isDraggingFile: boolean) => void;
}

export const DragAndDropFile: React.FC<DragAndDropFileProps> = ({ onFileDrop, isDraggingFile, setIsDraggingFile }) => {
  const { t } = useTranslation();

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDraggingFile(false);
    const files = event.dataTransfer.files;
    onFileDrop(files);
  };

  useEffect(() => {
    document.addEventListener('dragstart', (event) => {
      event.preventDefault();
    });
    return () => {
      document.removeEventListener('dragstart', (event) => {
        event.preventDefault();
      });
    };
  }, []);

  return (
    <div className={`drag-drop-wrapper ${isDraggingFile ? 'dragging' : ''}`} onDrop={handleDrop}>
      <img src={addFolderIcon} width={86} height={86} alt="add-folder" />
      <h6 className="font-en-18 font-jp-14">{t('directoryScreen.emptyRepository.dropFile')}</h6>
      <p>
        <div className="font-en-12 font-jp-10">{t('directoryScreen.emptyRepository.dropFileDescription1')}</div>
        <div className="font-en-12 font-jp-10">{t('directoryScreen.emptyRepository.dropFileDescription2')}</div>
      </p>
    </div>
  );
};
