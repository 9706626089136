import wrapIcon from './wrapIcon';

type IconProps = {
  onMouseColor?: string;
  onChangeColor?: string;
  width?: string;
  height?: string;
  isActive?: boolean;
};

function Icon({
  onMouseColor,
  onChangeColor,
  width,
  height,
  isActive,
  ...props
}: Readonly<IconProps>) {
  return (
    <svg
      width={width ?? '18'}
      height={height ?? '18'}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.000156403 5.5L6.06233 13L12.1245 5.5H9.06233V0H3.06233L3.06233 5.5H0.000156403Z"
        fill={isActive ? onMouseColor : onChangeColor || 'black'}
      />
    </svg>
  );
}
const ArrowDownIcon = wrapIcon(Icon);
export default ArrowDownIcon;
