import React, { useCallback, useEffect, useRef, useState } from 'react';
import 'assets/scss/layouts/Directory.scss';
import { DirectoryHeader } from 'layouts';
import {
  DragAndDropFile,
  PreviewFile,
  ModalConfirm,
  RepositoryHeader,
  MoveItemToFolderModal,
  UpdateFileModal,
  RepositoryDirectoryItem,
  RepositoryFileItem,
  RightClickContextMenu,
  LoadingOverlay,
  PreviewInfoFile,
  HoverItemMenu,
  CreateVersionModal,
} from 'components';
import {
  iconShuffle,
  iconTriagle,
  iconCheckboxChecked,
  iconCheckboxUncheckAll,
  moveFileIcon,
  updateFileIcon,
  renameItemIcon,
  removeItemIcon,
  addFileIcon,
  addFolderIcon,
  iconPeopleGray,
  iconAddFolderGray,
  iconUploadGray,
  iconXClose,
  iconEyeWhite,
  addFileIconBlue,
  addFolderIconBlue,
  updateFileIconBlue,
  renameItemIconBlue,
  moveFileIconBlue,
  removeItemIconBlue,
} from 'assets/images';
import {
  addRepositoryDirectory,
  addRepositoryFile,
  createRepositoryVersion,
  downloadRepositoryFiles,
  getRepositoryDetail,
  moveRepositoryDirectory,
  moveRepositoryFile,
  removeRepositoryDirectory,
  removeRepositoryFile,
  removeRepositoryVersion,
  renameRepositoryDirectory,
  renameRepositoryFile,
  updateRepositoryFile,
  updateRepositoryTutorialsStatus,
} from 'api/repository';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  IContextMenuItem,
  IContextMenuPosition,
  ICreateFileResponse,
  ICreateRemoveDirectoryData,
  ICreateVersionData,
  IDirectory,
  IDownloadFileData,
  IErrorResponse,
  IFile,
  IHoverMenuItem,
  IHoverMenuPosition,
  IMember,
  IMoveDirectoryData,
  IMoveFileData,
  IRemoveFileData,
  IRemoveItemRepositoryResult,
  IRepository,
  IRepositoryVersion,
  ITutorialStatus,
  IUpdatedBy,
} from 'types';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import moment from 'moment-timezone';
import {
  addItemToRepository,
  getPathInRepository,
  updateDirectoryInRepository,
  isFileType,
  updateFileInRepository,
  initRepository,
  removeItemFromRepository,
  countCheckedItems,
  removeCheckedItems,
  sortRepository,
  openPathInRepository,
  getCheckedItems,
  getDefaultNewFolderName,
  addOrUpdateModelFileToLocalStorage,
  removeModelFileFromLocalStorage,
  downloadFromUrl,
  getRepositoryOwner,
  getRepositoryLastUpdatedBy,
} from 'libs/repository';
import { useWindowSize } from 'hooks/useWindowSize';
import {
  RIGHT_CLICK_CONTEXT_MENU_HEIGHT,
  RIGHT_CLICK_CONTEXT_MENU_WIDTH,
  SUPPORTED_FILE_TYPE,
} from 'constants/Repository';
import { useAlertContext } from 'contexts/AlertContextProvider';
import { isEmpty } from 'lodash';
import { UploadFileModal, RepositoryFileTextureItem } from 'components';
import { isModelFile } from 'libs/previewFile';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { MAX_SIZE_UPLOAD_FILE } from 'constants/Common';

export const Directory: React.FC = () => {
  const { id } = useParams();
  const location = useLocation();
  const { width, height } = useWindowSize();
  const { alert } = useAlertContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const stateBase = useSelector((state: any) => state.base);
  const stateData = location.state;

  const [contextMenuPosition, setContextMenuPosition] = useState<IContextMenuPosition | null>(null);
  const [contextMenuItems, setContextMenuItems] = useState<IContextMenuItem[]>([]);
  const [selectedItemContextMenu, setSelectedItemContextMenu] = useState<IFile | IDirectory | null>(null);
  const [hoverMenuPosition, setHoverMenuPosition] = useState<IHoverMenuPosition | null>(null);
  const [hoverMenuItems, setHoverMenuItems] = useState<IHoverMenuItem[]>([]);

  const dropdownVersionRef = useRef<HTMLDivElement>(null);
  const inputRenameRef = useRef<HTMLInputElement | null>(null);
  const menuActionFileRef = useRef<HTMLDivElement | null>(null);
  const [isDraggingFileRepository, setIsDraggingFileRepository] = useState(false);
  const [activeFile, setActiveFile] = useState<IFile | IDirectory | null>(null);
  const [isOpenDropdownVersion, setIsOpenDropdownVersion] = useState(false);
  const [repositoryData, setRepositoryData] = useState<IRepository>();
  const [versions, setVersions] = useState<IRepositoryVersion[]>([]);
  const [latestVersion, setLatestVersion] = useState<IRepositoryVersion>();
  const [versionSelected, setVersionSelected] = useState<IRepositoryVersion>();
  const [versionInput, setVersionInput] = useState<string>('');

  const [editingItemId, setEditingItemId] = useState<string | null>(null);
  const [newName, setNewName] = useState<string>('');
  const [isCreateFolder, setIsCreateFolder] = useState<boolean>(false);

  const [menuActionFile, setMenuActionFile] = useState(false);
  const [isPopupAction, setIsPopupAction] = useState(false);
  const [actionCheckbox, setActionCheckbox] = useState<'delete' | 'download' | ''>('');
  const [isVisibleSaveVersion, setIsVisibleSaveVersion] = useState<boolean>(false);
  const [isVisibleRemoveFile, setIsVisibleRemoveFile] = useState<boolean>(false);
  const [isVisibleMoveItem, setIsVisibleMoveItem] = useState<boolean>(false);
  const [selectedFolderInModal, setSelectedFolderInModal] = useState<IDirectory | null>(null);

  const [isVisibleUpdateFile, setIsVisibleUpdateFile] = useState<boolean>(false);
  const [isVisibleUploadFile, setIsVisibleUploadFile] = useState<boolean>(false);
  const [newItemSelect, setNewItemSelect] = useState<IFile | IDirectory | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [progress, setProgress] = useState<number | null>(null);

  // State display/hidden instruct for new repository
  const [isShowShareYourTeam, setIsShowShareYourTeam] = useState<boolean>(true);
  const [isShowCreateDirectory, setIsShowCreateDirectory] = useState<boolean>(true);
  const [isShowUploadFiles, setIsShowUploadFiles] = useState<boolean>(true);

  const [flagViewOldVersion, setFlagViewOldVersion] = useState<boolean>(false);
  const [versionDelete, setVersionDelete] = useState<IRepositoryVersion>({} as IRepositoryVersion);

  const [isEmptyRepository, setIsEmptyRepository] = useState<boolean>(false);
  const [isCompletedTutorial, setIsCompletedTutorial] = useState<boolean>(false);
  const [repositoryOwner, setRepositoryOwner] = useState<IMember | undefined>();
  const [repositoryLastUpdatedBy, setRepositoryLastUpdatedBy] = useState<IUpdatedBy | undefined>();

  const {
    data: repositoryDetail,
    isLoading: isLoadingRepositoryDetail,
    refetch: refetchRepositoryDetail,
  } = useQuery<IRepository>(['REPOSITORY_DETAIL', id], () => getRepositoryDetail(id!), {
    enabled: !!id,
    refetchOnWindowFocus: false,
  });

  const initRepositoryDetail = useCallback(async () => {
    if (!repositoryDetail) return;
    // set isShow of files and directories is true
    const initRepositoryData = initRepository(repositoryDetail, stateData?.fileActive);
    setRepositoryData(initRepositoryData);
  }, [repositoryDetail, stateData?.fileActive]);

  /* Start Click/Toggle Item Functions */
  const toggleFolder = useCallback(
    (folder: IDirectory) => {
      if (!repositoryData) return;
      const toggleSubFolders = (dir: IDirectory, isOpen: boolean) => {
        dir.directories.forEach((subDir) => {
          subDir.isShow = isOpen;
          subDir.isOpen = isOpen;
          if (!isOpen) {
            toggleSubFolders(subDir, isOpen);
          }
        });
        dir.files.forEach((file) => {
          file.isShow = isOpen;
        });
      };
      const folderUpdate = { ...folder, isOpen: !folder.isOpen };
      if (folderUpdate.isOpen) {
        folderUpdate.directories.forEach((directory) => {
          directory.isShow = true;
          directory.isOpen = false;
        });
        folderUpdate.files.forEach((file) => {
          file.isShow = true;
        });
      } else {
        toggleSubFolders(folderUpdate, false);
      }
      const updatedRepositoryData = updateDirectoryInRepository(null, repositoryData, folder.id, folderUpdate);
      setRepositoryData(updatedRepositoryData);
    },
    [repositoryData],
  );

  const handleSelectFolderInModal = (folder: IDirectory) => {
    setSelectedFolderInModal((prevSelectedFolder) => {
      if (prevSelectedFolder?.id === folder.id) {
        return null;
      }
      return folder;
    });
  };

  const toggleFolderInModal = useCallback(
    (folder: IDirectory) => {
      if (!repositoryData) return;
      handleSelectFolderInModal(folder);
      const toggleSubFolders = (dir: IDirectory, isOpenInModal: boolean) => {
        dir.directories.forEach((subDir) => {
          subDir.isShowInModal = isOpenInModal;
          subDir.isOpenInModal = isOpenInModal;
          if (!isOpenInModal) {
            toggleSubFolders(subDir, isOpenInModal);
          }
        });
      };
      const folderUpdate = { ...folder, isOpenInModal: !folder.isOpenInModal };
      if (folderUpdate.isOpenInModal) {
        folderUpdate.directories.forEach((directory) => {
          directory.isShowInModal = true;
          directory.isOpenInModal = false;
        });
      } else {
        toggleSubFolders(folderUpdate, false);
      }
      const updatedRepositoryData = updateDirectoryInRepository(null, repositoryData, folder.id, folderUpdate);
      setRepositoryData(updatedRepositoryData);
    },
    [repositoryData],
  );

  const handleRowClick = (item: IFile | IDirectory) => {
    if (isFileType(item) && repositoryData) {
      addOrUpdateModelFileToLocalStorage(item, repositoryData);
    }
    setActiveFile(item);
  };

  const handleClickRenameItem = (item: IFile | IDirectory) => {
    setEditingItemId(item.id);
    setNewName(item.name);
  };
  /* End Click/Toggle Item Functions */

  /* Start Right Click/ Hover Item Functions */
  const handleAddNewFile = useCallback(
    async (file: File, item: IDirectory | IFile | null, textures: File[] = []) => {
      if (file && repositoryData) {
        let currentPath = '';
        if (item) {
          currentPath = getPathInRepository(item, repositoryData);
        }

        // check file size
        if (file.size > MAX_SIZE_UPLOAD_FILE) {
          alert({ type: 'error', content: t('directoryScreen.message.updateFileMaxSize') });
          return;
        }

        // check each texture file size
        for (const element of textures) {
          if (element.size > MAX_SIZE_UPLOAD_FILE) {
            alert({ type: 'error', content: t('directoryScreen.message.updateFileMaxSize') });
            return;
          }
        }

        const formData = new FormData();
        formData.append('file', file);
        // formData.append('name', file.name);
        formData.append('path', currentPath);
        formData.append('metaData[mesh]', 'mesh');
        formData.append('metaData[side]', 'side');
        formData.append('metaData[surface]', 'surface');

        textures.forEach((texture, index) => {
          formData.append('textureFiles', texture);
          formData.append('textureFileMetaData[' + index + '][mesh]', 'mesh');
          formData.append('textureFileMetaData[' + index + '][side]', 'side');
          formData.append('textureFileMetaData[' + index + '][surface]', 'surface');
        });

        if (repositoryData?.id) {
          try {
            setIsLoading(true);
            setProgress(0);
            addRepositoryFile(
              repositoryData?.id,
              formData,
              {
                file: file,
                textureFiles: textures,
              },
              (progress: number) => {
                setProgress(progress);
              },
              (res: string) => {
                const response: ICreateFileResponse = JSON.parse(res);
                if (response.id) {
                  const newFile: IFile = {
                    id: response.id,
                    storageKey: response.storageKey,
                    name: response.name,
                    metaData: response.metaData,
                    url: response.url || '',
                    creatorId: response.creatorId,
                    createdAt: {
                      _seconds: moment().unix(),
                    },
                    updatedAt: {
                      _seconds: moment().unix(),
                    },
                    updatedHistory: [
                      {
                        updatedAt: {
                          _seconds: moment().unix(),
                        },
                        user: {
                          id: stateBase?.userInfo?.id || '',
                          name: stateBase?.userInfo?.name || '',
                          avatarUrl: stateBase?.userInfo?.avatarUrl || '',
                          email: stateBase?.userInfo?.email || '',
                        },
                      },
                    ],
                    isShow: true,
                    textureFiles: response?.textureFiles,
                  };

                  let updatedRepositoryData = repositoryData;
                  try {
                    updatedRepositoryData = addItemToRepository(
                      stateBase?.userInfo,
                      newFile,
                      currentPath,
                      repositoryData,
                    );
                    setRepositoryData(updatedRepositoryData);
                    alert({ type: 'success', content: t('directoryScreen.message.addFileSuccess') });
                  } catch (error: any) {
                    alert({ type: 'error', content: t('directoryScreen.message.addFileNotAccepted') });
                  }
                } else {
                  alert({ type: 'error', content: t('directoryScreen.message.addFileFailed') });
                }
                setIsLoading(false);
                setProgress(null);
                setIsVisibleUploadFile(false);
              },
              (error: string) => {
                alert({ type: 'error', content: t('directoryScreen.message.addFileNotAccepted') });
                setIsLoading(false);
                setProgress(null);
              },
            );
          } catch (error) {
            alert({ type: 'error', content: t('directoryScreen.message.addFileFailed') });
            setIsLoading(false);
            setProgress(null);
          }
        }
      }
      setContextMenuPosition(null);
    },
    [repositoryData, alert, t, stateBase?.userInfo],
  );

  const handleFileSelect = useCallback(
    (item: IDirectory | IFile | null) => {
      setNewItemSelect(item);
      setIsVisibleUploadFile(true);
    },
    [setIsVisibleUploadFile],
  );

  const handleCreateNewFolder = useCallback(
    (item: IDirectory | IFile | null) => {
      if (!repositoryData) return;
      let currentPath = '';
      if (item) {
        currentPath = getPathInRepository(item, repositoryData);
      }
      const defaultFolderName = getDefaultNewFolderName(repositoryData, currentPath);
      const newFolder: IDirectory = {
        id: currentPath + '/' + defaultFolderName,
        name: defaultFolderName,
        files: [],
        directories: [],
        creatorId: stateBase?.userInfo?.id || '',
        createdAt: {
          _seconds: moment().unix(),
        },
        updatedAt: {
          _seconds: moment().unix(),
        },
        isShow: true,
        isShowInModal: currentPath === '',
      };
      let updatedRepositoryData = repositoryData;
      try {
        updatedRepositoryData = addItemToRepository(stateBase?.userInfo, newFolder, currentPath, repositoryData);
      } catch (error: any) {
        alert({ type: 'error', content: t('directoryScreen.message.addFolderNotAccepted') });
      }
      setRepositoryData(updatedRepositoryData);
      setIsCreateFolder(true);
      setEditingItemId(newFolder.id);
      setNewName(defaultFolderName);
      setContextMenuPosition(null);
    },
    [repositoryData, stateBase?.userInfo, alert, t],
  );

  const handleUploadUpdateFile = () => {
    setIsVisibleUpdateFile(true);
  };

  const handleShowModalMoveItem = () => {
    setIsVisibleMoveItem(true);
  };

  const handleUpdateThisItem = useCallback(
    async (fileUpdate: File, commitMessage: string, textureFiles: File[] = []) => {
      if (!repositoryData) return;
      if (!fileUpdate || !selectedItemContextMenu) {
        alert({ type: 'error', content: t('directoryScreen.message.updateFileValidationFailed') });
        return;
      }
      if (!commitMessage) {
        alert({ type: 'error', content: t('directoryScreen.message.updateFileMessageCommitRequired') });
        return;
      }

      // check file size
      if (fileUpdate.size > MAX_SIZE_UPLOAD_FILE) {
        alert({ type: 'error', content: t('directoryScreen.message.updateFileMaxSize') });
        return;
      }

      // check each texture file size
      for (const element of textureFiles) {
        if (element.size > MAX_SIZE_UPLOAD_FILE) {
          alert({ type: 'error', content: t('directoryScreen.message.updateFileMaxSize') });
          return;
        }
      }

      const dataUpdate = new FormData();
      dataUpdate.append('file', fileUpdate);
      dataUpdate.append('path', getPathInRepository(selectedItemContextMenu, repositoryData));
      dataUpdate.append('metaData[mesh]', 'mesh');
      dataUpdate.append('metaData[side]', 'side');
      dataUpdate.append('metaData[surface]', 'surface');
      dataUpdate.append('comment', commitMessage);
      dataUpdate.append('fileId', selectedItemContextMenu.id);

      textureFiles.forEach((texture, index) => {
        dataUpdate.append('textureFiles', texture);
        dataUpdate.append('textureFilesMetaData[' + index + '][mesh]', 'mesh');
        dataUpdate.append('textureFilesMetaData[' + index + '][side]', 'side');
        dataUpdate.append('textureFilesMetaData[' + index + '][surface]', 'surface');
      });

      const finish = () => {
        setIsLoading(false);
        setProgress(null);
        setContextMenuPosition(null);
        setIsVisibleUpdateFile(false);
      };

      try {
        setProgress(0);
        setIsLoading(true);
        await updateRepositoryFile(
          repositoryData.id,
          dataUpdate,
          {
            file: fileUpdate,
            textureFiles: textureFiles,
          },
          (progress: number) => {
            setProgress(progress);
          },
          (res: string) => {
            const response: IFile = JSON.parse(res);
            if (response.id) {
              const updatedItem = {
                ...selectedItemContextMenu,
                upcomingFile: response.upcomingFile,
                updatedAt: {
                  _seconds: moment().unix(),
                },
              };
              const updatedRepositoryData = updateFileInRepository(
                stateBase?.userInfo,
                repositoryData,
                selectedItemContextMenu.id,
                updatedItem as IFile,
              );
              setRepositoryData(updatedRepositoryData);
              alert({ type: 'success', content: t('directoryScreen.message.updateFileSuccess') });
            } else {
              alert({ type: 'error', content: t('directoryScreen.message.updateFileFailed') });
            }
            finish();
          },
          (error: string) => {
            alert({ type: 'error', content: t('directoryScreen.message.updateFileNotAccepted') });
            finish();
          },
        );
      } catch (error) {
        alert({ type: 'error', content: t('directoryScreen.message.updateFileFailed') });
        finish();
      }
    },
    [repositoryData, selectedItemContextMenu, alert, t, stateBase?.userInfo],
  );

  const handleRenameThisItem = useCallback(
    async (item: IFile | IDirectory, newName: string) => {
      if (!repositoryData || (item.name === newName && !isCreateFolder)) return;
      const updatedItem = {
        ...item,
        name: newName,
        updatedAt: {
          _seconds: moment().unix(),
        },
        updatedHistory: [
          ...(item.updatedHistory || []),
          {
            updatedAt: {
              _seconds: moment().unix(),
            },
            user: {
              id: stateBase?.userInfo?.id || '',
              name: stateBase?.userInfo?.name || '',
              avatarUrl: stateBase?.userInfo?.avatarUrl || '',
              email: stateBase?.userInfo?.email || '',
            },
          },
        ],
      };

      if (isFileType(item)) {
        setIsLoading(true);
        const response = await renameRepositoryFile(repositoryData.id, {
          path: getPathInRepository(item, repositoryData),
          fileId: item.id,
          newName,
        });
        if (response?.name) {
          setActiveFile(updatedItem);
          addOrUpdateModelFileToLocalStorage(updatedItem as IFile, repositoryData);
          const updatedRepositoryData = updateFileInRepository(
            stateBase?.userInfo,
            repositoryData,
            item.id,
            updatedItem as IFile,
          );
          setRepositoryData(updatedRepositoryData);
          alert({ type: 'success', content: t('directoryScreen.message.renameFileSuccess') });
        } else {
          alert({ type: 'error', content: t('directoryScreen.message.renameFileFailed') });
        }
        setIsLoading(false);
      } else {
        setIsLoading(true);
        if (isCreateFolder) {
          const currentPath = getPathInRepository(item, repositoryData, false, false, true);
          const addData: ICreateRemoveDirectoryData = {
            path: currentPath,
            directoryName: newName,
          };
          const response = await addRepositoryDirectory(repositoryData.id, addData);
          if (response?.id) {
            const updatedRepositoryData = updateDirectoryInRepository(
              stateBase?.userInfo,
              repositoryData,
              item.id,
              updatedItem as IDirectory,
              response.id,
            );
            setRepositoryData(sortRepository(updatedRepositoryData));
            alert({ type: 'success', content: t('directoryScreen.message.addFolderSuccess') });
          } else {
            const updatedRepositoryData = removeItemFromRepository(stateBase?.userInfo, repositoryData, item);
            setRepositoryData(sortRepository(updatedRepositoryData));
            alert({ type: 'error', content: t('directoryScreen.message.addFolderFailed') });
          }
        } else {
          const response = await renameRepositoryDirectory(repositoryData.id, {
            path: getPathInRepository(item, repositoryData, false, false, true),
            directoryId: item.id,
            newName: newName,
          });
          if (response?.name) {
            const updatedRepositoryData = updateDirectoryInRepository(
              stateBase?.userInfo,
              repositoryData,
              item.id,
              updatedItem as IDirectory,
            );
            setRepositoryData(sortRepository(updatedRepositoryData));
            alert({ type: 'success', content: t('directoryScreen.message.renameFolderSuccess') });
          } else {
            alert({ type: 'error', content: t('directoryScreen.message.renameFolderFailed') });
          }
        }
        setIsLoading(false);
        setIsCreateFolder(false);
      }
    },
    [repositoryData, isCreateFolder, alert, t, stateBase],
  );

  const handleRemoveFile = async (item: IFile | IDirectory | null) => {
    if (!item || !repositoryData) return;
    setIsLoading(true);
    let checkRemove = false;
    if (isFileType(item)) {
      const dataRemove: IRemoveFileData = {
        fileId: item.id,
        path: getPathInRepository(item, repositoryData),
      };
      checkRemove = await removeRepositoryFile(repositoryData.id, dataRemove);
    } else {
      const dataRemove: ICreateRemoveDirectoryData = {
        directoryName: item.name,
        path: getPathInRepository(item, repositoryData, false, false, true),
      };
      checkRemove = await removeRepositoryDirectory(repositoryData.id, dataRemove);
    }
    if (checkRemove) {
      const updatedDataTable = removeItemFromRepository(stateBase?.userInfo, repositoryData, item);
      if (isFileType(item)) {
        if (activeFile?.id === item.id) {
          setActiveFile(null);
        }
        removeModelFileFromLocalStorage(item);
      }
      setRepositoryData(updatedDataTable as any);
      alert({ type: 'success', content: t('directoryScreen.message.removeItemSuccess') });
    } else {
      alert({ type: 'error', content: t('directoryScreen.message.removeItemFailed') });
    }
    setIsLoading(false);
    setIsVisibleRemoveFile(false);
  };

  const handleClickRemoveItem = () => {
    setIsVisibleRemoveFile(true);
  };

  const handleRedirectManageAccess = useCallback(() => {
    navigate(`/repository/${id}/settings/access-management`);
  }, [id, navigate]);

  const handleMoveItem = useCallback(async () => {
    if (!repositoryData || !selectedItemContextMenu) return;
    setIsLoading(true);
    let currentPath = '';
    if (isFileType(selectedItemContextMenu)) {
      currentPath = getPathInRepository(selectedItemContextMenu, repositoryData);
    } else {
      currentPath = getPathInRepository(selectedItemContextMenu, repositoryData, false, false, true);
    }

    let response: IFile | IDirectory | IErrorResponse;
    if (isFileType(selectedItemContextMenu)) {
      const moveData: IMoveFileData = {
        path: currentPath,
        newPath: selectedFolderInModal ? getPathInRepository(selectedFolderInModal, repositoryData) : '',
        fileId: selectedItemContextMenu.id,
      };
      response = await moveRepositoryFile(repositoryData.id, moveData);
    } else {
      const moveData: IMoveDirectoryData = {
        path: currentPath,
        newPath: selectedFolderInModal ? getPathInRepository(selectedFolderInModal, repositoryData) : '',
        directoryId: selectedItemContextMenu.id,
      };
      response = await moveRepositoryDirectory(repositoryData.id, moveData);
    }
    if (!('isError' in response)) {
      let updatedRepositoryData = removeItemFromRepository(
        stateBase?.userInfo,
        repositoryData,
        selectedItemContextMenu,
      );
      if (!selectedFolderInModal) {
        if (isFileType(selectedItemContextMenu)) {
          updatedRepositoryData = {
            ...updatedRepositoryData,
            files: [...updatedRepositoryData.files, selectedItemContextMenu as IFile],
          };
        } else {
          updatedRepositoryData = {
            ...updatedRepositoryData,
            directories: [...updatedRepositoryData.directories, selectedItemContextMenu as IDirectory],
          };
        }
      } else {
        let directorySelected: IDirectory = {
          ...selectedFolderInModal,
          directories: [...selectedFolderInModal.directories, selectedItemContextMenu as IDirectory],
        };
        if (isFileType(selectedItemContextMenu)) {
          directorySelected = {
            ...selectedFolderInModal,
            files: [...selectedFolderInModal.files, selectedItemContextMenu as IFile],
            updatedAt: {
              _seconds: moment().unix(),
            },
          };
        }
        updatedRepositoryData = updateDirectoryInRepository(
          stateBase?.userInfo,
          updatedRepositoryData,
          selectedFolderInModal.id,
          directorySelected,
        );
        updatedRepositoryData = openPathInRepository(
          updatedRepositoryData,
          getPathInRepository(selectedFolderInModal, updatedRepositoryData),
        );
      }
      setRepositoryData(sortRepository(updatedRepositoryData));
      // update local storage
      if (isFileType(selectedItemContextMenu)) {
        addOrUpdateModelFileToLocalStorage(selectedItemContextMenu as IFile, repositoryData);
      }
      alert({ type: 'success', content: t('directoryScreen.message.moveItemSuccess') });
    } else if ('isError' in response) {
      alert({ type: 'error', content: t('directoryScreen.message.moveItemNotAccepted') });
    } else {
      alert({ type: 'error', content: t('directoryScreen.message.moveItemFailed') });
    }
    setIsLoading(false);
    setIsVisibleMoveItem(false);
  }, [repositoryData, selectedItemContextMenu, selectedFolderInModal, alert, t, stateBase?.userInfo]);

  const handleContextMenuSideLeft = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (flagViewOldVersion) return;
      event.preventDefault();
      if (
        !(event.target as HTMLElement).classList.contains('container-left') &&
        !(event.target as HTMLElement).classList.contains('table-folder-structure')
      ) {
        return;
      }

      const contextMenuX = event.clientX;
      const contextMenuY = event.clientY;
      const isRightAligned = contextMenuX > width / 2 - RIGHT_CLICK_CONTEXT_MENU_WIDTH / 2;
      const isTop = contextMenuY > height - RIGHT_CLICK_CONTEXT_MENU_HEIGHT;
      const contextMenuPositionConst = {
        x: contextMenuX,
        y: contextMenuY,
        isRightAligned,
        isTop,
      };
      setContextMenuPosition(contextMenuPositionConst);
      setHoverMenuPosition(null);

      setContextMenuItems([
        {
          label: t('directoryScreen.rightClickMenu.addNewFile'),
          action: () => handleFileSelect(null),
          className: '',
          isDisabled: false,
          icon: addFileIconBlue,
        },
        {
          label: t('directoryScreen.rightClickMenu.addNewFolder'),
          action: () => handleCreateNewFolder(null),
          className: 'border-bottom',
          isDisabled: false,
          icon: addFolderIconBlue,
        },
        {
          label: t('directoryScreen.rightClickMenu.updateItem'),
          action: () => {},
          className: 'disabled',
          isDisabled: true,
          icon: updateFileIconBlue,
        },
        {
          label: t('directoryScreen.rightClickMenu.renameItem'),
          action: () => {},
          className: 'border-bottom disabled',
          isDisabled: true,
          icon: removeItemIconBlue,
        },
        {
          label: t('directoryScreen.rightClickMenu.moveItem'),
          action: () => {},
          icon: moveFileIcon,
          className: 'disabled',
          isDisabled: true,
        },
        {
          label: t('directoryScreen.rightClickMenu.removeItem'),
          action: () => {},
          className: 'text-red disabled',
          isDisabled: true,
          icon: removeItemIconBlue,
        },
      ]);
    },
    [flagViewOldVersion, handleFileSelect, handleCreateNewFolder, width, height, t],
  );

  const handleContextMenu = useCallback(
    (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, item: IDirectory | IFile) => {
      if (flagViewOldVersion) return;
      event.preventDefault();
      setSelectedItemContextMenu(item);

      const contextMenuX = event.clientX;
      const contextMenuY = event.clientY;
      const isRightAligned = contextMenuX > width / 2 - RIGHT_CLICK_CONTEXT_MENU_WIDTH / 2;
      const isTop = contextMenuY > height - RIGHT_CLICK_CONTEXT_MENU_HEIGHT;
      const contextMenuPositionConst = {
        x: contextMenuX,
        y: contextMenuY,
        isRightAligned,
        isTop,
      };
      setContextMenuPosition(contextMenuPositionConst);
      setHoverMenuPosition(null);

      const isFile = isFileType(item);
      const items: IContextMenuItem[] = [
        {
          label: t('directoryScreen.rightClickMenu.addNewFile'),
          action: () => handleFileSelect(item),
          className: '',
          isDisabled: false,
          icon: addFileIconBlue,
        },
        {
          label: t('directoryScreen.rightClickMenu.addNewFolder'),
          action: () => handleCreateNewFolder(item),
          className: 'border-bottom',
          isDisabled: false,
          icon: addFolderIconBlue,
        },
        {
          label: t('directoryScreen.rightClickMenu.updateItem'),
          action: isFile ? handleUploadUpdateFile : () => {}, // show modal
          className: isFile ? '' : 'disabled',
          isDisabled: !isFile,
          icon: updateFileIconBlue,
        },
        {
          label: t('directoryScreen.rightClickMenu.renameItem'),
          action: () => handleClickRenameItem(item),
          className: 'border-bottom',
          isDisabled: false,
          icon: renameItemIconBlue,
        },
        {
          label: t('directoryScreen.rightClickMenu.moveItem'),
          action: handleShowModalMoveItem, // show modal
          icon: moveFileIconBlue,
          afterIcon: iconTriagle,
          className: '',
          isDisabled: false,
        },
        {
          label: t('directoryScreen.rightClickMenu.removeItem'),
          action: handleClickRemoveItem, // show modal
          className: 'text-red',
          isDisabled: false,
          icon: removeItemIconBlue,
        },
      ];
      setContextMenuItems(items);
    },
    [flagViewOldVersion, width, height, handleFileSelect, handleCreateNewFolder, t],
  );

  const hideContextMenu = () => {
    setContextMenuPosition(null);
    setContextMenuItems([]);
  };

  const hideHoverMenu = () => {
    setHoverMenuPosition(null);
    setHoverMenuItems([]);
  };

  const executeAction = (action: () => void, isDisabled?: boolean) => {
    if (isDisabled) return;
    action();
    hideContextMenu();
    hideHoverMenu();
  };

  const handleHoverFile = useCallback(
    (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, item: IFile) => {
      if (flagViewOldVersion || contextMenuPosition) return;
      setSelectedItemContextMenu(item);
      const repoItem = event?.currentTarget?.closest('.repo-item');
      if (!repoItem) return;

      const hoverMenuX = repoItem.getBoundingClientRect().left + repoItem.getBoundingClientRect().width - 105;
      const hoverMenuY = repoItem.getBoundingClientRect().top - 14;
      const hoverMenuPositionConst = {
        x: hoverMenuX,
        y: hoverMenuY,
      };
      setHoverMenuPosition(hoverMenuPositionConst);

      const items: IHoverMenuItem[] = [
        {
          action: handleShowModalMoveItem, // show modal
          icon: moveFileIcon,
          label: t('directoryScreen.rightClickMenu.moveItem'),
        },
        {
          action: handleUploadUpdateFile, // show modal
          icon: updateFileIcon,
          label: t('directoryScreen.rightClickMenu.updateItem'),
        },
        {
          action: () => handleClickRenameItem(item),
          icon: renameItemIcon,
          label: t('directoryScreen.rightClickMenu.renameItem'),
        },
        {
          action: handleClickRemoveItem, // show modal
          icon: removeItemIcon,
          label: t('directoryScreen.rightClickMenu.removeItem'),
        },
      ];
      setHoverMenuItems(items);
    },
    [flagViewOldVersion, contextMenuPosition, t],
  );

  const handleHoverDirectory = useCallback(
    (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, item: IDirectory) => {
      if (flagViewOldVersion || contextMenuPosition) return;
      setSelectedItemContextMenu(item);
      const repoItem = event?.currentTarget?.closest('.repo-item');
      if (!repoItem) return;

      const hoverMenuX = repoItem.getBoundingClientRect().left + repoItem.getBoundingClientRect().width - 105;
      const hoverMenuY = repoItem.getBoundingClientRect().top - 14;
      const hoverMenuPositionConst = {
        x: hoverMenuX,
        y: hoverMenuY,
      };
      setHoverMenuPosition(hoverMenuPositionConst);

      const items: IHoverMenuItem[] = [
        {
          action: () => handleCreateNewFolder(item),
          icon: addFolderIcon,
          label: t('directoryScreen.rightClickMenu.addNewFolder'),
        },
        {
          action: () => handleFileSelect(item),
          icon: addFileIcon,
          label: t('directoryScreen.rightClickMenu.addNewFile'),
        },
        {
          action: () => handleClickRenameItem(item),
          icon: renameItemIcon,
          label: t('directoryScreen.rightClickMenu.renameItem'),
        },
        {
          action: handleClickRemoveItem, // show modal
          icon: removeItemIcon,
          label: t('directoryScreen.rightClickMenu.removeItem'),
        },
      ];
      setHoverMenuItems(items);
    },
    [flagViewOldVersion, contextMenuPosition, handleFileSelect, handleCreateNewFolder, t],
  );

  /* End Right Click/ Hover Item Functions */

  /* Start Checkbox Item Functions */
  const handleClickCheckboxItem = useCallback(
    (item: IDirectory | IFile) => {
      if (!repositoryData) return;

      const checkAllItemsRecursive = (dir: IDirectory): boolean => {
        const allFilesChecked = dir.files.every((file) => file.isChecked);
        const allDirectoriesChecked = dir.directories.every(
          (subDir) => subDir.isChecked && checkAllItemsRecursive(subDir),
        );
        return allFilesChecked && allDirectoriesChecked;
      };

      const checkAllItems = (repo: IRepository): boolean => {
        const allFilesChecked = repo.files.every((file) => file.isChecked);
        const allDirectoriesChecked = repo.directories.every((dir) => dir.isChecked && checkAllItemsRecursive(dir));

        return allFilesChecked && allDirectoriesChecked;
      };

      const updateParentDirectories = (directories: IDirectory[]): IDirectory[] => {
        return directories.map((directory) => {
          if (directory.directories.length > 0 || directory.files.length > 0) {
            if (directory.directories.length > 0) {
              directory.directories = updateParentDirectories(directory.directories);
            }
            directory.isChecked = checkAllItemsRecursive(directory);
          }
          return directory;
        });
      };

      const updateRepository = (repo: IRepository): IRepository => {
        repo.directories = updateParentDirectories(repo.directories);
        return repo;
      };

      if (isFileType(item)) {
        const updateItem: IFile = {
          ...item,
          isChecked: !item.isChecked,
        };

        let updatedRepositoryData = updateFileInRepository(null, repositoryData, item.id, updateItem);
        updatedRepositoryData = updateRepository(updatedRepositoryData);
        setRepositoryData({
          ...updatedRepositoryData,
          isCheckedAll: checkAllItems(updatedRepositoryData),
        });
      } else {
        const updateItem: IDirectory = {
          ...item,
          isChecked: !item.isChecked,
        };
        const checkSubFolders = (dir: IDirectory, isChecked: boolean) => {
          dir.directories.forEach((subDir) => {
            subDir.isChecked = isChecked;
            checkSubFolders(subDir, isChecked);
          });
          dir.files.forEach((file) => {
            file.isChecked = isChecked;
          });
        };
        checkSubFolders(updateItem, updateItem.isChecked!);
        let updatedRepositoryData = updateDirectoryInRepository(null, repositoryData, item.id, updateItem);
        updatedRepositoryData = updateRepository(updatedRepositoryData);
        setRepositoryData({
          ...updatedRepositoryData,
          isCheckedAll: checkAllItems(updatedRepositoryData),
        });
      }
    },
    [repositoryData],
  );

  const handleSelectAll = useCallback(
    (isChecked: boolean) => {
      if (!repositoryData) return;

      const checkFiles = (files: IFile[], isChecked: boolean): IFile[] => {
        return files.map((file) => ({ ...file, isChecked }));
      };

      const checkDirectories = (directories: IDirectory[], isChecked: boolean): IDirectory[] => {
        return directories.map((directory) => ({
          ...directory,
          isChecked,
          files: checkFiles(directory.files, isChecked),
          directories: checkDirectories(directory.directories, isChecked),
        }));
      };

      const updatedRepositoryData: IRepository = {
        ...repositoryData,
        files: checkFiles(repositoryData.files, isChecked),
        directories: checkDirectories(repositoryData.directories, isChecked),
      };

      setRepositoryData({
        ...updatedRepositoryData,
        isCheckedAll: isChecked,
      });
    },
    [repositoryData],
  );

  const toggleMenuCheckbox = () => {
    setMenuActionFile(!menuActionFile);
  };

  const getTextActionCheckbox = (action: string, toUpperCase: boolean = true) => {
    if (action === 'delete') {
      return toUpperCase ? t('common.btnDelete') : t('common.actionDelete');
    }
    return toUpperCase ? t('common.btnDownload') : t('common.actionDownload');
  };

  const handleComfirmDownloadCheckedItems = useCallback(() => {
    if (!repositoryData) return;
    if (countCheckedItems(repositoryData) === 0) return;
    setActionCheckbox('download');
    setIsPopupAction(true);
    setMenuActionFile(false);
  }, [repositoryData]);

  const handleComfirmRemoveCheckedItems = useCallback(() => {
    if (!repositoryData || flagViewOldVersion) return;
    if (countCheckedItems(repositoryData) === 0) return;
    setActionCheckbox('delete');
    setIsPopupAction(true);
    setMenuActionFile(false);
  }, [repositoryData, flagViewOldVersion]);

  const handleRemoveCheckedItems = useCallback(async () => {
    if (!repositoryData) return;
    if (countCheckedItems(repositoryData) === 0) return;

    setIsLoading(true);
    const checkedItems = getCheckedItems(repositoryData);

    const removeFilePromises = checkedItems?.files?.map(async (item) => {
      const dataRemove: IRemoveFileData = {
        fileId: item.id,
        path: getPathInRepository(item, repositoryData),
      };
      return removeRepositoryFile(repositoryData.id, dataRemove)
        .then((): IRemoveItemRepositoryResult<IFile> => ({ success: true, item }))
        .catch((): IRemoveItemRepositoryResult<IFile> => ({ success: false, item }));
    });

    const removeDirectoryPromises = checkedItems?.directories?.map(async (item) => {
      const dataRemove: ICreateRemoveDirectoryData = {
        directoryName: item.name,
        path: getPathInRepository(item, repositoryData, false, false, true),
      };
      return removeRepositoryDirectory(repositoryData.id, dataRemove)
        .then((): IRemoveItemRepositoryResult<IDirectory> => ({ success: true, item }))
        .catch((): IRemoveItemRepositoryResult<IDirectory> => ({ success: false, item }));
    });

    const results = await Promise.all([...removeFilePromises, ...removeDirectoryPromises]);

    const failedItems = results.filter((result) => !result.success)?.map((result) => result.item);
    const successfulItems = results.filter((result) => result.success)?.map((result) => result.item);

    if (failedItems.length > 0) {
      const failedItemsNames = failedItems?.map((item) => item.name).join(', ');
      alert({
        type: 'error',
        content: t('directoryScreen.message.removeItemsFailed', { items: failedItemsNames }),
      });
    } else {
      alert({ type: 'success', content: t('directoryScreen.message.removeItemsSuccess') });
    }

    // check and remove item in local storage
    successfulItems.forEach((item) => {
      if (isFileType(item)) {
        removeModelFileFromLocalStorage(item);
      }
    });

    // set active file to null if it is removed
    const successfulItemsIds = successfulItems.map((item) => item.id);
    if (activeFile && successfulItemsIds.includes(activeFile.id)) {
      setActiveFile(null);
    }

    const updatedRepositoryData = removeCheckedItems(stateBase?.userInfo, repositoryData, successfulItems);
    setRepositoryData(updatedRepositoryData);

    setIsPopupAction(false);
    setIsLoading(false);
  }, [repositoryData, alert, activeFile, t, stateBase?.userInfo]);

  const handleDownloadCheckedItems = useCallback(async () => {
    if (!repositoryData) return;
    if (countCheckedItems(repositoryData) === 0) return;

    setIsLoading(true);

    let downloadFileData: IDownloadFileData;

    if (repositoryData?.isCheckedAll) {
      downloadFileData = {
        isAllFiles: true,
      };
    } else {
      const checkedItems = getCheckedItems(repositoryData);

      const fileIds = checkedItems?.files?.map((file) => file.id) || [];

      checkedItems?.directories?.forEach((dir) => {
        dir.files.forEach((file) => {
          fileIds.push(file.id);
        });
      });

      downloadFileData = {
        isAllFiles: false,
        fileIds,
      };
    }

    const response = await downloadRepositoryFiles(repositoryData.id, downloadFileData);
    if (!('isError' in response) && response.downloadUrl) {
      let fileName = `${repositoryData.name}.zip`;
      if (!repositoryData?.isCheckedAll) {
        fileName = `a-part-of-${repositoryData.name}.zip`;
      }
      await downloadFromUrl(response.downloadUrl, fileName);
    } else if ('isError' in response && response.message) {
      alert({ type: 'error', content: t('directoryScreen.message.downloadCheckedItemsFailed') });
    } else {
      alert({ type: 'error', content: t('directoryScreen.message.downloadCheckedItemsFailed') });
    }
    setIsLoading(false);
    setIsPopupAction(false);
  }, [repositoryData, alert, t]);

  const handleActionCheckedItems = useCallback(() => {
    if (actionCheckbox === 'delete') {
      handleRemoveCheckedItems();
    } else if (actionCheckbox === 'download') {
      handleDownloadCheckedItems();
    }
  }, [actionCheckbox, handleRemoveCheckedItems, handleDownloadCheckedItems]);
  /* End Checkbox Item Functions */

  const handleDropFileRepository = (files: FileList | null) => {
    if (flagViewOldVersion || !files || files.length === 0) return;
    if (files.length > 1) {
      alert({ type: 'warning', content: t('uploadFileModal.message.modelFileLimit') });
    } else {
      const name = files[0].name;
      const type = name.split('.')[1];
      if (SUPPORTED_FILE_TYPE.includes('.' + type)) {
        handleAddNewFile(files[0], null);
      } else {
        alert({ type: 'warning', content: t('uploadFileModal.message.fileTypeNotSupported') });
      }
    }
    setIsDraggingFileRepository(false);
  };

  const handleDragOverRepository = (event: React.DragEvent<HTMLDivElement>) => {
    if (flagViewOldVersion) return;
    event.preventDefault();
    setIsDraggingFileRepository(true);
  };

  const handleDragLeaveRepository = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDraggingFileRepository(false);
  };
  /* End Drag/Drop Item Functions */

  const handleDocumentClick = useCallback(
    (event: MouseEvent) => {
      if (
        contextMenuPosition ||
        event.target instanceof Node ||
        !(event.target as HTMLElement).closest('.context-menu')
      ) {
        const isDisabled = (event.target as HTMLElement).closest('.context-menu-item.disabled');
        if (isDisabled) return;
        hideContextMenu();
      }
    },
    [contextMenuPosition],
  );

  const handleClickOutsideDropVersion = useCallback(
    (event: MouseEvent) => {
      const target = event.target as Node;
      if (dropdownVersionRef.current && !dropdownVersionRef.current.contains(target)) {
        setIsOpenDropdownVersion(false);
      }
    },
    [dropdownVersionRef],
  );

  const handleClickOutsideMenuAction = useCallback(
    (event: MouseEvent) => {
      const target = event.target as Node;
      if (menuActionFileRef.current && !menuActionFileRef.current.contains(target)) {
        setMenuActionFile(false);
      }
    },
    [menuActionFileRef],
  );

  const onCloseRecommendShareYourTeam = useCallback(async () => {
    if (!repositoryData) return;
    const dataUpdate: ITutorialStatus = {
      isInviteMembersTutorialCompleted: true,
      isCreateDirectoryTutorialCompleted: !isShowCreateDirectory,
      isUploadFileTutorialCompleted: !isShowUploadFiles,
    };
    setIsLoading(true);
    const response = await updateRepositoryTutorialsStatus(repositoryData.id, dataUpdate);
    if (response) {
      setIsShowShareYourTeam(false);
    } else {
      alert({ type: 'error', content: t('directoryScreen.message.updateTutorialFailed') });
    }
    setIsLoading(false);
  }, [repositoryData, isShowCreateDirectory, isShowUploadFiles, alert, t]);

  const onCloseRecommendCreateDirectory = useCallback(async () => {
    if (!repositoryData) return;
    const dataUpdate: ITutorialStatus = {
      isInviteMembersTutorialCompleted: !isShowShareYourTeam,
      isCreateDirectoryTutorialCompleted: true,
      isUploadFileTutorialCompleted: !isShowUploadFiles,
    };
    setIsLoading(true);
    const response = await updateRepositoryTutorialsStatus(repositoryData.id, dataUpdate);
    if (response) {
      setIsShowCreateDirectory(false);
    } else {
      alert({ type: 'error', content: t('directoryScreen.message.updateTutorialFailed') });
    }
    setIsLoading(false);
  }, [repositoryData, isShowShareYourTeam, isShowUploadFiles, alert, t]);

  const onCloseRecommendUploadFiles = useCallback(async () => {
    if (!repositoryData) return;
    const dataUpdate: ITutorialStatus = {
      isInviteMembersTutorialCompleted: !isShowShareYourTeam,
      isCreateDirectoryTutorialCompleted: !isShowCreateDirectory,
      isUploadFileTutorialCompleted: true,
    };
    setIsLoading(true);
    const response = await updateRepositoryTutorialsStatus(repositoryData.id, dataUpdate);
    if (response) {
      setIsShowUploadFiles(false);
    } else {
      alert({ type: 'error', content: t('directoryScreen.message.updateTutorialFailed') });
    }
    setIsLoading(false);
  }, [repositoryData, isShowShareYourTeam, isShowCreateDirectory, alert, t]);

  const handleSumitCreateVersion = useCallback(async () => {
    if (!versionInput) {
      alert({ type: 'warning', content: t('directoryScreen.message.versionNameRequired') });
      return;
    }
    if (!repositoryData) {
      return;
    }
    setIsLoading(true);
    const data: ICreateVersionData = {
      name: versionInput,
    };
    const response = await createRepositoryVersion(repositoryData.id, data);
    if (!('isError' in response)) {
      setVersions([response, ...versions]);
      setLatestVersion(response);
      setVersionSelected(response);
      alert({ type: 'success', content: t('directoryScreen.message.saveVersionSuccess') });
    } else if ('isError' in response) {
      alert({ type: 'error', content: t('directoryScreen.message.saveVersionNotAccepted') });
    } else {
      alert({ type: 'error', content: t('directoryScreen.message.saveVersionFailed') });
    }
    setVersionInput('');
    setIsLoading(false);
    setIsVisibleSaveVersion(false);
  }, [versionInput, alert, repositoryData, versions, t]);

  const onChangeVersion = useCallback(
    (newVersion: IRepositoryVersion) => {
      setVersionSelected(newVersion);
      setFlagViewOldVersion(newVersion.id !== latestVersion?.id);

      if (repositoryDetail) {
        const newRepositoryDetail = structuredClone(repositoryDetail);
        newRepositoryDetail.directories = newVersion.directories;
        newRepositoryDetail.files = newVersion.files;
      }
      setIsOpenDropdownVersion(false);
    },
    [latestVersion, repositoryDetail],
  );

  const handleDeleteOldVersion = useCallback(async () => {
    if (isEmpty(versionDelete) || !repositoryData) return;
    const response = await removeRepositoryVersion(repositoryData?.id, { versionId: versionDelete.id });
    if (response) {
      setVersionDelete({} as IRepositoryVersion);
      alert({ type: 'success', content: t('directoryScreen.message.removeVersionSuccess') });
      refetchRepositoryDetail();
    } else {
      alert({ type: 'error', content: t('directoryScreen.message.removeVersionFailed') });
    }
  }, [versionDelete, alert, repositoryData, refetchRepositoryDetail, t]);

  //Reset new item select
  useEffect(() => {
    if (!isVisibleUploadFile) {
      setNewItemSelect(null);
    }
  }, [isVisibleUploadFile]);

  useEffect(() => {
    if (isLoadingRepositoryDetail || stateBase.isLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isLoadingRepositoryDetail, stateBase.isLoading]);

  useEffect(() => {
    initRepositoryDetail();
  }, [initRepositoryDetail]);

  useEffect(() => {
    // get latest version and versions
    const versionList = repositoryDetail?.versions || [];
    if (versionList.length > 0) {
      const sortedVersionList = [...versionList].sort(
        (a: IRepositoryVersion, b: IRepositoryVersion) => b.versionedAt._seconds - a.versionedAt._seconds,
      );
      const latestVersion = sortedVersionList[0];
      setLatestVersion(latestVersion);
      setVersionSelected(latestVersion);
      setVersions(sortedVersionList);
    }
  }, [repositoryDetail]);

  useEffect(() => {
    if (repositoryData) {
      setIsEmptyRepository(repositoryData?.directories?.length === 0 && repositoryData?.files?.length === 0);
      setRepositoryOwner(getRepositoryOwner(repositoryData));
      setRepositoryLastUpdatedBy(getRepositoryLastUpdatedBy(repositoryData));
    }
  }, [repositoryData]);

  useEffect(() => {
    setIsCompletedTutorial(!isShowCreateDirectory && !isShowUploadFiles && !isShowShareYourTeam);
  }, [isShowCreateDirectory, isShowUploadFiles, isShowShareYourTeam]);

  useEffect(() => {
    // check tutorial status
    if (repositoryDetail) {
      setIsShowShareYourTeam(
        !repositoryDetail.tutorialStatus?.isInviteMembersTutorialCompleted && repositoryDetail?.members?.length === 1,
      );
      setIsShowCreateDirectory(
        !repositoryDetail.tutorialStatus?.isCreateDirectoryTutorialCompleted && isEmptyRepository,
      );
      setIsShowUploadFiles(!repositoryDetail.tutorialStatus?.isUploadFileTutorialCompleted && isEmptyRepository);
    }
  }, [repositoryDetail, isEmptyRepository]);

  useEffect(() => {
    if (stateData?.fileActive) {
      setActiveFile(stateData?.fileActive);
    }
  }, [stateData?.fileActive]);

  useEffect(() => {
    if (inputRenameRef.current) {
      inputRenameRef.current.select();
    }
  }, [editingItemId]);

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    document.addEventListener('click', handleClickOutsideDropVersion);
    document.addEventListener('click', handleClickOutsideMenuAction);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
      document.removeEventListener('click', handleClickOutsideDropVersion);
      document.removeEventListener('click', handleClickOutsideMenuAction);
    };
  }, [handleDocumentClick, handleClickOutsideDropVersion, handleClickOutsideMenuAction]);

  return (
    <div className="repo-directory-page">
      <LoadingOverlay isLoading={isLoading} progress={progress} />
      {selectedItemContextMenu && repositoryData && (
        <MoveItemToFolderModal
          isVisibleMoveItem={isVisibleMoveItem}
          setIsVisibleMoveItem={setIsVisibleMoveItem}
          itemName={getPathInRepository(selectedItemContextMenu, repositoryData, true)}
          selectedItemContextMenu={selectedItemContextMenu}
          filteredFolders={repositoryData.directories}
          toggleFolder={toggleFolderInModal}
          handleMoveItem={handleMoveItem}
          selectedFolderInModal={selectedFolderInModal}
        />
      )}
      <CreateVersionModal
        isVisibleSaveVersion={isVisibleSaveVersion}
        setIsVisibleSaveVersion={setIsVisibleSaveVersion}
        versionInput={versionInput}
        setVersionInput={setVersionInput}
        handleSumitCreateVersion={handleSumitCreateVersion}
      />
      <ModalConfirm
        isVisible={!isEmpty(versionDelete)}
        onCancel={() => setVersionDelete({} as IRepositoryVersion)}
        width="400px"
        title={<span className="font-en-18 font-ja-14">{t('directoryScreen.deleteVerionModal.title')}</span>}
        textSubmit={t('common.btnDelete')}
        onSubmit={handleDeleteOldVersion}
      >
        <p className="pt-2 mb-0 font-en-16 font-ja-12">{t('directoryScreen.deleteVerionModal.description')}</p>
      </ModalConfirm>
      {selectedItemContextMenu && repositoryData && (
        <ModalConfirm
          setVisible={setIsVisibleRemoveFile}
          isVisible={isVisibleRemoveFile}
          width="481px"
          title={
            i18n.language === 'ja' ? (
              <div>
                <span className="font-ja font-en-14 font-ja-14">
                  “{getPathInRepository(selectedItemContextMenu, repositoryData, true)}”
                </span>
                <span className="font-en-18 font-ja-14">{t('directoryScreen.deleteItemModal.title')}</span>
              </div>
            ) : (
              <div>
                <span className="font-en-18 font-ja-14">{t('directoryScreen.deleteItemModal.title')}</span>
                <span className="font-ja font-en-14 font-ja-14">
                  “{getPathInRepository(selectedItemContextMenu, repositoryData, true)}”
                </span>
              </div>
            )
          }
          content={
            <div>
              <span className="font-en-16 font-ja-12">{t('directoryScreen.deleteItemModal.description1')}</span>
              <span className="font-ja font-en-12 font-ja-12">
                “{getPathInRepository(selectedItemContextMenu, repositoryData, true)}”
              </span>
              <span className="font-en-16 font-ja-12">{t('directoryScreen.deleteItemModal.description2')}</span>
            </div>
          }
          textSubmit={t('common.btnDelete')}
          onSubmit={() => handleRemoveFile(selectedItemContextMenu)}
        />
      )}
      {repositoryData && (
        <ModalConfirm
          setVisible={setIsPopupAction}
          isVisible={isPopupAction}
          width="481px"
          title={
            <div className="font-en-18 font-ja-14">
              {t('directoryScreen.actionSelectedItemsModal.title', {
                action: getTextActionCheckbox(actionCheckbox, true),
              })}
            </div>
          }
          content={
            <div className="font-en-16 font-ja-12">
              {t('directoryScreen.actionSelectedItemsModal.description', {
                action: getTextActionCheckbox(actionCheckbox, false),
                count: countCheckedItems(repositoryData),
              })}
            </div>
          }
          textSubmit={actionCheckbox.charAt(0).toUpperCase() + actionCheckbox.slice(1)}
          onSubmit={handleActionCheckedItems}
        />
      )}
      <UploadFileModal
        isVisibleUploadFile={isVisibleUploadFile}
        setIsVisibleUploadFile={setIsVisibleUploadFile}
        handleUploadThisItem={(file, fileTextures) => handleAddNewFile(file, newItemSelect, fileTextures)}
      />
      <UpdateFileModal
        isVisibleUpdateFile={isVisibleUpdateFile}
        setIsVisibleUpdateFile={setIsVisibleUpdateFile}
        selectedItemContextMenu={selectedItemContextMenu}
        handleUpdateThisItem={(file, commitMessage, textureFiles) =>
          handleUpdateThisItem(file, commitMessage, textureFiles)
        }
      />
      <DirectoryHeader repository={repositoryData} selectedItem={0} />
      <RepositoryHeader
        repositoryName={repositoryData?.name}
        owner={repositoryOwner}
        isPrivate={repositoryData?.isPrivate}
        members={repositoryData?.members}
        onAddMemberSucess={refetchRepositoryDetail}
      />
      <div className="border-solid"></div>
      {!isEmptyRepository && !isLoadingRepositoryDetail && (
        <div className="function-container">
          <div className="wrapper-select-version">
            <div className="dropdown-select-version">
              <div className="switch-container" ref={dropdownVersionRef}>
                <div className="switch-toggle" onClick={() => setIsOpenDropdownVersion(!isOpenDropdownVersion)}>
                  <div className="icon-shuffle">
                    <img src={iconShuffle} alt="Logo" width={25} height={25} />
                  </div>
                  <div className="text-switch truncate">
                    {versionSelected?.name ?? t('directoryScreen.currentVersion')}
                  </div>
                  <div className="icon-select-triagle">
                    <img src={iconTriagle} alt="Logo" width={15} height={15} />
                  </div>
                </div>
                {isOpenDropdownVersion && (
                  <div className="switch-dropdown beauty-scroll">
                    {versions.map((version) => (
                      <div
                        className={`switch-option truncate ${versionSelected?.id === version.id && 'bg-[#f0f0f070]'}`}
                        key={version.id}
                        onClick={() => onChangeVersion(version)}
                      >
                        <p className="m-0">{version.name}</p>
                        <p className="m-0 flex-1 text-[12px]">
                          {version.id === latestVersion?.id && `(${t('directoryScreen.latestVersion')})`}
                        </p>
                        {versions.length > 1 && (
                          <img
                            src={iconXClose}
                            alt="x"
                            className="cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              setVersionDelete(version);
                            }}
                          />
                        )}
                      </div>
                    ))}
                    {versions.length === 0 && <div className="no-option">{t('common.noData')}</div>}
                  </div>
                )}
              </div>
            </div>
            {!flagViewOldVersion ? (
              <div className="button-save-version font-en-16 font-ja-12" onClick={() => setIsVisibleSaveVersion(true)}>
                {t('directoryScreen.btnSaveVersion')}
              </div>
            ) : (
              <div className="button-save-version button-view-only">
                <img src={iconEyeWhite} alt="icon" />
                <span>{t('directoryScreen.btnViewOnly')}</span>
              </div>
            )}
          </div>
        </div>
      )}
      <div className="container-directory">
        <div className="container-wrapper">
          <div className="container-left" onContextMenu={handleContextMenuSideLeft}>
            {!isCompletedTutorial && !isLoadingRepositoryDetail && (
              <div className="start-empty-repo">
                {isShowShareYourTeam && (
                  <div className="action">
                    <img
                      src={iconXClose}
                      alt="icon"
                      className="action-icon-close"
                      onClick={onCloseRecommendShareYourTeam}
                    />
                    <div className="description">
                      <div className="title">
                        <img src={iconPeopleGray} alt="icon" />
                        <span className="font-en-16 font-ja-12">
                          1. {t('directoryScreen.emptyRepository.shareRepositoryTitle')}
                        </span>
                      </div>
                      <span className="note font-en-16 font-ja-12">
                        {t('directoryScreen.emptyRepository.shareRepositoryDescription')}
                      </span>
                    </div>
                    <div className="action-wrap-button">
                      <button className="common btn-skip font-en-16 font-ja-12" onClick={onCloseRecommendShareYourTeam}>
                        {t('directoryScreen.emptyRepository.btnSkip')}
                      </button>
                      <button className="common font-en-16 font-ja-12" onClick={handleRedirectManageAccess}>
                        {t('directoryScreen.emptyRepository.shareRepositoryBtn')}
                      </button>
                    </div>
                  </div>
                )}
                {isShowCreateDirectory && (
                  <div className="action">
                    <img
                      src={iconXClose}
                      alt="icon"
                      className="action-icon-close"
                      onClick={onCloseRecommendCreateDirectory}
                    />
                    <div className="description">
                      <div className="title">
                        <img src={iconAddFolderGray} alt="icon" />
                        <span className="font-en-16 font-ja-12">
                          2. {t('directoryScreen.emptyRepository.createNewFolder')}
                        </span>
                      </div>
                      <span className="note font-en-16 font-ja-12">
                        {t('directoryScreen.emptyRepository.createNewFolderDescription')}
                      </span>
                    </div>
                    <div className="action-wrap-button">
                      <button
                        className="common btn-skip font-en-16 font-ja-12"
                        onClick={onCloseRecommendCreateDirectory}
                      >
                        {t('directoryScreen.emptyRepository.btnSkip')}
                      </button>
                      <button className="common font-en-16 font-ja-12" onClick={() => handleCreateNewFolder(null)}>
                        {t('directoryScreen.emptyRepository.createNewFolderBtn')}
                      </button>
                    </div>
                  </div>
                )}
                {isShowUploadFiles && (
                  <div className="action">
                    <img
                      src={iconXClose}
                      alt="icon"
                      className="action-icon-close"
                      onClick={onCloseRecommendUploadFiles}
                    />
                    <div className="description">
                      <div className="title">
                        <img src={iconUploadGray} alt="icon" />
                        <span className="font-en-16 font-ja-12">
                          3. {t('directoryScreen.emptyRepository.uploadFile')}
                        </span>
                      </div>
                      <span className="note font-en-16 font-ja-12">
                        {t('directoryScreen.emptyRepository.uploadFileDescription')}
                      </span>
                    </div>
                    <div className="action-wrap-button">
                      <button className="common btn-skip font-en-16 font-ja-12" onClick={onCloseRecommendUploadFiles}>
                        {t('directoryScreen.emptyRepository.btnSkip')}
                      </button>
                      <button className="common font-en-16 font-ja-12" onClick={() => handleFileSelect(null)}>
                        {t('directoryScreen.emptyRepository.uploadFileBtn')}
                      </button>
                    </div>
                  </div>
                )}
                <p className="text-follow font-en-16 font-ja-12">{t('directoryScreen.emptyRepository.note')}</p>
              </div>
            )}
            {!isEmptyRepository && !isLoadingRepositoryDetail && (
              <div className="bar-log">
                <div className="bar-log-left">
                  <div
                    className={`checkbox-all ${flagViewOldVersion && 'hidden'}`}
                    onClick={() => handleSelectAll(!repositoryData?.isCheckedAll)}
                  >
                    {repositoryData?.isCheckedAll ? (
                      <img src={iconCheckboxChecked} alt="checked" />
                    ) : (
                      <img src={iconCheckboxUncheckAll} alt="uncheck" />
                    )}
                  </div>
                  <div ref={menuActionFileRef} className={`action-file-warpper ${flagViewOldVersion && 'hidden'}`}>
                    <img
                      src={iconTriagle}
                      alt="icon-triagle"
                      width={20}
                      height={20}
                      style={{
                        marginRight: '5px',
                      }}
                      onClick={toggleMenuCheckbox}
                    />
                    {menuActionFile && (
                      <div className="menu">
                        <div className="menu-item font-en-16 font-ja-12" onClick={handleComfirmDownloadCheckedItems}>
                          {t('common.btnDownload')}
                        </div>
                        <div
                          className={`menu-item font-en-16 font-ja-12 ${flagViewOldVersion && '!select-none !cursor-not-allowed'}`}
                          onClick={handleComfirmRemoveCheckedItems}
                        >
                          {t('common.btnRemove')}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="avatar-git">
                    <img src={repositoryOwner?.user?.avatarUrl} width={20} height={20} alt="avatar" />
                  </div>
                  <div className="name-git">{repositoryLastUpdatedBy?.name || ''}</div>
                  <div className="type-change">updated</div>
                  <div className="time-commit">
                    {repositoryLastUpdatedBy?.updatedAt?._seconds &&
                      moment.unix(repositoryLastUpdatedBy?.updatedAt?._seconds).fromNow()}
                  </div>
                </div>
              </div>
            )}
            <div
              className={`table-folder-structure drop-zone ${isEmptyRepository ? 'empty-repo-table' : ''}`}
              onMouseLeave={hideHoverMenu}
              onDragOver={handleDragOverRepository}
              onDragLeave={handleDragLeaveRepository}
            >
              <DragAndDropFile
                isDraggingFile={isDraggingFileRepository || isEmptyRepository}
                onFileDrop={handleDropFileRepository}
                setIsDraggingFile={setIsDraggingFileRepository}
              />
              <table>
                <tbody>
                  {repositoryData?.directories?.map((item: IDirectory) => (
                    <RepositoryDirectoryItem
                      depth={0}
                      key={item.id}
                      directoryData={item}
                      activeFile={activeFile}
                      selectedItemContextMenu={selectedItemContextMenu}
                      toggleFolder={toggleFolder}
                      handleRowClick={handleRowClick}
                      handleContextMenu={handleContextMenu}
                      handleHoverFile={handleHoverFile}
                      handleHoverDirectory={handleHoverDirectory}
                      handleClickCheckboxItem={handleClickCheckboxItem}
                      editingItemId={editingItemId}
                      setEditingItemId={setEditingItemId}
                      inputRenameRef={inputRenameRef}
                      newName={newName}
                      setNewName={setNewName}
                      handleRenameThisItem={handleRenameThisItem}
                      flagHiddenCheckbox={flagViewOldVersion}
                    />
                  ))}
                  {repositoryData?.files?.map((item: IFile) => (
                    <>
                      <RepositoryFileItem
                        depth={0}
                        key={item.id}
                        fileData={item}
                        activeFile={activeFile}
                        selectedItemContextMenu={selectedItemContextMenu}
                        handleRowClick={handleRowClick}
                        handleContextMenu={handleContextMenu}
                        handleHoverFile={handleHoverFile}
                        handleClickCheckboxItem={handleClickCheckboxItem}
                        editingItemId={editingItemId}
                        setEditingItemId={setEditingItemId}
                        inputRenameRef={inputRenameRef}
                        newName={newName}
                        setNewName={setNewName}
                        handleRenameThisItem={handleRenameThisItem}
                        flagHiddenCheckbox={flagViewOldVersion}
                      />
                      {item.textureFiles && item.textureFiles.length > 0 && (
                        <>
                          {item.textureFiles.map((textureFile) => (
                            <RepositoryFileTextureItem
                              key={textureFile.id}
                              depth={0}
                              fileDataTexture={textureFile}
                              fileData={item}
                            />
                          ))}
                        </>
                      )}
                    </>
                  ))}
                </tbody>
              </table>
              {contextMenuPosition && (
                <RightClickContextMenu
                  contextMenuPosition={contextMenuPosition}
                  contextMenuItems={contextMenuItems}
                  executeAction={executeAction}
                />
              )}
              {hoverMenuPosition && (
                <HoverItemMenu
                  hoverMenuPosition={hoverMenuPosition}
                  hoverMenuItems={hoverMenuItems}
                  executeAction={executeAction}
                />
              )}
            </div>
          </div>
          <div className="container-right">
            <PreviewFile
              url={activeFile && 'url' in activeFile ? activeFile?.url : ''}
              pathFull={activeFile && repositoryData ? getPathInRepository(activeFile, repositoryData, true, true) : ''}
              textureFiles={activeFile && 'textureFiles' in activeFile ? activeFile?.textureFiles : []}
            />
            {(!activeFile || (activeFile && 'url' in activeFile && isModelFile(activeFile?.url))) && (
              <PreviewInfoFile file={activeFile && 'url' in activeFile ? activeFile : null} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
