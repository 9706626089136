import React, { useEffect, useState } from 'react';
import { resetUserInfo } from 'app/redux/baseSlice';
import { FcGoogle } from 'react-icons/fc';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import 'assets/scss/layouts/Login.scss';
import { useAuth } from 'hooks/useAuth';
import { signInWithGoogle, signOutFromGoogle } from 'libs/auth';
import { LoadingOverlay, Logo } from 'components';
import { fetchUser } from 'api/user';
import { useAlertContext } from 'contexts/AlertContextProvider';
import { useTranslation } from 'react-i18next';

export const Login: React.FC = () => {
  const { t } = useTranslation();
  const user = useAuth();
  const { alert } = useAlertContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stateBase = useSelector((state: any) => state.base);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const handleLogin = async () => {
    try {
      setIsLoading(true);
      await signInWithGoogle();
      await fetchUser();
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
      alert({ type: 'success', content: t('login.message.loginSuccess') });
      navigate('/');
    } catch (error) {
      setIsLoading(false);
      alert({ type: 'error', content: t('login.message.loginFailed') });
    }
  };

  const handleLogout = async () => {
    try {
      setIsLoading(true);
      await signOutFromGoogle();
      dispatch(resetUserInfo());
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
      alert({ type: 'success', content: t('login.message.logoutSuccess') });
      navigate('/login');
    } catch (error) {
      setIsLoading(false);
      alert({ type: 'error', content: t('login.message.logoutFailed') });
    }
  };

  useEffect(() => {
    setIsLoading(stateBase.isLoading);
  }, [stateBase.isLoading]);

  return (
    <div className="container-login flex flex-col items-center justify-center">
      <LoadingOverlay isLoading={isLoading} />
      <Logo name="login-logo" />
      <div className="flex flex-col items-center gap-y-2.5 mt-4">
        <span className="font-bold font-en font-en-24 font-ja-24">{t('common.projectName')}</span>
        <span className="font-bold font-en-24 font-ja-20 text-[#7D94AE]">{t('login.description')}</span>
      </div>
      <div className="des-login d-flex items-center justify-center my-11 py-1.5 font-en-18 font-ja-12">
        {t('login.note1')}&nbsp;
        <a href="/terms" target="_blank" className="des-underline">
          {t('login.terms')}
        </a>
        &nbsp;{t('login.note2')}&nbsp;
        <a href="/privacy_policy" target="_blank" className="des-underline">
          {t('login.privacy')}
        </a>
        &nbsp;{t('login.note3')}
      </div>
      {user ? (
        <>
          <p className="text-bold-black font-en-18 font-ja-14">{t('login.loggedIn', { userName: user.displayName })}</p>
          <button className="logout-button font-en-24 font-ja-20" onClick={handleLogout}>
            {t('login.btnLogout')}
          </button>
        </>
      ) : (
        <button className="login-button" onClick={handleLogin}>
          <FcGoogle className="icon" />
          <span className="font-en-24 font-ja-20">{t('login.btnLoginWithGoogle')}</span>
        </button>
      )}
    </div>
  );
};
