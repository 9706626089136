export const MEMBER_ROLE = {
  OWNER: 'OWNER',
  ADMIN: 'ADMIN',
  EDITOR: 'EDITOR',
  VIEWER: 'VIEWER',
};

export const MEMBER_ROLE_TEXT = {
  OWNER: 'Owner',
  ADMIN: 'Admin',
  EDITOR: 'Editor',
  VIEWER: 'Viewer',
};

export const ROLE_LIST = [
  {
    value: MEMBER_ROLE.OWNER,
    label: MEMBER_ROLE_TEXT.OWNER,
  },
  {
    value: MEMBER_ROLE.ADMIN,
    label: MEMBER_ROLE_TEXT.ADMIN,
  },
  {
    value: MEMBER_ROLE.EDITOR,
    label: MEMBER_ROLE_TEXT.EDITOR,
  },
  {
    value: MEMBER_ROLE.VIEWER,
    label: MEMBER_ROLE_TEXT.VIEWER,
  },
];

export const REPOSITORY_TYPE = {
  OWN: 'OWN',
  SHARED: 'SHARED',
};

export const RIGHT_CLICK_CONTEXT_MENU_WIDTH = 404;
export const RIGHT_CLICK_CONTEXT_MENU_HEIGHT = 240;

export const SUPPORTED_FILE_TYPE = ['.fbx', '.obj', '.glb', '.gltf', '.md'];
export const SUPPORTED_MODEL_FILE_TYPE = ['.fbx', '.obj', '.glb', '.gltf'];
export const SUPPORTED_FILE_TEXTURE_TYPE = ['.png', '.jpg', '.jpeg', '.tga', '.mtl'];
export const SUPPORTED_FILE_TICKET_CHAT_TYPE = ['.png', '.jpg', '.jpeg'];

export const ALL_STATUSES = '';
export const REQUESTED_REVIEW = 'REQUESTED_REVIEW';
export const REQUESTED_CORRECTION = 'REQUESTED_CORRECTION';
export const COMPLETE_MERGE = 'COMPLETE_MERGE';

export const STATUS_REQUEST = [
  {
    id: ALL_STATUSES,
    reviewType: 'All statuses',
  },
  {
    id: REQUESTED_REVIEW,
    reviewType: 'Requested review',
  },
  {
    id: REQUESTED_CORRECTION,
    reviewType: 'Requested correction',
  },
  {
    id: COMPLETE_MERGE,
    reviewType: 'Complete merge',
  },
];
