import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface MenuDirectoryProps {
  repositoryId: string;
  selectedItem: number | null;
}

export const MenuDirectory: React.FC<MenuDirectoryProps> = ({ repositoryId = '1', selectedItem }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const menuList = [t('header.menu.directoryTree'), t('header.menu.tickets'), t('header.menu.settings')];
  const menuPaths = ['directory', 'tickets', 'settings'];

  const handleClick = (index: number) => {
    navigate(`/repository/${repositoryId}/${menuPaths[index]}`);
  };

  return (
    <div className="menu-container">
      <div className="menu-list">
        {menuList.map((item, index) => (
          <div
            onClick={() => handleClick(index)}
            className={`menu-item font-en-16 font-ja-12 ${selectedItem === index ? 'selected' : ''}`}
            key={index}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};
