import React, { useEffect, useState } from 'react';
import 'assets/scss/components/PreviewInfoFile.scss';
import { ICurrentFile, IFile, IMetaData, IUpcomingFile, IUpdatedBy } from 'types';
import moment from 'moment-timezone';
import { formatFileSize, getFileMetaData, getFileType, isGLBModel, isObjModel } from 'libs/previewFile';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { getFileLastUpdatedBy } from 'libs/repository';

interface IPreviewInfoFileProps {
  containerClassName?: string;
  file?: IFile | ICurrentFile | IUpcomingFile | null;
  upComingFile?: IFile | ICurrentFile | IUpcomingFile | null;
  currentFile?: IFile | ICurrentFile | IUpcomingFile | null;
  onClick?: () => void;
}

export const PreviewInfoFile: React.FC<IPreviewInfoFileProps> = ({
  containerClassName = '',
  file,
  upComingFile,
  currentFile,
  onClick,
}) => {
  const { t } = useTranslation();
  const [lastUpdatedBy, setLastUpdatedBy] = useState<IUpdatedBy | undefined>();
  const [metaData, setMetaData] = useState<IMetaData>({
    vertices: 0,
    triangles: 0,
    sizeX: 0,
    sizeY: 0,
    sizeZ: 0,
    surface: 0,
    size: 0,
    mimetype: '',
  });

  const [metaComparisonData, setMetaComparisonData] = useState<IMetaData>({
    vertices: 0,
    triangles: 0,
    sizeX: 0,
    sizeY: 0,
    sizeZ: 0,
    surface: 0,
    size: 0,
    mimetype: '',
  });

  useEffect(() => {
    if (!file || !file.url) return;
    const fileType = getFileType(file.url);
    let loader: any = new FBXLoader();
    if (isObjModel(fileType)) {
      loader = new OBJLoader();
    } else if (isGLBModel(fileType)) {
      loader = new GLTFLoader();
    }

    loader.load(file?.url, (object: any) => {
      let metaData: IMetaData;
      if (isGLBModel(fileType)) {
        metaData = getFileMetaData(object.scene);
      } else {
        metaData = getFileMetaData(object);
      }
      setMetaData({
        ...metaData,
        size: file.metaData.size,
        mimetype: file.metaData.mimetype ?? getFileType(file.url, true),
      });
    });

    setLastUpdatedBy(getFileLastUpdatedBy(file));
  }, [file]);

  useEffect(() => {
    let compareFile = upComingFile;
    if (!upComingFile || !upComingFile.url) {
      compareFile = currentFile;
    }
    if (!compareFile || !compareFile.url) return;

    const fileType = getFileType(compareFile.url);
    let loader: any = new FBXLoader();
    if (isObjModel(fileType)) {
      loader = new OBJLoader();
    } else if (isGLBModel(fileType)) {
      loader = new GLTFLoader();
    }

    loader.load(compareFile.url, (object: any) => {
      let metaData: IMetaData;
      if (isGLBModel(fileType)) {
        metaData = getFileMetaData(object.scene);
      } else {
        metaData = getFileMetaData(object);
      }
      setMetaComparisonData({
        ...metaData,
        size: compareFile?.metaData?.size,
        mimetype: compareFile?.metaData?.mimetype ?? getFileType(compareFile?.url, true),
      });
    });
  }, [currentFile, upComingFile]);

  return (
    <div className={`preview-info-file ${containerClassName}`} onClick={onClick}>
      {!file && (
        <div className="note">
          <span className="font-en-16 font-ja-10">{t('previewFile.note1')}</span>
          <span className="font-en-16 font-ja-10">{t('previewFile.note2')}</span>
        </div>
      )}
      <div className="detail-file-preview">
        <div className="name-file-wrapper">
          <div className="name-file font-en-18 font-ja-14">{t('previewFile.fileName')}</div>
          {file && (
            <div className="active-lastest font-ja">
              <span>{lastUpdatedBy?.name || ''} updated </span>
              <span>{lastUpdatedBy?.updatedAt && moment.unix(lastUpdatedBy?.updatedAt?._seconds).fromNow()}</span>
            </div>
          )}
        </div>
        <div className="type-file">{file ? '3D Model' : '-----'}</div>
        <div className="basic-info-wrapper">
          <div className="title-text font-en-16 font-ja-12">{t('previewFile.basicInfo')}</div>
          <div className="info-detail-file">
            <div className="info-right">
              <div className="detail">
                <div className="text font-en">vertices</div>
                <div
                  className={classNames(`parameter  font-en`, {
                    'text-green': metaData.vertices !== metaComparisonData.vertices && upComingFile,
                    'text-red': metaData.vertices !== metaComparisonData.vertices && currentFile,
                  })}
                >
                  {file ? metaData.vertices : '-----'}
                </div>
              </div>
              <div className="detail">
                <div className="text font-en">triangles</div>
                <div
                  className={classNames(`parameter font-en`, {
                    'text-green': metaData.triangles !== metaComparisonData.triangles && upComingFile,
                    'text-red': metaData.triangles !== metaComparisonData.triangles && currentFile,
                  })}
                >
                  {file ? metaData.triangles : '-----'}
                </div>
              </div>
              <div className="detail">
                <div className="text font-en">surface</div>
                <div
                  className={classNames(`parameter font-en`, {
                    'text-green': metaData.surface !== metaComparisonData.surface && upComingFile,
                    'text-red': metaData.surface !== metaComparisonData.surface && currentFile,
                  })}
                >
                  {file ? metaData.surface : '-----'}
                </div>
              </div>
            </div>
            <div className="info-left">
              <div className="detail">
                <div className="text font-en">sizeX</div>
                <div
                  className={classNames(`parameter font-en`, {
                    'text-green': metaData.sizeX !== metaComparisonData.sizeX && upComingFile,
                    'text-red': metaData.sizeX !== metaComparisonData.sizeX && currentFile,
                  })}
                >
                  {file ? metaData.sizeX : '-----'}
                </div>
              </div>
              <div className="detail">
                <div className="text font-en">sizeY</div>
                <div
                  className={classNames(`parameter font-en`, {
                    'text-green': metaData.sizeY !== metaComparisonData.sizeY && upComingFile,
                    'text-red': metaData.sizeY !== metaComparisonData.sizeY && currentFile,
                  })}
                >
                  {file ? metaData.sizeY : '-----'}
                </div>
              </div>
              <div className="detail">
                <div className="text font-en">sizeZ</div>
                <div
                  className={classNames(`parameter font-en`, {
                    'text-green': metaData.sizeZ !== metaComparisonData.sizeZ && upComingFile,
                    'text-red': metaData.sizeZ !== metaComparisonData.sizeZ && currentFile,
                  })}
                >
                  {file ? metaData.sizeZ : '-----'}
                </div>
              </div>
            </div>
            <div className="info-right">
              <div className="detail">
                <div className="text font-en">file size</div>
                <div
                  className={classNames(`parameter font-en`, {
                    'text-green': metaData.size !== metaComparisonData.size && upComingFile,
                    'text-red': metaData.size !== metaComparisonData.size && currentFile,
                  })}
                >
                  {file ? formatFileSize(metaData.size) : '-----'}
                </div>
              </div>
              <div className="detail">
                <div className="text font-en">file type</div>
                <div
                  className={classNames(`parameter font-en`, {
                    'text-green': metaData.mimetype !== metaComparisonData.mimetype && upComingFile,
                    'text-red': metaData.mimetype !== metaComparisonData.mimetype && currentFile,
                  })}
                >
                  {file ? metaData.mimetype : '-----'}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="update-history-wrapper">
          <div className="title-text font-en-16 font-ja-12">{t('previewFile.updateHistory')}</div>
          <div className="history-wrapper">
            {file &&
              'updatedHistory' in file &&
              file?.updatedHistory?.map((history, index) => (
                <div key={index} className="history-item">
                  <div className="text font-ja">{moment.unix(history.updatedAt._seconds).format('MMM, Do, YYYY')}</div>
                  <div className="parameter font-ja">{history.user.name} updated</div>
                </div>
              ))}
            {!file && (
              <div className="history-item">
                <div className="text font-en-12 font-ja-10">{t('previewFile.updateHistoryNote')}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
