import wrapIcon from './wrapIcon';

type IconProps = {
  onMouseColor?: string;
  onChangeColor?: string;
  width?: string;
  height?: string;
  isActive?: boolean;
};

function Icon({
  onMouseColor,
  onChangeColor,
  width,
  height,
  isActive,
  ...props
}: Readonly<IconProps>) {
  return (
    <svg
      width={width ?? '18'}
      height={height ?? '18'}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 12.1244L13 6.06218L5.5 0L5.5 3.06218L0 3.06218L0 9.06218H5.5L5.5 12.1244Z"
        fill={isActive ? onMouseColor : onChangeColor || 'black'}
      />
    </svg>
  );
}
const ArrowRightIcon = wrapIcon(Icon);
export default ArrowRightIcon;
