import React from 'react';
import 'assets/scss/layouts/NotFound.scss';

export const NotFound : React.FC = () => {
  return (
    <div className="notFound">
      <h2>404 Page Not Found</h2>
      <p>The page you are looking for does not exist.</p>
    </div>
  );
};
