import React from 'react';
import 'assets/scss/components/Loading.scss';

interface ILoadingProps {
  containerClassName?: string;
  spinnerClassName?: string;
  width?: string;
  height?: string;
}

export const Loading: React.FC<ILoadingProps> = ({
  containerClassName = '',
  spinnerClassName = '',
  width = '50px',
  height = '50px',
}) => {
  return (
    <div className={`loading-container ${containerClassName}`}>
      <div className={`spinner ${spinnerClassName}`} style={{ width, height }}></div>
    </div>
  );
};
