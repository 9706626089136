import React from 'react';
import { useTranslation } from 'react-i18next';

const page = 'privacyPolicy.';
export const PrivacyPolicy: React.FC = () => {
  const { t } = useTranslation();

  const trans = (key: string) => {
    return t(page + key);
  };

  return (
    <>
      <h1>{trans('title')}</h1>
      <span>{trans('date')}</span>
      <div className="line"></div>
      <p className="margin-b-10">{trans('desc')}</p>

      <p className="font-bold margin-b-10">{trans('1.title')}</p>
      <p>{trans('1.desc')}</p>
      <p>{trans('1.1')}</p>
      <p>{trans('1.2')}</p>
      <p>{trans('1.3')}</p>
      <p className="margin-b-10">{trans('1.4')}</p>

      <p className="font-bold margin-b-10">{trans('2.title')}</p>
      <p>{trans('2.1.title')}</p>
      <p className="margin-l-10">{trans('2.1.a')}</p>
      <p className="margin-l-10">{trans('2.1.b')}</p>
      <p className="margin-l-10">{trans('2.1.c')}</p>
      <p className="margin-l-10">{trans('2.1.d')}</p>
      <p className="margin-l-10">{trans('2.1.e')}</p>
      <p className="margin-l-10">{trans('2.1.f')}</p>
      <p className="margin-l-10">{trans('2.1.g')}</p>
      <p className="margin-l-10">{trans('2.1.h')}</p>
      <p className="margin-l-10">{trans('2.1.u')}</p>
      <p className="margin-l-10">{trans('2.1.j')}</p>
      <p className="margin-l-10">{trans('2.1.k')}</p>
      <p className="margin-l-10">{trans('2.1.l')}</p>
      <p className="margin-l-10">{trans('2.1.m')}</p>
      <p className="margin-b-10">{trans('2.2.title')}</p>

      <p className="font-bold margin-b-10">{trans('3.title')}</p>
      <p>{trans('3.1.title')}</p>
      <p className="margin-l-10">{trans('3.1.a')}</p>
      <p className="margin-l-10">{trans('3.1.b')}</p>
      <p>{trans('3.2.title')}</p>
      <p className="margin-b-10">{trans('3.3.title')}</p>

      <p className="font-bold margin-b-10">{trans('4.title')}</p>
      <p className="margin-b-10">{trans('4.desc')}</p>

      <p className="font-bold margin-b-10">{trans('5.title')}</p>
      <p className="margin-b-10">{trans('5.desc')}</p>

      <p className="font-bold margin-b-10">{trans('6.title')}</p>
      <p className="margin-b-10">{trans('6.desc')}</p>

      <p className="font-bold margin-b-10">{trans('7.title')}</p>
      <p className="margin-b-10">{trans('7.desc')}</p>

      <p className="font-bold margin-b-10">{trans('8.title')}</p>
      <p className="whitespace-pre-line margin-b-10">{trans('8.desc')}</p>

      <p>{trans('info.company')}</p>
      <p>{trans('info.address')}</p>
      <p>{trans('info.director')}</p>
    </>
  );
};
