import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';
import Users from 'app/models/Users';

const firebaseConfig = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_APP_ID,
};

export const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export const isLogin = (): boolean => {
	return !!localStorage.getItem('token');
};

export const signInWithGoogle = async (): Promise<void> => {
	try {
		const auth = getAuth();
		const provider = new GoogleAuthProvider();
		const result = await signInWithPopup(auth, provider);

		const token = await result.user.getIdToken();
		localStorage.setItem('token', token);
	} catch (error) {
		throw error;
	}
};

export const signOutFromGoogle = async (): Promise<void> => {
	try {
		const auth = getAuth();
		await signOut(auth);
		Users.clearToken();
	} catch (error) {
		throw error;
	}
};
